var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex"}},[(
			_vm.visao_colaborador &&
				_vm.mpp &&
				_vm.mppObservacao
		)?_c('v-tooltip',{attrs:{"top":""}},[_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"slot":"activator","color":_vm.corIcone('warning')},slot:"activator"},[_vm._v("fas fa-bell")]),_c('span',[_vm._v(_vm._s(_vm.mppObservacao))])],1):_vm._e(),(_vm.produtoReferencia)?_c('v-tooltip',{attrs:{"top":""}},[_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"slot":"activator","color":_vm.corIcone('success')},slot:"activator"},[_vm._v("info")]),_c('span',[_vm._v(" O "+_vm._s(_vm.produto)+" é um produto "+_vm._s(_vm.descricaoTipoProduto)+" ao "+_vm._s(_vm.produtoReferencia)+" ")])],1):_vm._e(),(_vm.portaria_344_98)?_c('v-tooltip',{attrs:{"top":""}},[_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"slot":"activator","color":_vm.corIcone('error')},slot:"activator"},[_vm._v("warning")]),_c('span',[_vm._v("Este medicamento necessita da retenção da prescrição médica.")])],1):_vm._e(),(_vm.foraBox)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('img',_vm._g({staticClass:"pl-1",staticStyle:{"cursor":"pointer"},attrs:{"src":require("@Assets/icons/fora_box_dark.svg"),"alt":"ForaBox","width":"18"}},on))]}}],null,false,251678462)},[_c('span',[_vm._v("ForaBox")])]):_vm._e(),(_vm.refrigerado)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('img',_vm._g({staticClass:"pl-1",staticStyle:{"cursor":"pointer"},attrs:{"src":require("@Assets/icons/refrigerado_dark.svg"),"alt":"Refrigerado","width":"18"}},on))]}}],null,false,494605921)},[_c('span',[_vm._v("Refrigerado")])]):_vm._e(),(_vm.pbm)?_c('v-tooltip',{attrs:{"top":""}},[_c('v-chip',{staticClass:"justify-center",staticStyle:{"max-width":"20px","max-height":"20px","margin-top":"-1.5px","cursor":"pointer"},attrs:{"slot":"activator","color":_vm.corIcone('blue'),"dark":"","small":""},slot:"activator"},[_c('strong',[_vm._v("%")])]),_c('span',[_vm._v(_vm._s(_vm.beneficioPbm || "PBM"))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<v-select
		v-if="newDesign"
		label="Ciclos"
		:value="quantidade"
		:disabled="desabilitado"
		:items="getCycles"
		hide-selected
		hide-details
		solo
		@change="change">
	</v-select>

	<v-layout v-else row wrap align-center align-content-center>
		<v-flex align-self-center md1 xs1 class="py-0 mr-2">
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-icon small class="mb-0 mr-2" v-on="on" color="green">fas fa-receipt</v-icon>
				</template>
				<span>Aproveitamento de receita</span>
			</v-tooltip>
		</v-flex>

		<v-flex md4 xs4 align-self-center class="aproveitamento-receita-style text-center text-bold">
			<v-btn :disabled="disabledMinus || (!eh_rt && !eh_administrador) || desabilitado" small icon @click="incrementValue(-1)"
				class="ma-0">
				<v-icon size="20" class="icon-count-use-receipt" color="green">fas fa-minus-square</v-icon>
			</v-btn>
			<span class="black--text" style="padding-bottom: 4px">{{ quantityValue }}</span>
			<v-btn :disabled="disabledPlus || (!eh_rt && !eh_administrador)  || desabilitado" small icon @click="incrementValue(1)"
				class="ma-0">
				<v-icon size="20" class="icon-count-use-receipt" color="green">fas fa-plus-square</v-icon>
			</v-btn>
		</v-flex>
	</v-layout>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import { RascunhoPresenter } from '@Presenter/alteracoes-presenter';
import { debounce } from 'lodash';

const PRESCRIPTION_REUSE_MIN = 1;
const PRESCRIPTION_REUSE_MAX = 2;

export default {
	name: 'AproveitamentoReceita',
	props: {
		desabilitado: {
			type: Boolean,
			default: false
		},
		quantidade: {
			type: Number,
			required: true
		},
		medicamentoId: {
			type: Number,
			required: true
		},
		boxId: {
			type: Number,
			required: true
		},
		eRascunho: {
			type: Boolean,
			required: true
		},
		newDesign: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		isDraft: false,
		quantityValue: 1
	}),
	created() {
		this.quantityValue = this.quantidade;
		this.isDraft = this.eRascunho;
	},
	methods: {
		incrementValue(value) {
			this.quantityValue += value;

			this.send(value, this);
		},
		change(value) {
			this.quantityValue = value;
			this.send(value, this);
		},
		send: debounce((value, self) => {
			const presenter = self.isDraft ? RascunhoPresenter : BoxPresenter;
			presenter.updateAproveitamentoReceita(self.boxId, {
				medicineId: self.medicamentoId,
				totalCycles: self.quantityValue
			}).then(() => {
				// self.quantidade = response.totalCycles;
				self.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Aproveitamento de receita alterado' });
			}).catch(({ message }) => {
				self.quantityValue += (-value);
				self.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message });
			});
		}, 500)
	},
	computed: {
		disabledMinus() {
			return this.quantidade === PRESCRIPTION_REUSE_MIN;
		},
		disabledPlus() {
			return this.quantidade === PRESCRIPTION_REUSE_MAX;
		},
		eh_rt() {
			return this.$store.getters.eh_rt;
		},
		eh_administrador() {
			return this.$store.getters.eh_administrador;
		},
		getCycles() {
			const items = [];
			for (let index = PRESCRIPTION_REUSE_MIN; index <= PRESCRIPTION_REUSE_MAX; index++) {
				items.push({
					value: index,
					text: `${index} ${index === 1 ? 'Ciclo' : 'Ciclos'}`
				});
			}
			return items;
		}
	},
	watch: {
		quantidade(value) {
			this.quantityValue = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.icon-count-use-receipt {
	cursor: pointer;
	border-radius: 20px;
	background-color: white !important;
}

.aproveitamento-receita-style {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	align-content: center;
	padding: 2px !important;
	border: 1px solid #E0E0E0;
	border-radius: 7px;
	min-width: 90px !important;
	max-width: 95px !important;
}
</style>

<template>
	<div>
		<section>
			<v-data-table
				:items="items"
				:headers="headers"
				:loading="loading"
				:total-items="total"
				class="custom-data-table"
				:disable-initial-sort="true"
				:pagination.sync="pagination"
				rows-per-page-text="Items por página"
				:rows-per-page-items="itemsPerPageOptions"
				no-results-text="Nenhum resultado encontrado"
				:no-data-text="loading ? 'Carregando...' : 'Nenhum intem encontrado'">
				<template v-slot:headers="props">
					<tr>
						<th
							v-for="header in props.headers"
							:key="header.text"
							:width="header.width"
							class="column"
							:class="[
								header.sortable ? 'sortable' :  '',
								pagination.descending ? 'desc' : 'asc',
								header.value === pagination.sortBy ? 'active' : ''
							]"
							@click="changeSort(header)">
							<v-icon v-if="header.sortable " small>arrow_upward</v-icon>
							{{ header.text }}
						</th>
					</tr>
				</template>

				<template v-slot:items="props">
					<tr>
						<td class="text-center">
							<div class="items-center justify-center ">
								<!-- ICON subscription cancelled -->
								<v-tooltip top v-if="props.item.deleted">
									<template v-slot:activator="{ on }">
										<v-icon v-on="on" class="mr-2" color="red" small>fas fa-exclamation-circle</v-icon>
									</template>
									<span>
										Assinatura cancelada
									</span>
								</v-tooltip>
								<router-link :to="{name: 'Assinatura', params: {id: props.item.subscriptionId}}" class="black--text">
									{{ props.item.subscriptionId }}
								</router-link> |
								{{ props.item.box.id }}

								<!-- ICON payment mode (proportional|integral) -->
								<v-tooltip top v-if="props.item.paymentMode">
									<template v-slot:activator="{ on }">
										<img :src="iconUrl(props.item)" :alt="iconLabel(props.item)" v-on="on" class="pl-1"  style="width: 25px;height: 25px;">
									</template>
									<span>
										{{iconLabel(props.item)}}
									</span>
								</v-tooltip>

								<!-- ICON PBM -->
								<v-tooltip v-if="props.item.box.hasPBM" top>
									<v-chip class="justify-center" color="blue" style="max-width: 22px; max-height: 22px;" slot="activator" dark
										small><strong>%</strong></v-chip>
									<span>Box com PBM</span>
								</v-tooltip>

								<!-- ICON Express -->
								<BoxExpress :express="props.item.express"/>

								<!-- ICON First box -->
								<FirstBox v-if="!props.item.express && props.item.isFirstBox" />
							</div>
						</td>
						<td class="text-center">
							<div class="items-center justify-center ">
								<span class="ml-2">{{ props.item.patient.name }}</span>
								<template v-if="props.item.patient.globalTags && props.item.patient.globalTags.length > 0">
									<GlobalTags
										v-for="(globalTag, index) in props.item.patient.globalTags"
										:key="index"
										:global_tag="globalTag"/>
								</template>
							</div>
						</td>
						<td class="text-center">{{ props.item.box.startDate | formatDate('DD/MM/YYYY [(]dddd[)]')  }}</td>
						<td class="text-center">
							<template v-if="props.item.restHome">
								{{ props.item.restHome.name }}
							</template>
							<template v-else>
								----
							</template>
						</td>
						<td class="text-center">{{ props.item.box.status | statusBox  }}</td>

						<td v-if="isChargedView" class="text-center">{{ props.item.chargedDate | formatDate('DD/MM/YYYY')  }}</td>

						<td class="text-center">
							<div class="items-center justify-center">
								<div class="pointer" v-if="!props.item.wasCharged" @click="openDialog(props.item, availableDialogs.IUGU)">
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<v-icon v-on="on">fas fa-dollar-sign</v-icon>
										</template>
										<span>Cobrar</span>
									</v-tooltip>
								</div>
								<div class="pl-3 pointer" @click="openDialog(props.item, availableDialogs.FILES)">
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<v-icon v-on="on">fas fa-file</v-icon>
										</template>
										<span>Arquivos</span>
									</v-tooltip>
								</div>
							</div>
						</td>
					</tr>
				</template>
			</v-data-table>
		</section>

		<!-- Iugu dialog -->
		<v-dialog
			persistent
			v-model="openChargeDialog"
			scrollable
			transition="fade"
			max-width="920px">
			<transition>
				<DialogIugu
					v-if="openChargeDialog"
					:box_id="selectedItem.box.id"
					:assinatura_id="selectedItem.subscriptionId"
					:isFirstBox="selectedItem.isFirstBox"
					:show_modalidade="true"
					@close="onCloseIugu"/>
			</transition>
		</v-dialog>

		<!-- Arquivos dialog -->
		<v-dialog
			persistent
			v-model="openFilesDialog"
			scrollable
			transition="fade"
			max-width="920px">
			<transition>
				<Dialog titulo="Arquivos da box" @close="onCloseFiles"
					v-if="openFilesDialog">
					<Arquivos :boxId="selectedItem.box.id" />
				</Dialog>
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import PorportionalPaymentModeIcon from '@Assets/icons/proportional-mode-icon.svg';
import IntegralPaymentModeIcon from '@Assets/icons/integral-mode-icon.svg';
import subscriptionPaymentMode from '@Consts/modalidade_assinatura';
import GlobalTags from '@Componentes/global-tags';
import DialogIugu from '@Componentes/dialog-iugu';
import BoxExpress from '@Componentes/box-express';
import FirstBox from '@Componentes/first-box';
import Arquivos from '@Componentes/assinatura/arquivos';

export default {
	name: 'ListChargesTable',
	components: {
		GlobalTags, DialogIugu, BoxExpress, FirstBox, Arquivos
	},
	props: {
		availableViews: {
			required: true,
			type: Object
		},
		currentView: {
			required: true,
			type: String
		},
		loading: {
			required: false,
			type: Boolean,
			default: false
		},
		items: {
			required: false,
			type: Array,
			default: () => ([])
		},
		total: {
			required: false,
			type: Number,
			default: 0
		},
		itemsPerPageOptions: {
			required: false,
			type: Array,
			default: () => ([15, 30, 50, 75, 100])
		},
		itemsPerPage: {
			required: false,
			type: Number,
			default: 30
		}
	},
	data() {
		return {
			openChargeDialog: false,
			openFilesDialog: false,
			availableDialogs: {
				IUGU: 'iugu',
				FILES: 'files'
			},
			generatingTags: false,
			pagination: {
				descending: false,
				page: 1,
				rowsPerPage: this.itemsPerPage,
				sortBy: 'id',
				totalItems: this.total
			}
		};
	},
	computed: {
		isChargedView() {
			return this.currentView === this.availableViews.CHARGED;
		},
		headers() {
			const baseHeaders = [

				{
					text: 'Assinatura ID | Box ID',
					value: 'subscription',
					sortable: true
				},
				{
					text: 'Paciente',
					value: 'patientName',
					sortable: true
				},
				{
					text: 'Data de inicio',
					value: 'startDate',
					sortable: true
				},
				{
					text: 'Casa de repouso',
					value: 'restHomeName',
					sortable: true
				},
				{
					text: 'Status',
					value: 'header-box-status',
					sortable: false
				}
			];
			if (this.currentView === this.availableViews.CHARGED) {
				baseHeaders.push(
					{
						text: 'Data da cobrança',
						sortable: false
					}
				);
			}
			return [
				...baseHeaders,
				{
					text: 'Ações',
					sortable: false,
					width: '10px'
				}
			];
		}
	},
	methods: {
		updateFilters() {
			this.$emit('filter', {
				pagination: {
					page: Number(this.pagination.page),
					perPage: Number(this.pagination.rowsPerPage)
				},
				sorting: {
					field: this.pagination.sortBy,
					direction: this.pagination.descending ? 'DESC' : 'ASC'
				}
			});
		},
		changeSort(header) {
			if (!header.sortable)
				return;
			const column = header.value;
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},
		openDialog(item, dialogName) {
			this.selectedItem = item;
			if (dialogName === this.availableDialogs.IUGU)
				this.openChargeDialog = true;
			else if (dialogName === this.availableDialogs.FILES)
				this.openFilesDialog = true;
		},
		onCloseIugu(afterSave) {
			this.openChargeDialog = false;
			if (afterSave)
				this.$emit('updateData');
		},
		onCloseFiles() {
			this.openFilesDialog = false;
		},
		iconUrl(item) {
			if (item.paymentMode === subscriptionPaymentMode.PROPORCIONAL)
				return PorportionalPaymentModeIcon;
			if (item.paymentMode === subscriptionPaymentMode.INTEGRAL)
				return IntegralPaymentModeIcon;
			return null;
		},
		iconLabel(item) {
			return item.paymentMode === subscriptionPaymentMode.PROPORCIONAL ? 'Pagamento proporcional' : 'Pagamento integral';
		}

	},
	watch: {
		pagination: {
			deep: true,
			handler() {
				this.updateFilters();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
header {
	border-bottom-width: 5px;
	border-bottom-color: #ffd9a0 !important;
	border-bottom-style: solid;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.column-overflow {
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

::v-deep .custom-data-table  {
	.v-table {
		background-color: $primary_light;
		border-collapse: separate;
		border-spacing: 0 5px;

		thead th {
			padding-top: 10px;
			padding-bottom: 10px;
			height: 100%;
		}
		thead {
			tr {
				background-color: transparent;
			}

			th {
				padding: 5px 10px;
				height: 100%;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					border: 2px solid transparent;
				}

				&:last-child {
					border: 4px solid transparent;
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}
		tbody {

			tr {
				background-color: $secondary-light;
			}

			td {
				padding: 5px 10px;
				height: 100%;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					border: 2px solid $secondary-light;
				}

				&:last-child {
					border: 4px solid $secondary-light;
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}

	}

	.v-datatable {
		.v-datatable__actions {
			background-color: $primary_light;
		}
	}
}
</style>

<template>
	<v-layout row wrap align-center justify-center class="text-bold informacao-valores">
		<v-flex class="pr-2">
			<v-flex>
				Sub total:
			</v-flex>
			<v-flex class="style-valores font-valores">
				{{ subtotal | dinheiro}}
			</v-flex>
		</v-flex>
		<v-flex class="pr-2">
			<v-flex>
				Desconto:
			</v-flex>
			<v-flex class="style-valores font-valores">
				{{ desconto | dinheiro}}
			</v-flex>
		</v-flex>
		<v-flex class="pr-2">
			<v-flex>
				Total:
			</v-flex>
			<v-flex class="style-valores font-valores">
				{{ total | dinheiro}}
			</v-flex>
		</v-flex>
		<v-flex class="pr-2" v-if="showInfoPrimeiraBox">
			<FirstBox />
		</v-flex>
		<v-flex class="pr-2" v-if="showInfoPrimeiraBox">
			<v-flex class="style-primeira-box">
				Desc. 1ª Box:
			</v-flex>
			<v-flex class="style-valores font-valores">
				{{ descontoPrimeiraBox | porcentagem}}
			</v-flex>
		</v-flex>
		<v-flex class="pr-2" v-if="showInfoPrimeiraBox">
			<v-flex class="style-primeira-box">
				Total 1ª Box:
			</v-flex>
			<v-flex class="style-valores font-valores">
				{{ totalPrimeiraBox | dinheiro}}
			</v-flex>
		</v-flex>
	</v-layout>
</template>

<script>
import FirstBox from '../first-box.vue';

export default {
	name: 'InformacaoValores',
	props: {
		desconto: {
			type: Number,
			default: 0
		},
		subtotal: {
			type: Number,
			default: 0
		},
		total: {
			type: Number,
			default: 0
		},
		descontoPrimeiraBox: {
			type: Number,
			default: 0
		},
		totalPrimeiraBox: {
			type: Number,
			default: 0
		},
		showInfoPrimeiraBox: {
			type: Boolean,
			default: false
		}
	},
	components: { FirstBox }
};
</script>

<style lang="scss" scoped>
.informacao-valores {
	.font-valores {
		font-size: 1.3rem !important;
	}

	.style-valores {
		color: rgba(117, 117, 117, 1) !important;
	}

	.style-primeira-box {
		color: rgb(196, 140, 57) !important;
	}
}
</style>

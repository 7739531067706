<template>
	<Dialog
		titulo="Liberar Box para Pré Produção"
		subtitulo="Tenha certeza que todos os produtos já foram faturados"
		btn_acao="Liberar Box"
		btn_cancelar="Cancelar"
		:btn_acao_extra_disable="false"
		@acao="save"
		:loading="loading"
		@close="$emit('update:close', false);">

		<v-container fluid grid-list-lg class="pa-0">
			<v-layout wrap align-center>
				<v-flex xs12>
					<v-data-table
						:headers="headers"
						:items="items"
						:loading="loading"
						:no-data-text="loading ? 'Carregando...' : 'Produtos não encontrados para essa Box'"
						:rows-per-page-items="[{text: 'Todos', value: -1}]"
						rows-per-page-text="Itens por página" hide-actions>
						<template v-slot:items="props">
							<tr>
								<td class="text-xs-left">
									{{ props.item.product_name }}
								</td>
								<!-- <td>
									<v-text-field
										v-model="props.item.ean"
										:label="`ean_${props.index+1}`"
										single-line
										solo
										v-validate="{ required: true }"
										@keydown.enter.prevent="submit"
										@change="validateEan(props.item.ean, props.item.id)"
										:data-vv-name="`ean_${props.index+1}`"
										:error-messages="
											errors.collect(`ean_${props.index+1}`)
										"/>
								</td> -->
								<td>
									<v-text-field
										:disabled="isDisabled(props.item, 'tagId')"
										v-model="props.item.tag_id"
										:label="`etiqueta_${props.index+1}`"
										single-line
										solo
										@keydown.enter.prevent="getTagInfo(props.index)"
										v-validate="{ required: (props.item.is_required), regex: /(^\d{8}$)|(^[vV]\d{13}$)/ }"
										:data-vv-name="`etiqueta_${props.index+1}`"
										:error-messages="
											errors.collect(`etiqueta_${props.index+1}`)
										"/>
								</td>
								<td>
									<v-text-field
										:disabled="isDisabled(props.item, 'batch')"
										v-model="props.item.batch"
										single-line
										solo
										v-validate="{ required: (props.item.is_required) }"
										@keydown.enter.prevent="submit"
										:data-vv-name="`batch${props.index+1}`"
										:error-messages="
											errors.collect(`batch${props.index+1}`)
										"/>
								</td>
								<td>
									<TextDateField
										:disabled="isDisabled(props.item, 'expirationDate')"
										v-model="props.item.expiration_date"
										:label="`validade_${props.index+1}`"
										placeholder="dd/mm/yyyy"
										single-line
										solo
										v-validate="{ required: (props.item.is_required) }"
										:data-vv-name="`validade_${props.index+1}`"
										:error-messages="
											errors.collect(`validade_${props.index+1}`)
										"/>
								</td>
								<td class="text-xs-center" style="display: flex">
									<v-tooltip top v-if="isDisabled(props.item, 'tagId') && !loading">
										<v-btn small icon @click.stop="removeMedicine(props.index)" slot="activator">
											<v-icon>edit</v-icon>
										</v-btn>
										<span>Editar</span>
									</v-tooltip>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import { EtiquetaPresenter } from '@Presenter/etiqueta-presenter';

export default {
	name: 'TraceabilityDialog',
	$_veeValidate: { validator: 'new' },
	props: {
		boxId: {
			required: true,
			type: Number
		}
	},
	data: () => ({
		stockItems: [],
		items: [],
		loading: false,
		headers: [
			{
				text: 'Produto', value: 'produto', sortable: false
			},
			{
				text: 'Etiqueta', value: 'etiqueta', sortable: false, width: '150px'
			},
			{
				text: 'Lote', value: 'lote', sortable: false, width: '150px'
			},
			{
				text: 'Validade', value: 'validade', sortable: false, width: '200px'
			}
		]
	}),
	async created() {
		this.loading = true;
		try {
			this.stockItems = await BoxPresenter.manualPickingV2(this.boxId);
			this.items = this.stockItems.map(item => ({
				id: item.id,
				product_id: item.product.id,
				expiration_date: this.$functions.FormatDateFromRequest(item.expirationDate),
				batch: item.batch,
				ean: '',
				product_name: `${item.product.name} - ${item.product.presentation} - ${item.product.brand}`,
				tag_id: item.tagId,
				is_required: item.product.isControlled || !item.product.isOutOfBox
			}));
			this.loading = false;
		} catch (e) {
			this.$emit('error');
		} finally {
			this.loading = false;
		}
	},
	computed: {
		user_id() {
			return this.$store.getters.user.id;
		}
	},
	methods: {
		close() {
			this.$emit('update:close', false);
		},
		async getTagInfo(index) {
			const searchTag = this.items[index] && this.items[index].tag_id;
			if (searchTag) {
				try {
					this.loading = true;
					const tagInfo = await EtiquetaPresenter.get(searchTag);
					this.items[index].batch = tagInfo?.batch || null;
					this.items[index].expiration_date = tagInfo?.expirationDate ? this.$functions.FormatDateFromRequest(tagInfo?.expirationDate) : null;
				} catch (e) {
					this.items[index].batch = null;
					this.items[index].expiration_date = null;
					this.$store.dispatch('SHOW_SNACKBAR', {
						message: `Não foi possível buscar as informações da etiqueta: ${e.message}`,
						color: 'warning'
					});
				} finally {
					this.loading = false;
				}
			}
		},
		async save() {
			const formIsValidated = await this.$validator.validateAll();

			if (!formIsValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário inválido',
					color: 'error'
				});
				return;
			}

			if (!this.validateTags()) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Etiquetas não podem ser iguais',
					color: 'error'
				});
				return;
			}

			try {
				this.loading = true;
				await BoxPresenter.manualPicking(this.boxId, {
					user_id: this.user_id,
					items: this.items.map(i => ({
						...i,
						expiration_date: this.$functions.FormatDateToRequest(i.expiration_date)
					}))
				});

				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Box liberada para Pré Produção',
					color: 'success'
				});

				this.$emit('update:close', false);
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao liberar Box',
					color: 'error'
				});
			} finally {
				this.loading = false;
			}
		},
		validateTags() {
			const items = this.items.filter(i => i.is_required);
			const tags = new Set(items.map(i => i.tag_id));
			return tags.size === items.length;
		},
		// validateEan(a, b) {
		// 	const stockItem = this.stockItems.find(i => i.id === b);
		// 	if (stockItem
		// 		&& stockItem.product.eans
		// 		&& stockItem.product.eans.length
		// 	) {
		// 		if (!stockItem.product.eans.some(e => e === a)) {
		// 			this.items.find(i => i.id === b).ean = '';
		// 			this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Ean inválido' });
		// 		}
		// 	}
		// 	return true;
		// },
		removeMedicine(index) {
			this.stockItems[index].expirationDate = null;
			this.stockItems[index].tagId = null;
			this.stockItems[index].batch = null;
			this.items[index].tag_id = null;
			this.items[index].expiration_date = null;
			this.items[index].batch = null;
		},
		isDisabled(currentItem, field) {
			if (this.loading)
				return true;
			const item = this.stockItems.find(i => i.id === currentItem.id);
			return !!(item && item[field]);
		}
	}
};
</script>

<style lang="scss" scoped>
</style>

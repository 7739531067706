<template>
	<Dialog
		titulo="Ocorrências"
		subtitulo="Caso não tenha acontecido algo, apenas clique em salvar."
		btn_acao="Salvar"
		btn_cancelar="Cancelar"
		@acao="salvar()"
		@close="fecharModal()"
		:loading="loading">
		<v-container class="pa-0">
			<v-layout wrap>
				<template v-if="status === 'CHECKED'">
					<v-flex xs12>
						<v-select
							v-model="ocorrencias"
							:items="opcoes"
							label="Ocorrencias"
							multiple>
							<template v-slot:prepend-item>
								<v-list-item
									ripple
									@mousedown.prevent>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template>
						</v-select>
					</v-flex>
					<v-flex v-if="checkOutros" xs12>
						<v-textarea class="mt-3" v-model="outros" box hide-details label="Outras ocorrências"
							placeholder="Quais foram as outras ocorrencias? Digite aqui" />
					</v-flex>
				</template>
				<v-flex v-else xs12>
					<v-textarea class="mt-3" v-model="ocorrencia" box hide-details label="Outras ocorrências"
						placeholder="Aconteceu algo durante a produção? Se sim, digite aqui." />
				</v-flex>
				<v-flex xs12>
					<slot />
				</v-flex>
				<v-flex xs12 v-if="envelopeIsEnabled">
					<v-checkbox
						v-model="hasStockLeftOver"
						primary
						hide-details
						label="Sobrou estoque para este paciente"/>
				</v-flex>
				<v-flex xs12 v-if="hasStockLeftOver">
					<v-text-field
						v-model="envelopes"
						label="Digite o código do(s) envelope(s) separados por vírgula"
						type="text"
						data-vv-name="Envelopes"
						v-validate="{ required: hasStockLeftOver }"
						:error-messages="errors.collect('Envelopes')"/>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { OcorrenciaPresenter } from '@Presenter/ocorrencia-presenter';
import { EnvelopePresenter } from '@Presenter/envelope-presenter';
import { featureFlags } from '@Consts/feature-flags';

export default {
	name: 'DialogOcorrencias',
	inject: ['$validator'],
	props: {
		status: {
			type: String,
			required: true
		},
		box_id: {
			type: Number,
			required: true
		},
		subscriptionId: {
			type: Number,
			required: false
		},
		request: {
			type: Function,
			required: true
		}
	},
	data: () => ({
		loading: false,
		opcoes: [
			'Resetar Box',
			'Outros',
			'Medicamento no horário errado',
			'Falta de medicamento',
			'Produzido por outra pessoa',
			'Esqueceu finalizar',
			'Falta de etiqueta',
			'Esqueceu iniciar',
			'Sem box',
			'Comprimidos já cortados',
			'Problema Software',
			'Pausa para almoço',
			'Comprimido no copinho',
			'Caiu comprimido'
		],
		ocorrencias: [],
		ocorrencia: '',
		justificativa: '',
		solucao: '',
		outros: '',
		hasStockLeftOver: false,
		envelopes: ''
	}),
	methods: {
		async validate() {
			return Boolean(await this.$validator.validateAll());
		},
		async setEnvelopes() {
			try {
				const params = {
					envelopes: this.envelopes,
					subscriptionId: this.subscriptionId,
					hasEnvelopes: this.hasStockLeftOver
				};
				await EnvelopePresenter.setEnvelopes(params);
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao atualizar codigo do envelope' });
			}
		},
		async salvar() {
			if (this.opcoes.some(opt => opt.selected) && !this.solucao) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'É necessário informar a solução' });
				return;
			}
			const fieldIsValid = await this.validate();
			if (!fieldIsValid) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Se houver sobra, é necessário informar o código do(s) envelope(s)'
				});
				return;
			}
			this.loading = true;
			const ocorrenciaFinal = [...this.ocorrencias, this.ocorrencia].filter(o => !!o).map(ocorr => {
				if (ocorr === 'Outros' && this.outros)
					ocorr = `Outros: ${this.outros}`;

				return ocorr;
			}).join(' | ');

			const promises = [
				OcorrenciaPresenter.store({
					box_id: this.box_id,
					status: this.status,
					ocorrencia: ocorrenciaFinal
				}),
				this.request()
			];
			if (this.envelopeIsEnabled)
				promises.push(this.setEnvelopes());

			Promise.all(promises).then(() => {
				this.fecharModal();
			}).finally(() => {
				this.loading = false;
			});
		},
		fecharModal() {
			this.$emit('update:close', false);
		}
	},
	computed: {
		checkOutros() {
			if (this.ocorrencias.includes('Outros'))
				return true;
			return false;
		},
		envelopeIsEnabled() {
			return (this.$store.getters.isFeatureFlagEnabled(featureFlags.enable_envelope_code_on_conference)
				&& this.subscriptionId);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.chip {
	display: inline-block;
	background: rgba(0,0,0,.06);
	border-radius: 15px;
	padding: 5px 10px;
	margin: 5px;

	&.selected {
		background: $purple;
		color: white;
	}
}
</style>

<template>
	<section>
		<header class="mb-4">
			<h1 class="text--text mr-5" style="opacity: 0.8; font-size: 35px">
				Picking Manual
			</h1>
		</header>
		<LoaderDialog v-if="loading"/>
		<template v-if="!loading">
			<Detail v-if="boxToProduce"
				:status="getCurrentStatus"
				:boxId="boxToProduce.id"
				:subscriptionId="boxToProduce.subscriptionId"
				:patientName="boxToProduce.patient.name"
				:globalTags="boxToProduce.patient.globalTags"
				:surplusStock="boxToProduce.stockLeft"
				:logStockInfo="boxToProduce.logStockInfo"
				@startBoxProduction="startBoxProduction(boxToProduce.id, boxToProduce.logStockInfo)"/>
			<Choice v-else @search="getBoxToProduce" />
		</template>
	</section>
</template>

<script>
import { BoxPresenter } from '@Presenter/box-presenter';
import LoaderDialog from '@Views/componentes/loader-dialog.vue';
import { boxStatusBoxMap } from '@Consts/status-box';
import { boxProductionStatusMap } from '@Mixins/box-production';
import Choice from './choice';
import Detail from './detail';

export default {
	name: 'InProduction',
	components: {
		Choice,
		Detail,
		LoaderDialog
	},
	data() {
		return {
			loading: false,
			boxToProduce: null
		};
	},
	methods: {
		async getBoxToProduce(identifier) {
			this.loading = true;
			try {
				const boxToProduceResponse = await BoxPresenter.getBoxManualPicking(identifier);
				console.log(boxToProduceResponse);
				this.boxToProduce = boxToProduceResponse;
			} catch (error) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					message: error.message,
					color: 'error'
				});
			} finally {
				this.loading = false;
			}
		},
		async startBoxProduction(identifier, data) {
			this.loading = true;
			try {
				const finalData = {
					user_id: this.user_id,
					items: data.map(item => ({
						product_id: item.medicamento_id,
						tag_id: item.id, // Assuming tag ID is represented by the item ID
						batch: item.lote,
						expiration_date: item.validade,
						price: item.valor
					}))
				};
				const boxStartProduceResponse = await BoxPresenter.manualPicking(identifier, finalData);
				this.boxToProduce.status = boxStartProduceResponse.status;
			} catch (error) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao atualizar lote e validade da Box',
					color: 'error'
				});
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		getCurrentStatus() {
			if (this.boxToProduce.status === boxStatusBoxMap.PICKING)
				return boxProductionStatusMap.WAITING;
			if (this.boxToProduce.status === boxStatusBoxMap.PROCESS_PICKING)
				return boxProductionStatusMap.WAITING;
			return boxProductionStatusMap.FINISHED;
		},
		user_id() {
			return this.$store.getters.user.id;
		}
	}
};
</script>

<style lang="scss" scoped>
header {
	border-bottom-width: 5px;
	border-bottom-color: #ffd9a0 !important;
	border-bottom-style: solid;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>

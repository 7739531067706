<template>
	<section>
		<template v-if="isFinished">
			<div class="finished mt-md-4">
				<v-icon x-large color="#2DCE71">check_circle</v-icon>
				<strong class="title font-weight-bold">Finalizada com <br> sucesso</strong>
			</div>
		</template>
		<template v-else>
			<ActionStart
				v-if="isWaiting"
				:boxId="boxId"
				:logStockInfo="logStockInfo"
				@confirm="startProduction" />
		</template>
	</section>
</template>

<script>
import { BoxProductionMixin } from '@Mixins/box-production';
import ActionStart from './start.vue';

export default {
	mixins: [BoxProductionMixin],
	components: {
		ActionStart
	},
	props: {
		boxId: {
			required: true
		},
		logStockInfo: {
			required: true
		}
	},
	methods: {
		startProduction() {
			this.$emit('startProduction');
		}
	}
};
</script>

<style lang="scss" scoped>
section {
	.finished {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
	}
}
</style>

<template>
	<v-layout row class="align-center">
		<v-icon class="pa-1" color="green" size="18" style="border: 2px solid #312b1d; border-radius: 50px; ">
			monetization_on
		</v-icon>

		<h1 class="text--text mx-2" style="opacity: .6;">
			Pagamento: <strong>{{ modalidade }}</strong>
		</h1>

		<v-btn small icon class="green white--text ml-0 text-right" slot="activator" v-if="permiteAlterarModalidade"
			@click="onClickAlterarModalidade">
			<v-icon size="12">fas fa-pen</v-icon>
		</v-btn>

		<v-dialog persistent scrollable v-model="showDialog" transition="fade" max-width="600px">
			<transition>
				<Dialog :titulo="'Troca de modalidade de assinatura'" @close="showDialog = false">
					<v-container fluid grid-list-lg class="p-4 text-center">

						<h5 style="font-size: 20px;" class="mb-3">Selecione abaixo a modalidade de assinatura desejada:
						</h5>

						<p v-if="showAlertEstoque" style="color: red">
							<strong>
								<template v-if="deveFazerCobranca">
									Esse Far.membro possui estoque. Lembre-se de realizar a cobrança antes de seguir com
									a troca.
								</template>
								<template v-else>
									A troca não poderá ser realizada, pois o paciente possui estoque.
								</template>
							</strong>
						</p>

						<v-layout align-center class="ml-4">
							<v-radio-group v-model="novaModalidade" :column="false">
								<v-radio v-if="modalidadesAssinatura.INTEGRAL" :label="`Pagamento ${modalidadesAssinatura.INTEGRAL}`"
									:value="modalidadesAssinatura.INTEGRAL" color="green" />
							</v-radio-group>
						</v-layout>

						<v-checkbox v-if="showAlertEstoque && deveFazerCobranca" v-model="cienteDeFazerCobranca" hide-details label="Confirmo que contabilizei o estoque e que será enviado a cobrança" />

						<v-layout align-center justify-center row items-center class="text-center">
							<v-flex xs6>
								<v-btn @click="showDialog = false" color="green"
									style="color: #FFFFFF; width:90%;">
									Cancelar
								</v-btn>
							</v-flex>
							<v-flex xs6>
								<v-btn color="primary" :loading="carregando" style=" width:90%;"
									:disabled="!atualizacaoDaModalidadeEstaDisponivel" @click="updateModalidade">
									Confirmar
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</Dialog>
			</transition>
		</v-dialog>
	</v-layout>
</template>

<script>
import modalidadesAssinatura from '@Consts/modalidade_assinatura';
import { PacientePresenter } from '@Presenter/paciente-presenter';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { featureFlags } from '@Consts/feature-flags';

export default {
	name: 'ModalidadeAssinatura',
	props: {
		assinaturaId: {
			required: true,
			type: Number
		},
		pacienteId: {
			required: true,
			type: Number
		},
		modalidade: {
			required: true,
			type: String,
			validator(value) {
				return Object.values(modalidadesAssinatura).includes(value);
			}
		}
	},
	data() {
		return {
			modalidadesAssinatura,
			showDialog: false,
			carregando: true,
			cienteDeFazerCobranca: false,
			estoque: [],
			novaModalidade: this.modalidade
		};
	},
	computed: {
		permiteAlterarModalidade() {
			const enableIntegrality = this.$store.getters.isFeatureFlagEnabled(featureFlags.integrality_b2b);
			if (enableIntegrality && this.modalidade === this.modalidadesAssinatura.INTEGRAL)
				return false;

			return (this.$store.getters.eh_administrador || this.$store.getters.eh_rt);
		},
		temEstoque() {
			return this.estoque.length > 0 && this.estoque.some(medicamentoEstoque => medicamentoEstoque.qtd_caixas > 0 || medicamentoEstoque.qtd_comprimidos > 0);
		},
		temEstoquePago() {
			return this.temEstoque && (this.modalidade === this.modalidadesAssinatura.INTEGRAL);
		},
		showAlertEstoque() {
			return this.temEstoque && this.modalidadeEstaDiferente;
		},
		modalidadeEstaDiferente() {
			return this.modalidade !== this.novaModalidade;
		},
		atualizacaoDaModalidadeEstaDisponivel() {
			if (this.showAlertEstoque && this.deveFazerCobranca && this.cienteDeFazerCobranca)
				return true;

			return this.modalidadeEstaDiferente && !this.temEstoquePago && !this.showAlertEstoque;
		},
		deveFazerCobranca() {
			return this.novaModalidade === this.modalidadesAssinatura.INTEGRAL && this.modalidade === this.modalidadesAssinatura.PROPORCIONAL;
		}
	},
	mounted() {
		this.getEstoque();
	},
	methods: {
		getEstoque() {
			this.carregando = true;
			PacientePresenter.estoque(this.pacienteId, { assinatura_id: this.assinaturaId })
				.then(response => {
					this.estoque = response;
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro carregar estoque do paciente' });
				})
				.finally(() => {
					this.carregando = false;
				});
		},
		updateModalidade() {
			this.carregando = true;
			AssinaturaPresenter.updateModalidade(this.assinaturaId, this.novaModalidade)
				.then(({ message, warning }) => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message });

					if (warning)
						this.$store.dispatch('SHOW_SNACKBAR', { color: 'warning', message: warning, timeout: 5000 });

					this.$store.dispatch('assinatura/FETCH_ASSINATURA', this.assinaturaId);
				})
				.catch(() => {
					this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao atualizar modalidade' });
				})
				.finally(() => {
					this.carregando = false;
				});
		},
		onClickAlterarModalidade() {
			this.showDialog = !this.showDialog;
		}
	}
};
</script>

<style scoped>
h1 {
	font-size: 14px;
}
</style>

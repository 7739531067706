<template>
	<transition>
		<v-card style="border-radius: 0 !important">
			<v-toolbar dark flat class="purple-title" height="70px">
				<v-toolbar-title style="color: #342b1d" class="titulo text-bold">Nova assinatura</v-toolbar-title>
				<v-btn style="color: #342b1d"  dark flat @click="dialog_fechar = true">
					<v-icon small class="mr-2">close</v-icon> Cancelar
				</v-btn>
			</v-toolbar>
			<v-card-text>
				<div class="content-wrapper pa-md-5">
					<v-window v-model="passo" touchless>
						<v-window-item :value="1">
							<EscolherPessoa
								ref="paciente"
								:type="type"
								:assinatura="assinatura"
								:paciente_cadastrado="paciente_cadastrado"
								:clinica="clinica" scope="1"
								:cpfPreSelecionado="cpfPreSelecionado"
								:filial="textFilial"
								:orcamento="orcamento"
								@selected-rest-home="(restHome) => setRestHome(restHome)" />
						</v-window-item>

						<v-window-item :value="2">
							<EscolherEndereco ref="endereco" :type="type" :assinatura="assinatura" :clinica="clinica" scope="3"
								:casa_repouso="casa_repouso" />
						</v-window-item>

						<v-window-item :value="3">
							<ConfirmarAssinatura ref="confirmarAssinatura" :medicines_checklist="medicines_checklist" :type="type" :assinatura="assinatura" :clinica="clinica"
								scope="4" @check-medicine="(id) => checkMedicine(id)" />
						</v-window-item>
					</v-window>
				</div>

				<div class="progress-wrapper">
					<v-layout fill-height align-center>
						<v-flex style="flex: 0">
							<v-btn flat :icon="mobile" :disabled="pilha_passos.length === 0"
								@click="passoAnterior">
								<v-icon :small="desktop" :class="desktop ? 'mr-2' : null">arrow_back</v-icon>
								{{ desktop ? 'Voltar' : null }}
							</v-btn>
						</v-flex>
						<v-flex class="px-md-4">
							<div class="progress">
								<div :style="{ width: tamanhoPasso }" />
								<div :style="{ width: tamanhoPassoRestante }" />
							</div>
						</v-flex>
						<v-flex style="flex: 0">
							<v-btn depressed :icon="mobile" :color="ultimoPasso ? 'success' : 'primary'"
								@click="proximoPasso()" :disabled="!habilitarProximo" :loading="loading">
								{{ desktop ? ultimoPasso ? 'Confirmar' : 'Continuar' : null }}
								<v-icon v-if="ultimoPasso" :small="desktop" :class="desktop ? 'ml-2' : null">done
								</v-icon>
								<v-icon v-else :small="desktop" :class="desktop ? 'ml-2' : null">arrow_forward
								</v-icon>
							</v-btn>
						</v-flex>
					</v-layout>
				</div>
				<v-dialog persistent v-model="dialog_fechar" max-width="380px">
					<transition>
						<Dialog v-if="dialog_fechar" titulo="Deseja cancelar?"
							subtitulo="Se você cancelar, irá perder todos os dados." btn_acao="Continuar"
							btn_excluir="Sim, cancelar" @acao="dialog_fechar = false"
							@excluir="$emit('update:close', false)" @close="dialog_fechar = false" />
					</transition>
				</v-dialog>
			</v-card-text>
		</v-card>
	</transition>
</template>

<script>
import { compress } from 'lz-string';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { PacientePresenter } from '@Presenter/paciente-presenter';
import { BH_CASA_REPOUSO_DOMICILIO_ID, SP_CASA_REPOUSO_DOMICILIO_ID } from '@Consts/casa_repouso_domicilio';
import { companyUnitIds } from '@Consts/unidades';
import { RESPONSIBLE_PAYMENT_REST_HOME } from '@Consts/rest-home/responsible-payment';
import modalidadesAssinatura from '@Consts/modalidade_assinatura';
import EscolherPessoa from './escolher-pessoa';
import EscolherEndereco from './escolher-endereco';
import ConfirmarAssinatura from './confirmar-assinatura';

export default {
	name: 'NovaAssinatura',
	$_veeValidate: { validator: 'new' },
	components: {
		EscolherPessoa, EscolherEndereco, ConfirmarAssinatura
	},
	props: {
		orcamento: {
			type: Object,
			default: null
		},
		type: {
			type: String // 'b2c' || b2b
		},
		clinica: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		assinatura: {
			modalidade: modalidadesAssinatura.PROPORCIONAL,
			paciente: {},
			endereco: {},
			medicamentos: [],
			receitas: [],
			pedido_id: ''
		},
		total_passos: 3,
		passo: 1,
		pilha_passos: [],
		loading: false,
		dialog_fechar: false,
		criandoPaciente: false,
		criandoResponsavel: false,
		id_doc_relacao_medicamentos: null,
		cpfPreSelecionado: null,
		casa_repouso: {},
		medicines_checklist: []
	}),
	created() {
		if (this.orcamento) {
			this.orcamento.medicamentos = this.orcamento.medicamentos.map(medicamento => {
				const med = { ...medicamento, ...medicamento.pivot };
				delete med.pivot;
				if (med.posologia && !this.$functions.isB2CQuotation(this.type))
					med.posologia = JSON.parse(med.posologia);
				return med;
			});
			if (this.orcamento.casa_repouso && this.orcamento.casa_repouso.responsavel_pagamento === RESPONSIBLE_PAYMENT_REST_HOME.value)
				this.assinatura.modalidade = modalidadesAssinatura.INTEGRAL;
			this.assinatura.cobranca_externa = this.orcamento.cobranca_externa;
			this.assinatura.sem_box = this.orcamento.sem_box;
			this.assinatura.observacao = this.orcamento.observacao;
			this.assinatura.valor_servico = this.orcamento.valor_servico;
			this.assinatura.paciente_orcamento = {
				nome: this.orcamento.nome,
				telefone: this.orcamento.telefone,
				email: this.orcamento.email || '',
				cpf: this.orcamento.cpf || '',
				nascimento: this.orcamento.data_nascimento || '',
				sexo: this.orcamento.sexo,
				tags: this.orcamento.tags || [],
				casaRepousoId: this.orcamento.casa_repouso_id
			};
			this.assinatura.orcamento_id = this.orcamento.id;
			this.assinatura.medicamentos = this.orcamento.medicamentos;

			if (this.$functions.isB2CQuotation(this.type)) {
				this.assinatura.v2 = true;
				this.assinatura.endereco = this.orcamento.endereco;
				this.cpfPreSelecionado = this.orcamento.cpf;
				this.assinatura.data_proxima_box = this.orcamento.data_proxima_box;
				this.assinatura.pedido_id = this.orcamento.pedido_id;
			}
			this.getSubscriptionDataFromQuotation();
		}
	},
	methods: {
		salvarAssinatura() {
			return new Promise(resolve => {
				const data = {
					modalidade: this.assinatura.modalidade,
					responsavel_id: this.assinatura.paciente.responsaveis[0].id,
					paciente_id: this.assinatura.paciente.id,
					observacao: this.assinatura.observacao || null,
					sem_box: this.assinatura.sem_box,
					cobranca_externa: this.assinatura.cobranca_externa || false,
					valor_servico: this.assinatura.valor_servico,
					medicamentos: this.assinatura.medicamentos.map(med => ({
						medicamento_id: med.medicamento_id,
						preco_personalizado: med.preco_personalizado || null,
						posologia: med.posologia ? JSON.stringify(med.posologia) : null,
						qtd_caixas: med.qtd_caixas ? Number(med.qtd_caixas) : null,
						desconto: med.desconto,
						aproveitamento_receita: med.aproveitamento_receita,
						divisivel: this.$functions.isB2CQuotation(this.type) ? med.divisivel : !med.liquido
					})),
					receitas: this.assinatura.receitas.map(rec => ({
						receita_base64: compress(rec.dataBase64),
						tipo: rec.type,
						name: rec.name
					})),
					data_proxima_box: this.$functions.FormatDateToRequest(this.assinatura.data_proxima_box),
					clinica: this.clinica,
					pedido_id: this.assinatura.pedido_id
				};

				if (this.assinatura.orcamento_id)
					data.orcamento_id = this.assinatura.orcamento_id;

				data.is_rest_home_address = (this.assinatura.endereco.id && !this.assinatura.endereco.assinatura_id);

				data.endereco = this.assinatura.endereco;

				if (this.orcamento && this.orcamento.filial_id)
					data.orcamento_filial_id = this.orcamento.filial_id;
				else
					data.orcamento_filial_id = this.correspondingUnit;

				data.isB2CQuotation = this.orcamento && this.$functions.isB2CQuotation(this.type);
				AssinaturaPresenter.store(data)
					.then(response => {
						response.created_at = ((this.$functions.FormatDateFromRequest(response.created_at)).split(' '))[0];
						this.$emit('assinatura-salva', response);
						this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Assinatura realizada com sucesso.' });
						resolve(true);
					})
					.catch(() => resolve(false));
			});
		},
		async proximoPasso() {
			const valido = await this.$validator.validateAll(String(this.passo));
			if (!valido) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: this.errors.all()[0] });
				return;
			}

			this.loading = true;
			let request_success = true;
			switch (this.passo) {
				case 1:
					request_success = true;
					break;
				case 2:
					request_success = true;
					break;
				case 3:
					// if (this.medicines_checklist?.filter(med => med.checked).length < this.assinatura.medicamentos?.length) {
					// 	this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Você não conferiu todos os medicamentos.' });
					// 	request_success = false;
					if (!this.$refs.confirmarAssinatura.check_concordancia) {
						this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Você não comparou e conferiu todos os itens contidos na prescrição médica ou termo de consentimento assinado pelo cliente.' });
						request_success = false;
					} else if (!this.$refs.confirmarAssinatura.assinatura.data_proxima_box) {
						this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Você não preencheu o dia do início da box' });
						request_success = false;
					} else
						request_success = await this.salvarAssinatura();
					break;
				default:
					break;
			}
			this.loading = false;

			if (request_success) {
				this.pilha_passos.unshift(this.passo);

				if (this.passo !== 5)
					this.passo += 1;
			}
		},
		passoAnterior() {
			this.passo = this.pilha_passos[0];
			this.pilha_passos.shift();
		},
		async createPatientWithQuotationData(quotationData) {
			let restHomeId = BH_CASA_REPOUSO_DOMICILIO_ID;

			if (this.orcamento && (parseInt(this.orcamento.filial_id, 10) === companyUnitIds.SP))
				restHomeId = SP_CASA_REPOUSO_DOMICILIO_ID;

			const novoPaciente = {
				cpf: quotationData.paciente_orcamento.cpf,
				email: quotationData.paciente_orcamento.email,
				nascimento: quotationData.paciente_orcamento.nascimento,
				nome: quotationData.paciente_orcamento.nome,
				sexo: quotationData.paciente_orcamento.sexo,
				telefone: quotationData.paciente_orcamento.telefone,
				casa_repouso_id: restHomeId
			};
			const createdPatient = await PacientePresenter.store(novoPaciente);
			this.assinatura.paciente = createdPatient;
			return createdPatient;
		},
		getSubscriptionDataFromQuotation() {
			if (this.$functions.isB2CQuotation(this.type)) {
				this.criandoPaciente = true;
				this.createPatientWithQuotationData(this.assinatura)
					.catch(err => {
						this.cpfPreSelecionado = this.orcamento.cpf;
						if (err.message !== 'CPF já está em uso por outro Farmembro.')
							this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: err.message });
						else
							this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Paciente foi encontrado no FarmeOS e selecionado' });
					}).finally(() => {
						this.criandoPaciente = false;
					});
			}
		},
		checkMedicine(id) {
			this.medicines_checklist = this.medicines_checklist
				?.map(med => ({ ...med, checked: med.id === id ? !med.checked : med.checked }));
		},
		setRestHome(restHome) {
			if (restHome && restHome.responsavel_pagamento === RESPONSIBLE_PAYMENT_REST_HOME.value) {
				this.assinatura.modalidade = 'integral';
				this.assinatura.cobranca_externa = true;
			}
			this.casa_repouso = restHome;
		}
	},
	computed: {
		visao_colaborador() {
			return this.$store.getters.visao_colaborador;
		},
		endereco_cadastrado() {
			return typeof this.assinatura.endereco.id === 'number';
		},
		paciente_cadastrado() {
			return typeof this.assinatura.paciente.id === 'number';
		},
		paciente_preenchido() {
			const {
				nome, cpf, telefone, nascimento
			} = this.assinatura.paciente;
			return !!nome && !!cpf && !!telefone && !!nascimento;
		},
		ultimoPasso() {
			return this.total_passos === this.passo;
		},
		mobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		desktop() {
			return this.$vuetify.breakpoint.mdAndUp;
		},
		tamanhoPasso() {
			return `${(this.passo / this.total_passos) * 100}%`;
		},
		tamanhoPassoRestante() {
			return `calc(100% - ${(this.passo / this.total_passos) * 100}% - 15px)`;
		},
		is_endereco_valido() {
			if (this.clinica)
				return true;

			const { endereco } = this.assinatura;
			return endereco.cidade && endereco.estado && endereco.cep && endereco.logradouro && endereco.numero && endereco.bairro;
		},
		habilitarProximo() {
			switch (this.passo) {
				case 1:
					return (this.assinatura.paciente || {}).id;
				case 2:
					return this.is_endereco_valido;
				case 3:
					return true;
				default:
					return false;
			}
		},
		correspondingUnit() {
			return Number(localStorage.getItem('unidade'));
		},
		textFilial() {
			let unit;

			if (this.orcamento && this.orcamento.filial_id)
				unit = this.orcamento.filial_id;
			else
				unit = this.correspondingUnit;

			switch (unit) {
				case 1:
					return 'Belo Horizonte';
				case 2:
					return 'São Paulo';
				default:
					return '';
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.v-card {
  .v-toolbar {
    ::v-deep .v-toolbar__content {
      display: flex;
      justify-content: space-between !important;

      .v-btn {
        .v-btn__content {
          text-transform: uppercase;
        }
      }
    }
  }

  .v-card__text {
    background: #f2f4f6;
    min-height: calc(100vh - 138px);

    .content-wrapper {
      max-width: $break-point-lg;
      margin: 0 auto;
      padding-bottom: 68px !important;

      ::v-deep .v-window {
        .v-window__container {
          min-height: calc(100vh - 286px);
        }
      }
    }

    .progress-wrapper {
      box-shadow: 0px -10px 60px rgba(0, 0, 0, 0.05);
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 20px;
      background: #fff;

      .progress {
        position: relative;
        display: flex;
        border-radius: 10px;
        height: 10px;
        justify-content: flex-start;

        :nth-child(1) {
          position: relative;
          z-index: 1;
          transition: width 0.4s ease-in-out;
          margin: 0;
          border-radius: 10px;
          height: inherit;
          background-color: $green;
        }

        :nth-child(2) {
          position: absolute;
          top: 0;
          right: 0;
          transition: width 0.4s ease;
          margin: 0;
          border-radius: 10px;
          height: inherit;
          background-color: #e0e0e0;
        }
      }
    }
  }
}
</style>

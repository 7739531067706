import $http from '@Config/axios';

const prefix = '/v2/etiquetas';
/**
 *
 * @param {String} tag
 *
 * @typedef {Object} GetTagResponse
 * @property {String} tag
 * @property {String} batch
 * @property {String} expirationDate
 *
 * @returns {Promise<GetTagResponse>}
 */
async function get(tag) {
	const response = await $http.get(`${prefix}/${tag}`);
	return {
		tag: response.tag,
		batch: response.batch,
		expirationDate: response.expirationDate
	};
}

export const EtiquetaPresenter = {
	get
};

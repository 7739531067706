<template>
	<div class="px-md-5">

		<HeaderProcesso
			titulo="Picking"
			subtitulo="Gere a lista de picking, a ordem de produção e adicione medicamentos ao estoque dos pacientes."
			:subtitulo2="`Aqui estão ${tabSelected === 'boxesTab' ? 'listadas as boxes' : 'listados os pedidos'} que já passaram pela etapa de compra e Sucesso.`"
			btn_label="Gerar lista de picking"
			:btn_loading="tabSelected === 'boxesTab' ? loading_picking : loadingPickingListOrders"
			:btn_disabled="tabSelected === 'boxesTab' ? selecionados.length === 0 : selectedOrders.length === 0"
			:btn_on_click="tabSelected === 'boxesTab' ? () => { dialog_lista_compras = true } : () => { generatePdfOrderPickingList() } "
			:isSignatures="tabSelected === 'boxesTab'"
			@busca="val => busca = val">
			<v-btn v-if="tabSelected === 'boxesTab'" depressed class="primary" :disabled="selecionados.length === 0" :loading="loading_picking_prod"
				@click="gerarPdfPickingEOrdemDeProducao">
				Lista de picking e ordem de produção
			</v-btn>
		</HeaderProcesso>

		<TabsDataTable
			:boxesLength="boxes.length"
			:ordersLength="ordersProcessTotal"
			:tabSelected="tabSelected"
			@switchTab="val => switchTab(val)"/>

		<!-- Conteúdo -->
		<DataTableProcesso
			v-if="tabSelected==='boxesTab'"
			selectable
			:boxes="boxes"
			:loading="carregando"
			:search="busca"
			:buttons="buttons"
			@selected-updated="arr => selecionados = arr" />

		<DataTablePedidos
			v-if="tabSelected==='ordersTab'"
			selectable
			ref="dataTableOrders"
			:orders="orders"
			:loading="loadingOrders"
			:buttons="orderButtons"
			:total-items="ordersProcessTotal"
			@pagination-updated="val => getOrdersProcess(val)"
			@selected-updated="arr => selectedOrders = arr"/>

		<!-- Estoque dialog -->
		<v-dialog persistent v-model="dialog_estoque" max-width="800px" scrollable transition="fade">
			<transition>
				<DialogEstoque v-if="dialog_estoque" :paciente="item_selecionado.assinatura.paciente" :box_id="item_selecionado.box_id" :assinatura_id="item_selecionado.assinatura.id" :close.sync="dialog_estoque" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialog_bipe" max-width="1020px" scrollable
			transition="fade">
			<transition>
				<DialogBipe v-if="dialog_bipe"
					:subscriptionId="item_selecionado.assinatura.id"
					:paciente="item_selecionado.assinatura.paciente"
					:box_id="item_selecionado.box_id"
					:close.sync="dialog_bipe" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_enviar_faturamento" transition="fade" max-width="500px">
			<transition>
				<DialogConfirmacao v-if="dialog_enviar_faturamento" @close="dialog_enviar_faturamento = false" :request="updateStatus('PICKED')" :opcoes="[
					'Confirmo que fiz o picking corretamente'
				]" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_ordem_producao" transition="fade" max-width="920px">
			<transition>
				<DialogEscolherMedicamentos v-if="dialog_ordem_producao" :box_id="item_selecionado.box_id"
					@close="dialog_ordem_producao = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent scrollable v-model="dialog_etiquetas_antigas" transition="fade" max-width="920px">
			<transition>
				<DialogEscolherMedicamentos v-if="dialog_etiquetas_antigas" :box_id="item_selecionado.id" etiqueta
					@close="dialog_etiquetas_antigas = false" />
			</transition>
		</v-dialog>

		<v-dialog v-model="dialog_lista_compras" max-width="350px">
			<transition>
				<DialogListaCompras v-if="dialog_lista_compras" :gerar-lista-compra="baixarListaPicking" titulo="Lista Picking" @close="dialog_lista_compras = false" />
			</transition>
		</v-dialog>

		<v-dialog v-model="dialog_products_bipe" max-width="1020px" scrollable
			transition="fade">
			<DialogBipeOrders v-if="dialog_products_bipe" :order="item_selecionado" @productsPicked="onProductsPicked" :changeStatusProcess="changeStatusProcess()" @close="dialog_products_bipe = false"/>
		</v-dialog>

	</div>
</template>

<script>
import { ProcessoMixin } from '@Mixins/processo';
import { BoxPresenter } from '@Presenter/box-presenter';
import { openBuffer } from '@Util/functions';
import TabsDataTable from '@Componentes/tabs-data-table.vue';
import DialogListaCompras from '@Componentes/dialog-lista-compras';
import DialogEscolherMedicamentos from '@Componentes/dialog-escolher-medicamentos';
import { ProcessTypes } from '@Consts/status-order-process';
import { OrderProcessMixin } from '@Mixins/order-process';
import DialogBipeOrders from '@Views/colaborador/orders/dialog-bipe-orders/index.vue';
import { OrderPresenter } from '@Presenter/order-presenter';
import DialogBipe from './dialog-bipe';
import DialogEstoque from './dialog-estoque';

export default {
	name: 'Picking',
	components: {
		DialogBipe, DialogEstoque, DialogEscolherMedicamentos, DialogListaCompras, TabsDataTable, DialogBipeOrders
	},
	mixins: [ProcessoMixin, OrderProcessMixin],
	data: () => ({
		processName: ProcessTypes.PICKING,
		statusToShow: ['PICKING', 'PROCESS_PICKING'],
		gerando: false,
		loading_picking: false,
		loading_picking_prod: false,
		loadingPickingListOrders: false,
		dialog_bipe: false,
		dialog_estoque: false,
		dialog_lista_compras: false,
		dialog_ordem_producao: false,
		dialog_etiquetas_antigas: false,
		dialog_enviar_faturamento: false,
		dialog_products_bipe: false
	}),
	created() {
		this.orderButtons = [
			{
				label: 'Adicionar produtos/ Enviar para faturamento',
				icon: 'fas fa-plus-square',
				onClick: item => this.abrirDialog(item, 'dialog_products_bipe')
			}
		];

		this.buttons = [
			{
				label: 'Gerar ordem de produção',
				icon: 'fas fa-file-pdf',
				onClick: item => this.abrirDialog(item, 'dialog_ordem_producao'),
				condition: item => !item.sem_box
			},
			{
				label: 'Gerar etiqueta de rastreio',
				icon: 'fas fa-qrcode',
				onClick: item => this.gerarEtiquetaRastreio(item)
			},
			{
				label: 'Gerar etiquetas do saquinho',
				icon: 'fas fa-file-alt',
				onClick: item => this.abrirDialog(item, 'dialog_etiquetas_antigas')
			},
			{
				label: 'Ver estoque do paciente',
				icon: 'fas fa-eye',
				onClick: item => this.abrirDialog(item, 'dialog_estoque')
			},
			{
				label: 'Adicionar medicamento ao estoque do paciente',
				icon: 'fas fa-plus-square',
				onClick: item => this.abrirDialog(item, 'dialog_bipe')
			},
			{
				label: 'Enviar para faturamento',
				icon: 'attach_money',
				onClick: item => this.abrirDialog(item, 'dialog_enviar_faturamento')
			}
		];
	},
	methods: {
		changeStatusProcess() {
			return this.updateOrderProcess(ProcessTypes.PICKING, 'SUCCESS', {});
		},
		async onProductsPicked(metadata) {
			try {
				await OrderPresenter.updateOrderProcess(
					this.item_selecionado.id, ProcessTypes.PICKING, { status: 'SUCCESS', metadata }
				);
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'success',
						message: 'Status do pedido atualizado com sucesso!'
					}
				);
				this.getOrdersProcess();
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'error',
						message: error.message || 'Erro ao finalizar Picking'
					}
				);
			} finally {
				this.dialog_products_bipe = false;
			}
		},
		async baixarListaPicking(tipo) {
			if (tipo === 'pdf')
				await this.gerarPdf();
			else
				await this.gerarListaCompra(tipo);
		},
		async gerarPdf() {
			const idsBox = this.selecionados.map(a => a.box_id);
			const pdf = await BoxPresenter.gerarPdfPicking({ ids: idsBox });
			openBuffer(pdf);
			return pdf;
		},
		async gerarPdfPickingEOrdemDeProducao() {
			try {
				this.loading_picking_prod = true;
				const idsBox = this.selecionados.map(a => a.box_id);
				const pdf = await BoxPresenter.gerarPdfPickingEOrdemDeProducao({ ids: idsBox });
				openBuffer(pdf);
				return pdf;
			} catch (err) {
				throw new Error(err);
			} finally {
				this.loading_picking_prod = false;
			}
		},
		async generatePdfOrderPickingList() {
			try {
				this.loadingPickingListOrders = true;
				const orderIds = this.selectedOrders.map(order => order.id);
				const pdf = await OrderPresenter.generatePdfOrderPickingList({ orderIds });

				openBuffer(pdf);
				return pdf;
			} catch (err) {
				throw new Error(err);
			} finally {
				this.loadingPickingListOrders = false;
			}
		}
	}
};
</script>

<template>
	<section>
		<div class="header">
			<h3>Receitas</h3>

			<v-btn
				color="primary"
				@click.stop="openedUploadPrescriptionDialog = true">
				Adicionar receita
				<v-icon small>add</v-icon>
			</v-btn>
		</div>

		<v-container fluid grid-list-md class="px-0" >
			<PrescriptionList
				:prescriptions="prescriptions"
				:canDelete="canDelete"
				@deletePrescription="deletePrescription"/>
		</v-container>

		<v-dialog
			v-model="openedUploadPrescriptionDialog"
			persistent
			scrollable
			transition="fade"
			max-width="1024px">
			<transition>
				<UploadPrescriptionDialog
					v-if="openedUploadPrescriptionDialog"
					:close.sync="openedUploadPrescriptionDialog"
					@prescriptionToBeCreated="createPrescription"/>
			</transition>
		</v-dialog>
	</section>
</template>

<script>
import UploadPrescriptionDialog from '@Componentes/dialog-comparar-receitas-medicamentos/dialog-upload-receitas';
import PrescriptionList from './prescription-list.vue';

export default {
	name: 'PharmaceuticDialogPrescriptonSection',
	components: {
		UploadPrescriptionDialog,
		PrescriptionList
	},
	props: {
		canDelete: {
			required: false,
			type: Boolean,
			default: false
		},
		prescriptions: {
			required: false,
			type: Array,
			default: () => ([])
		}
	},
	data() {
		return {
			openedUploadPrescriptionDialog: false
		};
	},
	methods: {
		createPrescription(data) {
			this.$emit('createPrescription', data);
			this.openedUploadPrescriptionDialog = false;
		},
		deletePrescription(prescriptionId) {
			this.$emit('deletePrescription', prescriptionId);
		}
	}
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px dashed #3B2C1B80;
	padding-bottom: 10px;

	h3 {
		font-size: 25px;
		font-weight: 400;
		line-height: 30px;
	}
}
</style>

<template>
	<div class="boxes-wrapper px-md-5">
		<HeaderProcesso
			titulo="Atendimento"
			subtitulo="Visualize os medicamentos e informações de cada box."
			subtitulo2="Aqui estão listadas as boxes com atendimento iniciado pelo Farmacêutico."
			btn_label="Gerar lista de compra"
			btn_label_extra="Copiar nomes"
			:btn_disabled="selecionados.length === 0"
			:btn_disabled_extra="selecionados.length === 0"
			:btn_on_click="() => {dialog_lista_compras = true;}"
			:btn_on_click_extra="copyResidentNames"
			:btn_loading="gerando"
			@busca="(val) => (busca = val)"
			:show_casa_filter="true"
			:show_filter_status_box="true"
			@casas-repouso-updated="onCasasRepouso"
			@filtro-status-box="onFiltroStatusBox"/>

		<DataTableProcessoPagination
			selectable
			:showPrioridadeAtendimento="true"
			:loading="carregando"
			:search="busca"
			:buttons="buttons"
			:sort_by="sortBy"
			:descending="descending"
			:fetch_data="getDataPagination"
			:sortable_actions="true"
			ref="dataTablePagination"
			:rows_per_page="[15, 30, 45, { text: 'Todos', value: -1 }]"
			@selected-updated="arr => selecionados = arr"/>
		<template v-if="isNewDraftDialogEnabled">
			<DraftDialog
				v-if="dialog_prescricoes"
				:boxId="item_selecionado.id"
				:subscriptionId="item_selecionado.assinatura_id"
				:patientId="item_selecionado.paciente.id"
				@close="closeAlteracoes" />

		</template>
		<template v-else>
			<v-dialog
				persistent
				v-model="dialog_prescricoes"
				fullscreen
				transition="fade">
				<transition>
					<Dialog
						titulo="Alteracoes"
						v-if="dialog_prescricoes"
						@close="closeAlteracoes">
						<Alteracoes
							:assinatura_id="item_selecionado.assinatura_id"
							:box_id="item_selecionado.id"
							:item="item_selecionado"
							:prescriptionIsVisible="false"
							:prescriptionReadonly="true"
							@close="closeAlteracoes"/>
					</Dialog>
				</transition>
			</v-dialog>
		</template>

		<v-dialog
			persistent
			scrollable
			v-model="dialog_enviar_picking"
			transition="fade"
			max-width="500px">
			<transition>
				<DialogConfirmacao
					v-if="dialog_enviar_picking"
					@close="dialog_enviar_picking = false"
					:request="updateStatus('PICKING')"
					:opcoes="['Confirmo que o pedido está pronto para ser liberado para Picking',]"/>
			</transition>
		</v-dialog>

		<v-dialog v-model="dialog_lista_compras" max-width="350px">
			<transition>
				<DialogListaCompras
					v-if="dialog_lista_compras"
					:gerar-lista-compra="gerarListaCompra"
					@close="dialog_lista_compras = false"/>
			</transition>
		</v-dialog>

		<v-dialog
			persistent
			scrollable
			v-model="dialog_pendencias"
			transition="fade"
			max-width="620px">
			<transition>
				<DialogPendencias
					v-if="dialog_pendencias"
					:item="item_selecionado"
					:box_id="item_selecionado.box_id"
					@close="fecharPendencias"/>
			</transition>
		</v-dialog>

		<v-dialog v-model="dialog_alteracoes" max-width="500px">
			<transition>
				<DialogAlteracoes
					v-if="dialog_alteracoes"
					:box_id="item_selecionado.box_id"
					:item="item_selecionado"
					@close="dialog_alteracoes = false; getDataPagination();"
					@rascunho-aberto="dialog_alteracoes = false; dialog_prescricoes = true;"/>
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { AtendimentoFarmaceuticoMixin } from '@Mixins/atendimento-farmaceutico';
import Alteracoes from '@Componentes/alteracoes';
import DialogListaCompras from '@Componentes/dialog-lista-compras';
import HeaderProcesso from '@Componentes/header-processo';
import DataTableProcessoPagination from '@Componentes/data-table-processo-pagination';
import { BoxPresenter } from '@Presenter/box-presenter';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { copyToClipboard, openBuffer } from '@Util/functions';
import { featureFlags } from '@Consts/feature-flags';
import { RascunhoPresenter } from '@Presenter/alteracoes-presenter';
import DialogAlteracoes from './dialog-alteracoes';
import DraftDialog from '../draft-dialog/index.vue';

export default {
	name: 'Atendimento',
	components: {
		HeaderProcesso,
		DataTableProcessoPagination,
		Alteracoes,
		DialogListaCompras,
		DialogAlteracoes,
		DraftDialog
	},
	// recursos compartilhados entre Farmaceutico e Atendimento
	mixins: [AtendimentoFarmaceuticoMixin],
	data: () => ({
		dialog_prescricoes: false,
		dialog_lista_compras: false,
		dialog_iniciar_suporte: false,
		dialog_entrega_atrasada: false,
		dialog_entrega_realizada: false,
		dialog_iniciar_atendimento: false,
		dialog_enviar_picking: false,
		alterouPendencia: false,
		selecionados: [],
		item_selecionado: null,
		gerando: false,
		carregando: false,
		busca: '',
		sortBy: 'dias_a_iniciar',
		descending: false,
		casaRepousoIds: [],
		filtroStatusBox: 'ativas',
		pagination: {
			sortBy: 'dias_a_iniciar',
			descending: false,
			page: 1,
			rowsPerPage: 15
		},
		loadingPrintQuotation: false
	}),
	created() {
		this.buttons = this.buildButtons();
	},
	computed: {
		isAtendimento() {
			return this.$route.name === 'Atendimento';
		},
		isCompra() {
			return this.$route.name === 'Compra';
		},
		isNewDraftDialogEnabled() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.new_draft_dialog);
		}
	},
	methods: {
		async getDataFromAPI(pagination, search, casas_repouso, filtroStatusBox) {
			this.carregando = true;

			const params = {
				...pagination, search, casa_repouso_ids: casas_repouso, filtroStatusBox
			};

			const resp = {};
			await BoxPresenter.fetchAtendimento({ params })
				.then(response => {
					resp.items = response.items;
					resp.total = response.total;
				})
				.finally(() => {
					this.carregando = false;
				});
			return resp;
		},
		async getDataPagination() {
			if (this.$refs.dataTablePagination && this.$refs.dataTablePagination.pagination) {
				this.pagination = { ...this.$refs.dataTablePagination.pagination };
				this.pagination.order = this.pagination.descending ? 'desc' : 'asc';
				const resp = await this.getDataFromAPI(this.pagination, this.busca, this.casaRepousoIds, this.filtroStatusBox);
				const items = resp.items;
				const total = resp.total;
				this.$refs.dataTablePagination.updatePage(items, total);
			}
		},
		async printQuotation(item) {
			this.loadingPrintQuotation = true;
			try {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Orçamento sendo impresso, aguarde...' });
				const pdf = await RascunhoPresenter.print(item.id);
				openBuffer(pdf);
				return pdf;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao gerar o PDF do orçamento pendência' });
			} finally {
				this.loadingPrintQuotation = false;
			}
		},
		onCasasRepouso(casaRepousoIds) {
			this.casaRepousoIds = casaRepousoIds;
			this.getDataPagination();
		},
		onFiltroStatusBox(filtroStatusBox) {
			this.filtroStatusBox = filtroStatusBox;
			this.getDataPagination();
		},
		buildButtons() {
			const buttons = this.getButtons();
			const pendenciesButton = this.buildPendenciesButton();
			const pickingButton = this.buildPickingButton();
			const printQuotationButton = this.buildPrintQuotationButton();
			buttons.splice(1, 0, pendenciesButton);
			buttons.push(pickingButton);
			buttons.push(printQuotationButton);
			return buttons;
		},
		buildPendenciesButton() {
			return {
				icon: 'fas fa-clipboard-list',
				getLabel: this.pendenciesLabelSuporte,
				setColor: this.pendenciesColor,
				onClick: item => this.abrirDialog(item, 'dialog_pendencias'),
				condition: item => this.isServiceStarted(item)
			};
		},
		buildPickingButton() {
			return {
				label: 'Enviar para Picking',
				icon: 'all_inbox',
				onClick: item => this.abrirDialog(item, 'dialog_enviar_picking')
			};
		},
		buildPrintQuotationButton() {
			return {
				label: 'Imprimir Orçamento',
				icon: 'print',
				onClick: item => this.printQuotation(item)
			};
		},
		pendenciesLabelSuporte(item) {
			const color = this.pendenciesColor(item);

			if (color === 'success')
				return 'Pendências concluídas';
			if (color === 'primary')
				return 'Pendências concluídas mas não finalizadas';
			if (color === 'error')
				return 'Pendências não concluídas';
			if (color === 'warning')
				return 'Aguardando resposta do Farmacêutico';
			if (color === '')
				return 'Sem pendência';

			return 'Aguardando ação';
		},
		gerarListaCompra(tipo) {
			return new Promise(async (resolve, reject) => {
				this.gerando = true;
				try {
					const ids = this.selecionados.map(a => a.box_id);
					const promises = [
						AssinaturaPresenter.listaDeCompras({ ids, compra: this.isCompra })
					];
					if (tipo === 'pdf')
						promises.push(import('@Util/pdf-lista-compra'));
					else
						promises.push(import('@Util/excel-lista-compra'));
					const responses = await Promise.all(promises);
					const { geraListaDeCompras } = responses[1];
					geraListaDeCompras(responses[0]);
					if (this.isCompra)
						this.getDataPagination();
				} catch (error) {
					reject();
				}
				this.gerando = false;
				resolve();
			});
		},
		copyResidentNames() {
			const residentNames = this.selecionados.map(item => `${item.paciente.nome} \n`);
			copyToClipboard(residentNames.join(''));
			this.$store.dispatch('SHOW_SNACKBAR', { message: 'Nomes de residentes copiados', color: 'success' });
		},
		closeAlteracoes() {
			this.getDataPagination();
			this.dialog_prescricoes = false;
		}
	}
};
</script>

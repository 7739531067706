<template>
	<section>
		<v-data-table
			:items="items"
			:headers="headers"
			v-model="selected"
			:loading="loading"
			:total-items="getTotal"
			class="custom-data-table"
			:disable-initial-sort="true"
			:pagination.sync="pagination"
			rows-per-page-text="Items por página"
			:rows-per-page-items="itemsPerPageOptions"
			no-results-text="Nenhum resultado encontrado"
			:no-data-text="loading ? 'Carregando...' : 'Nenhum item encontrado'">
			<template v-slot:headers="props">
				<tr>
					<th width="10px">
						<v-checkbox
							:input-value="props.all"
							:indeterminate="props.indeterminate"
							primary
							hide-details
							@click.stop="toggleAll"></v-checkbox>
					</th>
					<th
						v-for="header in props.headers"
						:key="header.text"
						:width="header.width"
						class="column"
						:class="[
							header.sortable ? 'sortable' :  '',
							pagination.descending ? 'desc' : 'asc',
							header.value === pagination.sortBy ? 'active' : ''
						]"
						@click="changeSort(header)">
						<v-icon v-if="header.sortable " small>arrow_upward</v-icon>
						{{ header.text }}
					</th>
				</tr>
			</template>

			<template v-slot:items="props">
				<tr :active="props.selected">
					<td @click="updateSelected(props)">
						<v-checkbox
							:disabled="!props.item.canRetryProcessing"
							:input-value="props.selected"
							primary
							hide-details></v-checkbox>
					</td>
					<td class="text-left">
						<router-link :to="{name: 'Assinatura', params: {id: props.item.subscriptionId}}" class="black--text">
							{{ props.item.subscriptionId }}
						</router-link>
						<template v-if="props.item.subscriptionId">|</template>
						{{ props.item.id }}
					</td>
					<td class="text-center">
						<div class="items-center justify-center ">
							<template v-if="props.item.patient.globalTags && props.item.patient.globalTags.length > 0">

								<GlobalTags
									v-for="(globalTag, index) in props.item.patient.globalTags"
									:key="index"
									:global_tag="globalTag"/>
							</template>
							<span class="ml-2">{{ props.item.patient.name }}</span>
						</div>
					</td>
					<td class="text-center">
						<template v-if="props.item.restHome">
							{{ props.item.restHome.name }}
						</template>
						<template v-else>
							----
						</template>
					</td>
					<td class="text-center">
						<span
							v-if="viewTypeIsBox()"
							class="mr-2"
							:class="{'font-weight-bold' : props.item.isPickingDateCalculated}">{{ props.item.pickingDate | formatDate('DD/MM/YYYY') }}</span>
						<span
							v-else
							class="mr-2">{{ props.item.startDate | formatDate('DD/MM/YYYY') }}</span>
						<v-tooltip v-if="props.item.isPickingDateCalculated" bottom>
							<template v-slot:activator="{ on }">
								<v-avatar size="30">
									<v-icon v-on="on">error_outline</v-icon>
								</v-avatar>
							</template>
							<span>Data calculada pelo sistema</span>
						</v-tooltip>
					</td>
					<td class="text-center">
						{{ props.item.startDate | formatDate('DD/MM/YYYY') }}
					</td>
					<td class="text-center">
						<template v-if="viewTypeIsBox()">
							{{ props.item.status | statusBox }}
							<span v-if="props.item.status === boxStatusBoxMap.PROCESS_PICKING">
								({{ props.item.medicinesWithTraceabilityQuantity}}/{{ props.item.boxMedicinesQuantity}})
							</span>
						</template>
						<template v-else>
							{{ getOrderProcessDescription(props.item.status) }}
						</template>

					</td>
					<td>
						<v-btn
							v-if="props.item.canRetryProcessing"
							:loading="loading"
							class="px-4"
							large
							color="primary"
							style="border-radius: 10px;"
							@click="$emit('retryProcessingId', props.item.id)">
							Enviar <br> Pedido
						</v-btn>
						<v-btn
							:v-if="showManualPicking"
							:loading="loading"
							class="px-0"
							large
							color="primary"
							style="border-radius: 10px;"
							@click="openTraceability(props.item.id)">
							Liberar<br>Manual
						</v-btn>
					</td>
				</tr>
			</template>
		</v-data-table>
		<!-- Dialog rastreabilidade -->
		<v-dialog persistent scrollable v-model="showTraceabilityDialog" transition="fade" max-width="920px">
			<transition>
				<TraceabilityDialog v-if="this.showTraceabilityDialog"
					:boxId=this.selectedBoxId
					@error="onTraceabilityError"
					:close.sync="showTraceabilityDialog" />
			</transition>
		</v-dialog>
	</section>
</template>

<script>
import GlobalTags from '@Componentes/global-tags';
import { getOrderProcessDescription } from '@Consts/status-order';
import { boxStatusBoxMap } from '@Consts/status-box';
import unleash from '@Util/unleash';
import TraceabilityDialog from './traceability-dialog.vue';

export default {
	name: 'ListOrdersTable',
	components: {
		GlobalTags, TraceabilityDialog
	},
	props: {
		loading: {
			required: false,
			type: Boolean,
			default: false
		},
		items: {
			required: false,
			type: Array,
			default: () => ([])
		},
		view: {
			required: false,
			type: String,
			default: 'BOXES'
		},
		totalBoxes: {
			required: false,
			type: Number,
			default: 0
		},
		totalOrders: {
			required: false,
			type: Number,
			default: 0
		},
		itemsPerPageOptions: {
			required: false,
			type: Array,
			default: () => ([5, 10, 25, 100])
		},
		itemsPerPage: {
			required: false,
			type: Number,
			default: 5
		}
	},
	created() {
		unleash.on(
			'update',
			() => {
				this.updateFeatureFlag(!!unleash.isEnabled('FARMEBOX_SHOW_MANUAL_PICKING_FIX'));
			}
		);
	},
	data() {
		return {
			showManualPicking: false,
			showTraceabilityDialog: false,
			generatingTags: false,
			getOrderProcessDescription,
			boxStatusBoxMap,
			boxId: null,
			pagination: {
				descending: false,
				page: 1,
				rowsPerPage: this.itemsPerPage,
				sortBy: 'id',
				totalItems: this.total
			},
			selected: []
		};
	},
	computed: {
		headers() {
			if (this.viewTypeIsBox()) {
				return [
					{
						text: 'AssinaturaId | BoxId',
						value: 'header-id',
						sortable: false,
						width: '10px'
					},
					{
						text: 'Nome',
						value: 'header-customer',
						sortable: false
					},
					{
						text: 'Origem',
						value: 'header-origin',
						sortable: false
					},
					{
						text: 'Previsão de picking',
						value: 'pickingDate',
						sortable: true,
						width: '10px'
					},
					{
						text: 'Data de inicio',
						value: 'header-box-start-date',
						sortable: false
					},
					{
						text: 'Status',
						value: 'header-produced-by',
						sortable: false
					},
					{
						sortable: false,
						width: '10px'
					}
				];
			}
			return [
				{
					text: 'AssinaturaID | ID',
					value: 'header-id',
					sortable: false,
					width: '10px'
				},
				{
					text: 'Nome',
					value: 'header-customer',
					sortable: false
				},
				{
					text: 'Origem',
					value: 'header-origin',
					sortable: false
				},
				{
					text: 'Data de entrega',
					value: 'productionDate',
					sortable: true,
					width: '10px'
				},
				{
					text: 'Data de inicio',
					value: 'header-box-start-date',
					sortable: false
				},
				{
					text: 'Status',
					value: 'header-produced-by',
					sortable: false
				},
				{
					sortable: false,
					width: '10px'
				}
			];
		},
		getTotal() {
			if (this.viewTypeIsBox())
				return this.totalBoxes;

			return this.totalOrders;
		}
	},
	methods: {
		viewTypeIsBox() {
			return this.view === 'BOXES';
		},
		closeTraceabilityDialog() {
			this.showTraceabilityDialog = false;
			this.selectedBoxId = null;
		},
		updateFeatureFlag(value) {
			this.showManualPicking = value;
		},
		openTraceability(boxId) {
			this.selectedBoxId = boxId;
			this.showTraceabilityDialog = true;
		},
		onTraceabilityError() {
			this.showTraceabilityDialog = false;
			this.selectedBoxId = null;
		},
		updateFilters() {
			this.$emit('filter', {
				pagination: {
					page: Number(this.pagination.page),
					perPage: Number(this.pagination.rowsPerPage)
				},
				sorting: {
					field: this.pagination.sortBy,
					direction: this.pagination.descending ? 'DESC' : 'ASC'
				}
			});
		},
		updateSelected(props) {
			if (props.item.canRetryProcessing)
				props.selected = !props.selected;
		},
		toggleAll() {
			if (this.selected.length)
				this.selected = [];
			else
				this.selected = this.items.filter(item => item.canRetryProcessing === true).slice();
		},
		changeSort(header) {
			if (!header.sortable)
				return;
			const column = header.value;
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		}
	},
	watch: {
		pagination: {
			deep: true,
			handler() {
				this.updateFilters();
			}
		},
		selected: {
			handler() {
				this.$emit('updatedSelected', this.selected);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
header {
	border-bottom-width: 5px;
	border-bottom-color: #ffd9a0 !important;
	border-bottom-style: solid;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.column-overflow {
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

::v-deep .custom-data-table  {
	.v-table {
		background-color: $primary_light;
		border-collapse: separate;
		border-spacing: 0 5px;

		thead th {
			padding-top: 10px;
			padding-bottom: 10px;
			height: 100%;
		}
		thead {
			tr {
				background-color: transparent;
			}

			th {
				padding: 5px 10px;
				height: 100%;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					border: 2px solid transparent;
				}

				&:last-child {
					border: 4px solid transparent;
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}
		tbody {

			tr {
				background-color: $secondary-light;
			}

			td {
				padding: 5px 10px;
				height: 100%;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					border: 2px solid $secondary-light;
				}

				&:last-child {
					border: 4px solid $secondary-light;
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}

	}

	.v-datatable {
		.v-datatable__actions {
			background-color: $primary_light;
		}
	}
}
</style>

<template>
	<div>
		<v-menu offset-y>
			<template v-slot:activator="{ on }">

				<slot name="activator" v-bind="{ on }"></slot>

			</template>
			<v-card>
				<v-list class="bg-purple">
					<v-list-tile @click.stop="openDuplicate(item)" :disabled="ruleDisableDuplicate(item)">
						<v-list-tile-action>
							<v-icon color="black" :disabled="ruleDisableDuplicate(item)">payments</v-icon>
						</v-list-tile-action>
						<v-list-tile-title>Gerar nova via</v-list-tile-title>
					</v-list-tile>
					<v-divider />
					<v-list-tile @click.stop="generateRefund(item)" :disabled="ruleDisableRefund(item)">
						<v-list-tile-action>
							<v-icon color="black" :disabled="ruleDisableRefund(item)">replay</v-icon>
						</v-list-tile-action>
						<v-list-tile-title>Estorno</v-list-tile-title>
					</v-list-tile>
					<v-divider />
					<v-list-tile @click.stop="cancelInvoice(item)" :disabled="ruleDisableCancelInvoice(item)">
						<v-list-tile-action>
							<v-icon color="red" :disabled="ruleDisableCancelInvoice(item)">cancel</v-icon>
						</v-list-tile-action>
						<v-list-tile-title :class="ruleDisableCancelInvoice(item) ? 'grey--text' : 'red--text'">Cancelar fatura</v-list-tile-title>
					</v-list-tile>
				</v-list>
			</v-card>
		</v-menu>
		<v-dialog
			persistent
			scrollable
			v-model="dialogRefund"
			transition="fade"
			max-width="680px">
			<transition>
				<DialogRefund
					v-if="dialogRefund"
					@close="closeRefunds"
					:invoiceId="item.id"
					:invoiceTotalAmount="item.total_amount" :refunds="item.refunds" :status="item.status" />
			</transition>
		</v-dialog>
		<v-dialog
			persistent
			scrollable
			v-model="dialogCancelInvoice"
			transition="fade"
			max-width="680px">
			<transition>
				<DialogCancelInvoice
					v-if="dialogCancelInvoice"
					@close="closeCancelInvoice"
					:invoiceId="item.id"/>
			</transition>
		</v-dialog>

		<v-dialog
			persistent
			v-model="dialogReGenerateInvoice"
			scrollable
			transition="fade"
			max-width="900px">
			<transition>
				<DialogGenerateInvoice
					v-if="dialogReGenerateInvoice"
					:invoice="item"
					:loading="loading"
					@duplicate-invoice="invoice => generateDuplicate(invoice)"
					@close="dialogReGenerateInvoice = false"
					:close.sync="dialogReGenerateInvoice"/>
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import { statusEnum, paymentsMethod, typesEnum } from '@Consts/invoices';
import { InvoicesPresenter } from '@Presenter/invoices-presenter';
import DialogRefund from './dialog-refund.vue';
import DialogCancelInvoice from './dialog-cancel-invoice.vue';
import DialogGenerateInvoice from './dialog-re-generate-invoice.vue';

export default {
	name: 'ManageInvoice',
	components: {
		DialogRefund, DialogCancelInvoice, DialogGenerateInvoice
	},
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		dialogRefund: false,
		dialogCancelInvoice: false,
		dialogReGenerateInvoice: false,
		loading: false

	}),
	methods: {
		openDuplicate() {
			this.dialogReGenerateInvoice = true;
		},
		generateRefund() {
			this.dialogRefund = true;
		},
		cancelInvoice() {
			this.dialogCancelInvoice = true;
		},
		closeCancelInvoice() {
			this.dialogCancelInvoice = false;
			this.$emit('load-invoice');
		},
		closeRefunds() {
			this.dialogRefund = false;
			this.$emit('load-invoice');
		},
		ruleDisableDuplicate(item) {
			return !([statusEnum.EXPIRED, statusEnum.PENDING, statusEnum.OVERDUE, statusEnum.CANCELED]
				.includes(item.status) && this.item.reference_type !== typesEnum.PONTUAL);
		},
		ruleDisableRefund(item) {
			if (!item.payments_config || item.payments_config.length === 0)
				return true;

			const paymentMethod = item.payments_config[0].method;
			return !((item.status === statusEnum.PAID || item.status === statusEnum.PARTIAL_REFUND || item.status === statusEnum.PROTESTED)
			&& (paymentMethod === paymentsMethod.creditCard || paymentMethod === paymentsMethod.pix));
		},
		ruleDisableCancelInvoice(item) {
			return !([statusEnum.EXPIRED, statusEnum.PENDING, statusEnum.OVERDUE]
				.includes(item.status) && this.allowCancelInvoices);
		},
		async generateDuplicate(invoice) {
			try {
				this.loading = true;
				const duplicatedInvoice = await InvoicesPresenter.duplicateInvoice(invoice.id, invoice);
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Nova via gerada com sucesso'
				});
				this.$router.push({ name: 'invoice', params: { id: duplicatedInvoice.id } });
			} catch (e) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: `Erro ao gerar nova via: ${e.message}`
				});
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		allowCancelInvoices() {
			return this.$store.getters.allow_cancel_invoices;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>

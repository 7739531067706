<template>
	<section>
		<LoaderDialog v-if="loading"/>
		<v-dialog
			value="true"
			persistent
			fullscreen>
			<div class="dialog">

				<div class="dialog-header px-4">
					<div class="dialog-header-content">
						<div class="dialog-title">
							<h1>Farmacêutico</h1>
						</div>

						<v-btn
							outline
							flat
							@click="$emit('close')"
							style="color: #342b1d;border-radius: 5px">
							Fechar <v-icon small right>close</v-icon>
						</v-btn>
					</div>
				</div>

				<div v-if="!loading" class="dialog-body px-4">
					<GeneralData
						class="my-5"
						:subscription="subscription"
						:boxId="boxId"
						@updateObservation="updateObservation"/>

					<PendenceSection
						class="my-5"
						:isCreatePendenceAvailable="isOriginSubcription || canApproveChanges"
						:pendences="pendences"
						@createPendence="createPendence"
						@finishPendence="finishPendence" />

					<PrescriptionSection
						class="my-5"
						:canDelete="true"
						:prescriptions="prescriptions"
						@createPrescription="createPrescription"
						@deletePrescription="deletePrescription" />

					<MedicineSection
						class="my-5"
						:items="medicines"
						:subscription="subscription"
						:canApproveChanges="canApproveChanges"
						:prescriptions="prescriptions"
						:isOriginSubcription="isOriginSubcription"
						@reload="getData()"
						@approveDrat="approve"/>
				</div>
			</div>
		</v-dialog>
	</section>

</template>

<script>
import { StatusBoxPresenter } from '@Presenter/status-box-presenter';
import { RascunhoPresenter } from '@Presenter/alteracoes-presenter';
import LoaderDialog from '@Views/componentes/loader-dialog.vue';
import { PacientePresenter } from '@Presenter/paciente-presenter';
import { AssinaturaPresenter } from '@Presenter/assinatura-presenter';
import { PendenciaPresenter } from '@Presenter/pendencia-presenter';
import { BoxPresenter } from '@Presenter/box-presenter';
import { ReceitaPresenter } from '@Presenter/receita-presenter';
import { compress } from 'lz-string';
import { isBoxInProcess } from '@Util/functions';
import logo from '@Assets/images/farme-logo-animada.gif';
import GeneralData from './general-data/index.vue';
import PendenceSection from './pendences/index.vue';
import PrescriptionSection from './prescriptions/index.vue';
import MedicineSection from './medicines/index.vue';

export default {
	name: 'PharmaceuticDialog',
	components: {
		LoaderDialog,
		GeneralData,
		PendenceSection,
		PrescriptionSection,
		MedicineSection
	},
	props: {
		boxId: {
			required: true,
			type: Number
		},
		subscriptionId: {
			required: true,
			type: Number
		},
		patientId: {
			required: true,
			type: Number
		},
		canApproveChanges: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			subscription: null,
			pendences: [],
			medicines: [],
			prescriptions: [],
			cycleDiscount: {
				value: 0,
				type: null
			},
			loading: false,
			origin: null, // subscription | draft
			logo
		};
	},
	computed: {
		isOriginSubcription() {
			return this.origin === 'subscription';
		}
	},
	async created() {
		await this.getData();
	},
	methods: {
		async getData() {
			this.loading = true;
			const hasDraft = await RascunhoPresenter.get(this.boxId);
			if (typeof hasDraft === 'object')
				await this.getDataFromDraft();
			else
				await this.getDataFromSubscription();
		},
		async getDataFromDraft() {
			this.origin = 'draft';
			this.loading = true;
			const promises = [
				AssinaturaPresenter.show(this.subscriptionId),
				PendenciaPresenter.buscarPendencias(this.boxId),
				RascunhoPresenter.get(this.boxId, { with_deleted: true }),
				BoxPresenter.show(this.boxId),
				PacientePresenter.estoque(this.patientId)
			];

			try {
				const [subscription, pendences, draft, box, stock] = await Promise.all(promises);
				this.subscription = subscription;
				this.pendences = pendences;
				this.cycleDiscount.value = draft.desconto_ciclo ? draft.desconto_ciclo.valor : null;
				this.cycleDiscount.type = draft.desconto_ciclo ? draft.desconto_ciclo.tipo : null;
				this.prescriptions = box.receitas;
				this.medicines = draft.medicamentos.map(am => {
					const stockFound = stock.find(s => s.id === am.medicamento_id);
					return {
						alteracao_id: am.id,
						...am,
						...am.medicamento,
						estoque: stockFound ? { comprimidos: stockFound.qtd_comprimidos, caixas: stockFound.qtd_caixas } : null,
						posologia: JSON.parse(am.posologia),
						deleted_at: am.deleted_at || am.medicamento.deleted_at,
						created_at: am.created_at,
						updated_at: am.updated_at,
						qtd_mensal: am.medicamento.qtd_mensal,
						qtd_mensal_sem_proporcionalidade: am.medicamento.liquido === 1 ? am.qtd_caixas : Math.ceil(am.medicamento.qtd_mensal / am.medicamento.qtd_embalagem),
						aproveitamento_receita: Number(am.ciclos_extras) > 1
					};
				});
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao dados do rascunho' });
			} finally {
				this.loading = false;
			}
		},
		async getDataFromSubscription() {
			this.origin = 'subscription';
			this.loading = true;
			const promises = [
				AssinaturaPresenter.show(this.subscriptionId),
				BoxPresenter.show(this.boxId),
				PendenciaPresenter.buscarPendencias(this.boxId),
				PacientePresenter.estoque(this.patientId)
			];

			try {
				const [assinatura, box, pendencias, estoque] = await Promise.all(promises);
				const item = box || assinatura;
				this.prescriptions = item.receitas;
				this.subscription = assinatura;
				this.medicines = item.medicamentos;
				this.pendences = pendencias;

				if (box && !isBoxInProcess(box.status)) {
					const aproveitamentos = await BoxPresenter.getAproveitamentoReceita(this.boxId);
					const assinaturaMedicamentos = assinatura.medicamentos.map(am => {
						const aproveitamento = aproveitamentos.find(ap => ap.medicineId === am.id);
						return {
							...am,
							created_at: am.pivot.created_at,
							updated_at: am.pivot.updated_at,
							deleted_at: am.pivot.deleted_at,
							ciclos_extras: aproveitamento ? aproveitamento.totalCycles : 1,
							receitas: am.receitas.filter(r => r.box_id === box.id)
						};
					});

					this.medicines = assinaturaMedicamentos;
				}

				this.medicines = this.medicines.map(med => {
					const stockFound = estoque.find(s => s.id === med.id);
					const medicineSubscription = assinatura.medicamentos.find(medicamento => (box && !isBoxInProcess(box.status) ? medicamento.id === med.id : medicamento.id === med.medicamento_id));
					return {
						...med,
						estoque: stockFound ? { comprimidos: stockFound.qtd_comprimidos, caixas: stockFound.qtd_caixas } : null,
						assinatura_id: assinatura.id,
						box_id: box.id,
						qnt_mensal: medicineSubscription?.qnt_mensal || null,
						qnt_mensal_sem_proporcionalidade: medicineSubscription?.qnt_mensal_sem_proporcionalidade || null
					};
				});
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro buscar dados da Assinatura' });
			} finally {
				this.loading = false;
			}
		},
		async createPrescription(data) {
			this.loading = true;
			try {
				await ReceitaPresenter.store({
					assinatura_id: this.subscriptionId,
					box_id: this.boxId,
					prescritor_id: data.prescriberId,
					receita_base64: compress(data.receita_base64),
					tipo: data.tipo,
					name: data.name,
					numero_notificacao: data.numero_notificacao,
					data_prescrita: data.data_prescrita ? this.$functions.FormatDateToRequest(data.data_prescrita) : null
				});
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'success',
					message: 'Receita adicionada com sucesso'
				});
				await this.getData();
			} catch (e) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Erro ao Salvar receita'
				});
			} finally {
				this.loading = false;
			}
		},
		async deletePrescription(prescriptionId) {
			this.loading = true;
			try {
				await ReceitaPresenter.destroy(prescriptionId);
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'success',
					message: 'Receita excluída com sucesso'
				});
				await this.getData();
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Erro ao excluir receita'
				});
			} finally {
				this.loading = false;
			}
		},
		async createPendence(params) {
			this.loading = true;
			try {
				await PendenciaPresenter.enviarPendencia({
					box_id: this.boxId,
					...params
				});
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Pendência criada com sucesso' });
				await this.getData();
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao criar pendência' });
			} finally {
				this.loading = false;
			}
		},
		async finishPendence(pendencyId) {
			this.loading = true;
			try {
				await PendenciaPresenter.concluirPendencia(pendencyId);
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Pendência concluída com sucesso' });
				await this.getData();
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao concluir pendência' });
			} finally {
				this.loading = false;
			}
		},
		async updateObservation(observation) {
			this.loading = true;
			try {
				await AssinaturaPresenter.updateObservacao(this.subscriptionId, observation);
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'success',
						message: 'Observação alterada com sucesso'
					}
				);
				await this.getData();
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'error',
						message: 'Erro ao alterar observação da assinatura'
					}
				);
			} finally {
				this.loading = false;
			}
		},
		async approve() {
			this.loading = true;
			try {
				await StatusBoxPresenter.store({ box_id: this.boxId, status: 'SERVICE_ENDED' });
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'success', message: 'Alterações aplicada com sucesso' });
				this.$emit('close');
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Erro ao aplicar alterações na assinatura' });
			} finally {
				this.loading = false;
			}
		},
		async applyDiscount(data) {
			this.loading = true;
			try {
				await RascunhoPresenter.updateDiscount(this.boxId, { valor: data.value, tipo: data.type });
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'success',
						message: 'Desconto aplicado com sucesso'
					}
				);
				await this.getData();
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'error',
						message: error.message || 'Erro ao atualizar descontos dos medicamentos'
					}
				);
			} finally {
				this.loading = false;
			}
		},
		async removeDiscount() {
			this.loading = true;
			try {
				await RascunhoPresenter.removeDiscount(this.boxId);
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'success',
						message: 'Desconto removido com sucesso'
					}
				);
				await this.getData();
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'error',
						message: 'Erro ao remover desconto do ciclo nos medicamento'
					}
				);
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.primary-light {
	background-color: red !important;
}
.tab-active {
	background:red !important;
}
.dialog {
	position: relative;
	width: 100%;
	height: 100%;
	height: 100vh;
	overflow-y: scroll;
	background-color: #FFEACC;
}

.dialog-close {
	position: absolute;
	top: 40px;
	right: 20px;
}
.dialog-header {
	padding-top: 95px;
	height: 215px;
	background-color: rgba(255, 200, 117, 0.5);
}

.dialog-header-content {
	display: flex;
	width: 100%;
	max-width: 1278px;
	margin: 0 auto;
	min-height: 100%;
	height: 100%;
	flex-direction: row;
	justify-content: space-between;
}

.dialog-title {
	h1 {
		font-size: 28px;
	}
}

.dialog-body {
	max-width: 1278px;
	width: 100%;
	margin: 0 auto;
	margin-top: -100px;
}
</style>

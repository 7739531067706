import { posologyTypes } from '@Consts/quotation-v2';
import unleash from '@Util/unleash';

/**
 * @param {Number} productPrice
 * @param {Number} quantityPillOrPackage
 * @param {Boolean} isDivisible
 * @returns {Promise<number>}
 */
export const calculatePricePerPillsOrPackage = (productPrice, quantityPillOrPackage, isDivisible) => {
	if (!isDivisible)
		return Math.ceil(productPrice);

	return Math.ceil(productPrice / quantityPillOrPackage);
};

/**
 * @param {Number} productPrice
 * @param {Number} quantityPillOrPackage
 * @param {Boolean} isDivisible
 * @returns {Promise<number>}
 */
export const getPackagePriceWithRounding = (productPrice, quantityPillOrPackage, isDivisible) => {
	const pricePerPillOrPackage = calculatePricePerPillsOrPackage(productPrice, quantityPillOrPackage, isDivisible);
	if (isDivisible)
		return pricePerPillOrPackage * quantityPillOrPackage;

	return pricePerPillOrPackage;
};

/**
 * @param {Number} frequency
 * @param {Array} days
 * @returns {Promise<number>}
 */
function getMedicineTotalDays(frequency, days) {
	let totalDays = 0;
	const monthWeeks = 4;

	switch (frequency) {
		case posologyTypes.allDays:
			totalDays = 30;
			break;
		case posologyTypes.dayYesDayNot:
			totalDays = 15;
			break;
		case posologyTypes.dayNotDayYes:
			totalDays = 15;
			break;
		case posologyTypes.weekly:
			totalDays = days.length * monthWeeks;
			break;
		case posologyTypes.monthly:
			totalDays = days.length;
			break;
		default: break;
	}

	return totalDays;
}

/**
 * @param {Object} posology
 * @param {Number} closedPackage
 * @returns {Promise<number>}
 */
export const getQuantityByProductItem = (posology, closedPackages) => {
	if (closedPackages)
		return closedPackages;

	if (posology) {
		const totalPills = posology.schedules.reduce(
			(total, item) => total + (item.quantidade_por_horario || item.pills),
			0
		);
		const weekDays = posology.daysOfWeek || [];
		const monthDays = posology.daysOfMonth || [];

		let totalDays;
		if (weekDays.length > 0)
			totalDays = getMedicineTotalDays(posology.frequency, weekDays);
		else if (monthDays.length > 0)
			totalDays = getMedicineTotalDays(posology.frequency, monthDays);
		else
			totalDays = getMedicineTotalDays(posology.frequency);

		return totalDays * totalPills;
	}

	return 0;
};

/**
 * @param {Number} totalPriceOfPackages
 * @param {Number} totalPriceOriginal
 * @returns {Promise<number>}
 */
export const getPercentageDiscount = (totalPriceOfPackages, totalPriceOriginal) => (100 - ((totalPriceOfPackages * 100) / totalPriceOriginal)).toFixed(2);

/**
 * @param {Object} properties
 * @param {String} property
 * @returns {Promise<any>}
 */
export const getPropertyValue = (properties, property) => {
	const propertyFound = (properties || []).find(item => item.name === property);
	const value = propertyFound?.value || null;
	return !property.startsWith('is')
		? value
		: value?.toString().toLowerCase() === 'true';
};

export const getClosedPackagesIntegralItem = (quantityPills, quantityPillsPerPackage) => Math.ceil((quantityPills || 0) / quantityPillsPerPackage);

export const calculateValuesOfDiscount = (product, closedPackagesWithPrescriptionUtilization) => {
	const isDivisible = product.drug ? getPropertyValue(product.drug.properties, 'isDivisible') : false;
	const originalPackagePrice = product.values.price;

	const quantityByProductItem = getQuantityByProductItem(product.posology, product.quantityOfBoxes);

	const originalPricePerUnit = calculatePricePerPillsOrPackage(originalPackagePrice, product.quantity, isDivisible);

	let closedPackages;
	if (closedPackagesWithPrescriptionUtilization)
		closedPackages = closedPackagesWithPrescriptionUtilization;
	else if (product.quantityOfBoxes)
		closedPackages = product.quantityOfBoxes;
	else if (!isDivisible)
		closedPackages = Math.ceil(quantityByProductItem / product.quantity);

	let totalPriceOriginal;
	let totalFinalPrice;
	const enableIntegrality = unleash.isEnabled('FARMEBOX_ENABLE_INTEGRALITY_B2C');

	if (closedPackages && !isDivisible) {
		totalPriceOriginal = originalPackagePrice * closedPackages;
		totalFinalPrice = (originalPackagePrice - product.values.nominalDiscount) * closedPackages;
	} else if (enableIntegrality) {
		const integralPackages = closedPackages;
		totalPriceOriginal = originalPackagePrice * integralPackages;
		totalFinalPrice = (originalPackagePrice - product.values.nominalDiscount) * integralPackages;
	} else {
		totalPriceOriginal = originalPricePerUnit * quantityByProductItem;
		totalFinalPrice = ((originalPackagePrice - product.values.nominalDiscount) / product.quantity) * quantityByProductItem;
	}

	const discountPercentage = getPercentageDiscount(totalFinalPrice, totalPriceOriginal);

	product.values = {
		...product.values,
		quantityByProductItem,
		totalFinalPrice,
		totalPriceOriginal,
		discountPercentage
	};

	return product.values;
};

<template>
	<div>
		<v-data-table
			v-model="selected"
			ref="DataTable"
			:items="quotations"
			:headers="headers"
			:loading="loadingQuotations"
			:no-data-text="no_data_text"
			no-results-text="Nenhum resultado encontrado"
			:rows-per-page-items="[15, 30, 45, 90, { text: 'Todos', value: -1 }]"
			:pagination.sync="pagination"
			:total-items="totalItems"
			rows-per-page-text="Itens por página">
			<template slot="headers" slot-scope="props">
				<tr>
					<th class="pr-0">
						<v-checkbox
							:input-value="props.all"
							:indeterminate="props.indeterminate"
							primary
							hide-details
							@click.stop="toggleAll"/>
					</th>
					<th
						v-for="header in props.headers"
						:key="header.text"
						:style="!header.sortable ? 'pointer-events: none' : ''"
						:class="[
							'column',
							{ sortable: header.sortable },
							pagination.descending ? 'desc' : 'asc',
							header.value === pagination.sortBy ? 'active' : '',
						]"
						@click="changeSort(header.value)">
						{{ header.text }}
						<v-icon small v-if="header.sortable">arrow_upward</v-icon>
					</th>
				</tr>
			</template>
			<template v-slot:items="props">
				<tr
					:active="props.selected"
					@click="openDialog(props.item, 'dialogDetail')"
					class="pointer">
					<td class="pr-0">
						<v-checkbox
							:input-value="props.selected"
							@click.stop="props.selected = !props.selected"
							primary
							hide-details/>
					</td>
					<td>{{ props.item.id }}</td>
					<td>
						<strong>{{ props.item.customer.name }}</strong>
					</td>
					<td>{{ props.item.origem || "Domicílio" }}</td>
					<td class="text-xs-center">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									{{ props.item.expiresAt | formatDate("DD/MM/YYYY") }}
								</span>
							</template>
							<span>Criado em
								<strong>{{
									props.item.createdAt | formatDate("DD/MM/YYYY")
								}}</strong></span>
						</v-tooltip>
					</td>
					<td class="text-xs-center">
						<span>
							{{ getStartDateText(props.item.startDate) }}
						</span>
					</td>
					<td class="text-xs-center">
						<span>
							{{ props.item.seller.name }}
						</span>
					</td>
					<td class="text-xs-center">
						<strong>{{ props.item.total | dinheiro }}</strong>
					</td>
					<td class="text-xs-center">
						<v-chip
							style="min-width: 100px"
							small
							disabled
							class="justify-center mx-0 white--text"
							:class="$functions.getStatusOrcamento(props.item.status).class">
							{{
								$functions
									.getStatusOrcamento(props.item.status)
									.label.toUpperCase()
							}}
						</v-chip>
					</td>
					<td>
						<v-menu offset-y>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" @click.stop class="botao-acao" flat>
									<span class="px-2">AÇÕES</span><v-icon>fas fa-ellipsis-h</v-icon>
								</v-btn>
							</template>
							<v-card>
								<v-list class="bg-purple">
									<v-list-tile
										@click.stop="crud(props.item)"
										:disabled="ruleDisableEdit(props)">
										<v-list-tile-action>
											<v-icon color="black" :disabled="ruleDisableEdit(props)">edit</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Editar orçamento</v-list-tile-title>
									</v-list-tile>
									<v-list-tile
										@click.stop="newSubscription(props.item)"
										:disabled="
											loadingQuotation ||
												props.item.status !== 'PAID'||
												generatingPdf
										">
										<v-list-tile-action>
											<v-progress-circular v-if="loadingQuotation" :size="25" indeterminate color="primary" />
											<v-icon
												v-else
												color="black"
												:disabled="
													props.item.status !== 'PAID'||
														generatingPdf
												">done_all</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Criar assinatura</v-list-tile-title>
									</v-list-tile>
									<v-divider />
									<v-list-tile
										@click="copyMedicationRelationshipMessage(props.item)">
										<v-list-tile-action>
											<v-icon color="primary">fas fa-file</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Copiar relação de med.</v-list-tile-title>
									</v-list-tile>
									<v-list-tile @click="copyUrl(props.item)">
										<v-list-tile-action>
											<v-icon color="primary">fas fa-share</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Copiar link</v-list-tile-title>
									</v-list-tile>
								</v-list>
							</v-card>
						</v-menu>
					</td>
				</tr>
			</template>
		</v-data-table>
		<v-dialog
			persistent
			v-model="dialogDetail"
			scrollable
			transition="fade"
			max-width="900px">
			<transition>
				<DialogDetalhesB2C
					v-if="dialogDetail"
					:sellers="sellers"
					:quotation_id="itemSelected.id"
					:close.sync="dialogDetail"/>
			</transition>
		</v-dialog>

		<v-dialog
			persistent
			v-model="dialogQuotation"
			fullscreen
			hide-overlay
			scrollable
			transition="fade">
			<transition>
				<QuotationV2
					v-if="dialogQuotation"
					:quotationId="itemSelected.id"
					@close="dialogQuotation = false"
					@cancelQuotation="dialogQuotation = false"/>
			</transition>
		</v-dialog>

		<v-dialog
			persistent
			v-model="dialogNewSubscription"
			fullscreen
			hide-overlay
			scrollable>
			<NovaAssinatura
				v-if="dialogNewSubscription"
				type="b2c"
				:close.sync="dialogNewSubscription"
				@assinatura-salva="savedSubscription"
				:orcamento="itemSelected"/>
		</v-dialog>
	</div>
</template>

<script>
import NovaAssinatura from '@Componentes/assinatura/nova-assinatura';
import moment from 'moment';
import { copyToClipboard } from '@Util/functions';
import { getFormattedPosology } from '@Util/medicamento';
import QuotationV2 from '@Componentes/quotation-v2';
import { QuotationPresenter } from '@Presenter/quotation-presenter';
import { getCurrentCompanyUnit, unidades } from '@Config/unidades';
import { ProductPresenter } from '@Presenter/product-presenter';
import { statesWithSymbol } from '@Consts/estados';
import { posologyTypes } from '@Consts/posologia';
import { TagPresenter } from '@Presenter/tag-presenter';
import { genders } from '@Consts/quotation-v2';
import { MedicamentoPresenter } from '@Presenter/medicamento-presenter';
import DialogDetalhesB2C from './dialog-detail-b2c.vue';

import mixin from './mixin-data-table';

export default {
	name: 'DataTableOrcamento',
	mixins: [mixin],
	components: {
		DialogDetalhesB2C,
		QuotationV2,
		NovaAssinatura
	},
	props: {
		sellers: {
			type: Array,
			default: () => []
		},
		searchQuotation: {
			type: String
		},
		filter: {
			type: Object,
			default: () => {}
		},
		quotationV2: {
			type: Boolean
		}
	},
	data: () => ({
		quotations: [],
		pagination: {},
		selected: [],
		tags: [],
		totalItems: 0,
		headers: [
			{ text: 'ID', value: 'id', sortable: true },
			{ text: 'Cliente', value: 'name' },
			{ text: 'Origem', value: 'origin' },
			{ text: 'Validade', value: 'createdAt', align: 'center' },
			{ text: 'Data de início', value: 'startDate', align: 'center' },
			{ text: 'Vendedor', value: 'sellerId', align: 'center' },
			{
				text: 'Valor',
				value: 'valor_total',
				align: 'center',
				sortable: false
			},
			{ text: 'Status', value: 'status', align: 'center' },
			{ text: '', sortable: false }
		],
		error: false,
		dialogDetail: false,
		dialogQuotation: false,
		dialogNewSubscription: false,
		generatingPdf: false,
		loadingQuotation: false,
		loadingQuotations: false,
		pdf: null
	}),
	watch: {
		pagination: {
			handler() {
				this.refresh();
			},
			deep: true
		},
		filter: {
			handler(oldVal, newVal) {
				if (oldVal !== newVal)
					this.refresh();
			},
			deep: true
		},
		searchQuotation: {
			handler(oldVal, newVal) {
				if (oldVal !== newVal)
					this.refresh();
			},
			deep: true
		},
		selected(arr) {
			this.$emit('selected-updated', arr);
		},
		dialogQuotation(value) {
			if (value === false)
				this.refresh();
		}
	},
	methods: {
		getQuotations() {
			this.loadingQuotations = true;
			const order = this.pagination.descending ? 'desc' : 'asc';
			const sortBy = this.pagination.sortBy;

			const companyUnit = getCurrentCompanyUnit();

			const pagination = {
				q: this.searchQuotation,
				itensPerPage: this.pagination.rowsPerPage,
				page: this.pagination.page,
				sort: `${sortBy},${order}`,
				status: this.filter.status,
				companyUnit: companyUnit.key
			};

			if (this.filter.seller) {
				const filteredSeller = this.sellers.find(s => s.id === this.filter.seller);
				pagination.seller = filteredSeller?.name || null;
			}

			QuotationPresenter.index(pagination)
				.then(response => {
					this.quotations = response.items;
					this.totalItems = response.total;
				})
				.catch(() => {
					this.error = true;
					this.$store.dispatch('SHOW_SNACKBAR', {
						message: 'Erro ao buscar orçamentos',
						color: 'error'
					});
				})
				.finally(() => {
					this.loadingQuotations = false;
				});
		},
		toggleAll() {
			this.selected = this.selected.length
				? []
				: [...this.$refs.DataTable.filteredItems];
		},
		refresh() {
			this.getQuotations();
		},
		crud(item = {}) {
			this.openDialog(item, 'dialogQuotation');
		},
		newSubscription(orcamento) {
			this.loadingQuotation = true;
			QuotationPresenter.show(orcamento.id)
				.then(async response => {
					const orcamentoSelected = await this.mapperToNewSubscription(response);
					this.itemSelected = orcamentoSelected;
					this.dialogNewSubscription = true;
				})
				.finally(() => {
					this.loadingQuotation = false;
				});
		},
		changeSort(column) {
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},

		copyMedicationRelationshipMessage(item) {
			QuotationPresenter.show(item.id).then(response => {
				const msg = `*Importante*: Para garantir a segurança do seu tratamento pedimos que você revise com atenção a dose, \
					quantidade de comprimidos e horários dos seus medicamentos, conforme abaixo. \
					Se estiver tudo certo, nos envie "Ok. De acordo".

					${response.items.map(
					product => `${product.name} - ${product.presentation} \
					${getFormattedPosology(product)}`
				).join('')}`;
				copyToClipboard(msg, 'Relação de Medicamentos escrita');

				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Relação de medicamento copiada',
					color: 'success'
				});
			});
		},
		savedSubscription() {
			this.refresh();
			this.dialogNewSubscription = false;
		},
		ruleDisableEdit(props) {
			return (
				this.loadingQuotation
				|| this.generatingPdf
				|| (props.item.status !== 'DRAFT' && props.item.status !== 'EXPIRED')
			);
		},
		async mapperToNewSubscription(response) {
			const filialId = unidades.find(unit => unit.key === response.companyUnit);

			const expiresAt = moment(response.expiresAt).format('YYYY-MM-DD');

			const birthdate = moment(response.customer.birthdate).format('DD/MM/YYYY');

			const startDate = moment(response.startDate).format('DD/MM/YYYY');

			const productIds = response.items.map(item => item.productId);

			const selectedProducts = await ProductPresenter.getProducts({ ids: productIds });

			const tagsSelected = response.tags;
			this.tags = await TagPresenter.index();

			const tagsToNewSubscription = tagsSelected && tagsSelected.length > 0 ? this.tags.filter(tag => tagsSelected.includes(tag.nome)) : [];

			const medicines = await Promise.all(response.items.map(item => MedicamentoPresenter.find(item.productId, filialId.id)));

			const products = response.items.map(item => {
				const prodt = selectedProducts.items.find(product => product.id === item.productId);
				const medicine = medicines.find(med => med.id === item.productId);

				const frequencySelected = () => {
					switch (item.posology.frequency) {
						case 1:
							return posologyTypes.allDays;
						case 2:
							return posologyTypes.dayYesDayNot;
						case 3:
							return posologyTypes.dayNotDayYes;
						case 4:
							return posologyTypes.weekly;
						case 5:
							return posologyTypes.monthly;
						default:
							return undefined;
					}
				};

				const isMedicine = prodt.drug;
				let preco_final = medicine.preco_recorrente;
				let discount = medicine.preco_recorrente - (item.priceSku - item.discount);

				if (discount < 0) {
					discount = 0;
					preco_final = item.priceSku;
				}

				const product = {
					principio_ativo: isMedicine ? prodt.drug.compositions.map(composition => composition.activeIngredient.name).join(' + ') : '',
					produto: prodt.name,
					id: prodt.id,
					medicamento_id: prodt.id,
					descricao_tipo_produto: isMedicine ? prodt.drug.type : '',
					laboratorio: prodt.brand,
					apresentacao: prodt.presentation,
					aproveitamento_receita: item.hasPrescriptionUtilization,
					divisivel: item.isDivisible,
					dosagem: isMedicine ? prodt.drug.compositions
						.map(dose => dose.concentration + dose.unitOfMeasurement.symbol)
						.join() : '',
					posologia: item.posology ? {
						dias_da_semana: item.posology.daysOfWeek || [],
						dias_do_mes: item.posology.daysOfMonth || [],
						periodo_de_uso: frequencySelected(),
						horarios: item.posology.schedules.map(schedule => ({
							horario: schedule.hour,
							quantidade_por_horario: schedule.pills
						}))
					} : {
						horarios: [], dias_do_mes: [], dias_da_semana: [], periodo_de_uso: 1
					},
					qtd_mensal: item.closedPackages,
					qtd_caixas: item.isDivisible ? null : item.closedPackages,
					qtd_embalagem: prodt.quantity,
					desconto: discount,
					preco_personalizado: preco_final
				};

				return product;
			});
			const genderSelected = genders.find(item => item.id === response.customer.gender);

			const address = response.address;
			const state = statesWithSymbol[address.state];
			const orcamento = {
				medicamentos: products,
				sem_box: response.type !== 'SUBSCRIPTION',
				endereco: {
					cep: address.zipCode,
					logradouro: address.street,
					numero: address.addressNumber,
					bairro: address.neighborhood,
					cidade: address.city,
					estado: state,
					complemento: address.complement
				},
				observacao: response.publicObservations,
				nome: response.customer.name,
				telefone: response.customer.phone,
				email: response.customer.email,
				cpf: response.customer.cpf,
				data_nascimento: birthdate,
				sexo: genderSelected.gender,
				id: response.id,
				filial_id: filialId.id,
				status: response.status,
				tags: tagsToNewSubscription,
				vendedor: {
					id: response.seller.id,
					nome: response.seller.name
				},
				valor_final: response.total,
				valor: response.total,
				data_validade: expiresAt,
				data_proxima_box: startDate,
				pedido_id: response.publicId,
				valor_servico: response.shippingPrice
			};

			return orcamento;
		},
		getStartDateText(startDate) {
			if (startDate)
				return moment(startDate).format('DD/MM/YYYY');
			return '-';
		}
	},
	computed: {
		no_data_text() {
			if (this.loadingQuotations)
				return 'Carregando...';
			if (this.error)
				return 'Erro ao carregar. Por favor, tente novamente';
			if (this.quotations.length === 0)
				return 'Utilize o campo acima para buscar os orçamentos';
			return 'Nenhum orçamento encontrado';
		}
	}
};
</script>

<style lang="scss" scoped>
	$icon-size: 16px;

	.bg-purple {
		background-color: #f8f1ff !important;
	}

	.botao-acao {
		color: #4e2f6f !important;
		border: 1px solid #4e2f6f !important;
		border-radius: 16px;

		&:hover {
			color: white !important;
			background-color: #4e2f6f !important;
		}
	}

	.preco {
		.space {
			width: $icon-size;
		}

		.v-icon {
			font-size: $icon-size;
		}
	}

	.revisao {
		font-weight: bold;
		text-align: center;
	}
</style>

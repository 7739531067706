import { BoxPresenter } from '@Presenter/box-presenter';
import { onlyNumber } from '@Util/functions';
import { getPropertyValue } from '@Util/ProductService';
import store from '@Store';
import { DEFAULT_DAYS_CYCLE, DEFAULT_QUANTITY_CYCLE } from '@Consts/cycles';
import { posologyTypes } from '@Consts/posologia';
import { posologyTypes as frequencyPosology } from '@Consts/quotation-v2';
import moment from 'moment';

function mapToFarmeosFrequency(frequency) {
	switch (frequency) {
		case frequencyPosology.allDays:
			return posologyTypes.allDays;
		case frequencyPosology.dayYesDayNot:
			return posologyTypes.dayYesDayNot;
		case frequencyPosology.dayNotDayYes:
			return posologyTypes.dayNotDayYes;
		case frequencyPosology.weekly:
			return posologyTypes.weekly;
		case frequencyPosology.monthly:
			return posologyTypes.monthly;

		default:
			break;
	}
}

export async function getSimulationCycles(quotation) {
	const [dia, mes, ano] = quotation.startDate.split('/');
	const startDate = new Date(ano, mes, dia).toISOString().substring(0, 10);

	const body = {
		inicio: startDate,
		dias_ciclo: DEFAULT_DAYS_CYCLE,
		quantidade_ciclos: DEFAULT_QUANTITY_CYCLE,
		servico_farmaceutico: Number(onlyNumber(quotation.shippingPrice)),
		produtos: quotation.items.map(item => {
			const isOutOfBox = item.drug ? Boolean(getPropertyValue(item.drug.properties, 'isOutOfBox')) : true;
			const isDivisible = item.drug ? Boolean(getPropertyValue(item.drug.properties, 'isDivisible')) : false;
			const closedPackages = item.quantityOfBoxes
				? item.quantityOfBoxes
				: Math.ceil(item.values.quantityByProductItem / item.quantity);
			return {
				id: item.id,
				fora_box: isOutOfBox,
				aproveitamento_receita: item.hasPrescriptionUtilization ? item.hasPrescriptionUtilization : false,
				divisivel: isDivisible,
				quantidade_caixas: closedPackages,
				preco: item.price,
				posologia: isOutOfBox && !isDivisible
					? undefined
					: {
						periodo_de_uso: mapToFarmeosFrequency(item.posology.frequency),
						dias_da_semana: item.posology.daysOfWeek ? item.posology.daysOfWeek : [],
						dias_do_mes: item.posology.daysOfMonth ? item.posology.daysOfMonth : [],
						horarios: item.posology.schedules,
						outro_periodo: {}
					}
			};
		})
	};

	const cycles = await BoxPresenter.simulate(body);

	if (!cycles)
		store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao simular ciclos', color: 'error' });

	return cycles;
}

export async function getSimulationCyclesByMedicine({
	id, hasPrescriptionUtilization, isDivisible, isOutOfBox, closedPackages, priceSku, posology, boxStartDate
}) {
	const [dia, mes, ano] = (boxStartDate || moment().format('DD/MM/YYYY')).split('/');
	const startDate = new Date(ano, mes, dia).toISOString().substring(0, 10);

	const body = {
		inicio: startDate,
		dias_ciclo: DEFAULT_DAYS_CYCLE,
		quantidade_ciclos: DEFAULT_QUANTITY_CYCLE,
		servico_farmaceutico: 0,
		produtos: [{
			id,
			fora_box: isOutOfBox,
			aproveitamento_receita: Boolean(hasPrescriptionUtilization),
			divisivel: isDivisible,
			quantidade_caixas: isDivisible ? undefined : closedPackages,
			preco: priceSku,
			posologia: isOutOfBox && !isDivisible ? undefined : posology
		}]
	};

	const cycles = await BoxPresenter.simulate(body);

	if (!cycles)
		store.dispatch('SHOW_SNACKBAR', { message: 'Erro ao simular ciclos', color: 'error' });

	return cycles;
}

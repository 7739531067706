<template>
	<v-container fluid grid-list-md class="pa-0">
		<form>
			<NewProductStepItemType :form="form.product" />

			<div v-if="form.product.type == itemType.medicine" >
				<FarmeDivider />
				<NewProductStepMedicine
					:form="form.medicine"
					:productForm="form.product"
					:dosageForms="dosageForms"
					:updateLoading="setMedicinesLoading"
					@addIngredient="addIngredient"
					@removeIngredient="removeIngredient"/>
			</div>

			<FarmeDivider />

			<Loading v-if="loading || medicineLoading" />
			<div v-else>

				<NewProductStepItemMeasurement
					:form="form.product"
					:unitsOfMeasurement="unitsOfMeasure"
					@getUnitsOfMeasure="getUnitsOfMeasure"/>

				<FarmeDivider />

				<h3 class="my-3">Fabricante</h3>
				<v-layout wrap>
					<v-flex xs12>
						<v-autocomplete
							v-model="form.product.manufacturer"
							background-color="white"
							:prepend-inner-icon="manufacturers.loading ? 'sync' : 'search'"
							:items="manufacturers.items"
							box
							label="Fabricante"
							clearable
							:loading="manufacturers.loading"
							:disabled="manufacturers.loading"
							item-text="name"
							return-object
							data-vv-name="Fabricante"
							v-validate="{ required: true }"
							append-outer-icon="add"
							@click:append-outer="createManufacturer = true"
							:error-messages="errors.collect('Fabricante')"/>
					</v-flex>
					<v-flex xs12>
						<v-autocomplete
							v-model="form.product.brand"
							background-color="white"
							:prepend-inner-icon="brands.loading ? 'sync' : 'search'"
							:items="brands.items"
							box
							label="Marca"
							clearable
							:loading="brands.loading"
							:disabled="brands.loading"
							item-text="name"
							item-value="id"
							return-object
							data-vv-name="Marca"
							v-validate="{ required: true }"
							append-outer-icon="add"
							@click:append-outer="createBrand = true"
							:error-messages="errors.collect('Marca')"/>
					</v-flex>
					<v-flex xs12>
						<v-autocomplete
							v-model="form.product.category"
							background-color="white"
							:prepend-inner-icon="categories.loading ? 'sync' : 'search'"
							:items="categories.items"
							box
							label="Categoria"
							clearable
							:loading="categories.loading"
							:disabled="categories.loading"
							item-text="name"
							return-object
							data-vv-name="Categoria"
							v-validate="{ required: true }"
							append-outer-icon="add"
							@click:append-outer="createCategory = true"
							:error-messages="errors.collect('Categoria')"/>
					</v-flex>
				</v-layout>

				<FarmeDivider />

				<NewProductStepItemGeneralData
					:form="form.product"
					:medicineForm="form.medicine"
					:productGroups="productGroups"
					:associatedConditions="associateConditions"
					:storageConditions="storageConditions"
					:umbrellaItems="umbrellaItems"
					@reloadAssociatedConditions="getAssociateConditions()"/>

				<FarmeDivider v-if="form.product.type == itemType.medicine" />

				<v-btn v-if="form.product.type == itemType.medicine" depressed class="primary full-width" @click="openPmcPfTable=true">
					Consultar/alterar tabela PMC
				</v-btn>
			</div>

		</form>

		<v-dialog
			persistent
			v-model="openPmcPfTable"
			fullscreen
			hide-overlay
			scrollable
			transition="fade">
			<PmcPfTable
				v-if="openPmcPfTable"
				@closeTable="openPmcPfTable = false"
				:form="form"
				:isNew="true"/>
		</v-dialog>

		<v-dialog
			v-model="createManufacturer"
			persistent
			scrollable
			transition="fade"
			max-width="840px">
			<transition>
				<DialogCreateManufacturer
					v-if="createManufacturer"
					@close="closeManufacturerDialog()"/>
			</transition>
		</v-dialog>

		<v-dialog
			v-model="createBrand"
			persistent
			scrollable
			transition="fade"
			max-width="840px">
			<transition>
				<DialogCreateBrand
					v-if="createBrand"
					@close="closeBrandDialog()"/>
			</transition>
		</v-dialog>

		<v-dialog
			v-model="createCategory"
			persistent
			scrollable
			transition="fade"
			max-width="840px">
			<transition>
				<DialogCreateProductCategory
					v-if="createCategory"
					@close="closeCategoryDialog()"/>
			</transition>
		</v-dialog>
	</v-container>
</template>

<script>
import { AssociatedConditionPresenter } from '@Presenter/associated-condition-presenter';
import { ProductGroupPresenter } from '@Presenter/product-group-presenter';
import { UnitOfMeasurePresenter } from '@Presenter/unit-of-measure-presenter';
import { ManufacturerPresenter } from '@Presenter/manufacturer-presenter';
import { BrandPresenter } from '@Presenter/brand-presenter';
import { ProductCategoryPresenter } from '@Presenter/product-category-presenter';
import { UmbrellaItemPresenter } from '@Presenter/umbrella-item-presenter';
import { DosageFormPresenter } from '@Presenter/dosage-form-presenter';
import { itemType } from '@Consts/product/item-type';
import FarmeDivider from '@Componentes/farme-divider.vue';
import { medicineTypes } from '@Consts/medicine-types';
import DialogCreateManufacturer from '../../../dialog-new-manufacturer.vue';
import DialogCreateBrand from '../../../dialog-new-brand.vue';
import DialogCreateProductCategory from '../../../dialog-new-category.vue';
import PmcPfTable from './pmc-pf-table.vue';
import NewProductStepItemType from './item-type.vue';
import NewProductStepItemMeasurement from './measurements.vue';
import NewProductStepItemGeneralData from './general-data.vue';
import NewProductStepMedicine from '../medicine/index.vue';
import Loading from '../../loading.vue';

export default {
	name: 'NewProductStepItem',
	$_veeValidate: { validator: 'new' },
	components: {
		DialogCreateManufacturer,
		DialogCreateBrand,
		DialogCreateProductCategory,
		NewProductStepItemType,
		NewProductStepItemMeasurement,
		NewProductStepItemGeneralData,
		FarmeDivider,
		NewProductStepMedicine,
		Loading,
		PmcPfTable
	},
	props: {
		form: {
			required: true,
			type: Object
		},
		defaultMedicineForm: {
			required: false,
			type: Object
		}
	},
	async created() {
		try {
			await Promise.all([
				this.getProductGroups(),
				this.getUnitsOfMeasure(),
				this.getManufacturers(),
				this.getBrands(),
				this.getDosageForms(),
				this.getCategories()
			]);

			this.setFormGroup();
			this.loading = false;
		} catch (error) {
			this.$store.dispatch('SHOW_SNACKBAR', {
				message: 'Erro ao carregar dados do formulário',
				color: 'error'
			});
		}
	},
	data() {
		return {
			openPmcPfTable: false,
			medicineLoading: false,
			loading: true,
			medicineTypes,
			manufacturers: {
				loading: false,
				items: []
			},
			brands: {
				loading: false,
				items: []
			},
			categories: {
				loading: false,
				items: []
			},
			associateConditions: [],
			productGroups: [],
			unitsOfMeasure: [],
			umbrellaItems: [],
			dosageForms: [],
			createManufacturer: false,
			createBrand: false,
			createCategory: false,
			openCreateUnitOfMeasurementDialog: false,
			itemType
		};
	},
	watch: {
		'form.product.brand': function () {
			this.updatePresentation();
			this.updateDescription();
		},
		'form.medicine.type': function () {
			this.updateName();
		},
		'form.medicine.dosageFormId': function () {
			this.updatePresentation();
			this.updateDescription();
		},
		'form.medicine.compositions': {
			deep: true,
			handler() {
				this.updateName();
				this.updatePresentation();
				this.updateDescription();
			}
		},
		'form.medicine.properties.aggregateItem': function () {
			this.updatePresentation();
			this.updateDescription();
		},
		'form.product.quantity': function () {
			this.updatePresentation();
			this.updateDescription();
		},
		'form.product.unitOfMeasurementSymbol': function () {
			this.updatePresentation();
			this.updateDescription();
		},
		'form.product.quantityForSale': function () {
			this.updatePresentation();
			this.updateDescription();
		},
		'form.product.unitOfMeasurementForSaleSymbol': function () {
			this.updatePresentation();
			this.updateDescription();
		},
		'form.product.type': function () {
			this.setFormGroup();
		}
	},
	computed: {
		storageConditions() {
			return [
				'Temperatura ambiente (não requer refrigeração)',
				'Refrigerado',
				'Congelado (freezer)',
				'Refrigerar após aberto',
				'Congelar após aberto'
			];
		}
	},
	methods: {
		setMedicinesLoading(loading) {
			this.medicineLoading = loading;
		},
		setFormGroup() {
			if (!this.form.product.group?.id && this.form.product.type === itemType.medicine)
				this.form.product.group = this.productGroups.find(p => p.name.toLowerCase() === 'medicamentos');
		},
		addIngredient() {
			this.$emit('addIngredient');
		},
		removeIngredient(index) {
			this.$emit('removeIngredient', index);
		},
		/**
		 * Substitui pontos que estão entre números, mas não se seguido de 3 dígitos (supõe-se que isso seria separador de milhar)
		 * Ex: 1.000,00 -> 1000,00
		 * Ex: 1.000.000,00 -> 1000000,00
		 * Ex: 2.50 -> 2,50
		 * @param {string} str
		 * @returns {string}
		 */
		replaceDecimalPoints(str) {
			if (!str)
				return '';
			return `${str}`.replace(/(\d)\.(\d{1,2})(?!\d)/g, '$1,$2');
		},
		getDataToUpdatePresentationAndDescription() {
			const unitOfMeasureForSaleDescription = this.unitsOfMeasure.find(um => um.symbol === this.form.product.unitOfMeasurementForSaleSymbol);
			const dosageForm = this.dosageForms.find(df => df.id === this.form.medicine.dosageFormId);

			const isDosageFormSolid = ['sólido', 'sólida'].includes(dosageForm?.type?.toLowerCase());

			let unitQuantity;
			if (isDosageFormSolid)
				unitQuantity = `${this.form.product.quantity || ''} ${dosageForm?.name || this.form.product.unitOfMeasurementSymbol || ''}`;
			else
				unitQuantity = `${this.form.product.quantity || ''}${this.form.product.unitOfMeasurementSymbol || ''} de ${dosageForm?.name || this.form.product.unitOfMeasurementSymbol || ''}`;

			const saleQuantity = `${this.form.product.quantityForSale || ''} ${unitOfMeasureForSaleDescription?.name || this.form.product.unitOfMeasurementForSaleSymbol || ''}`;
			const quantity = `${saleQuantity} com ${unitQuantity}`;
			const aggregatedItem = this.form.medicine.properties.aggregateItem;
			const brand = this.form.product.brand?.name || '';
			return {
				quantity,
				aggregatedItem,
				brand
			};
		},
		updateName(compositions) {
			if (this.form.medicine.type === medicineTypes.GENERICO)
				this.form.product.name = ((compositions || this.form.medicine.compositions)?.map(c => c.activeIngredient?.nameReduced || c.activeIngredient?.name).join(' + ')).toUpperCase();
		},
		updatePresentation() {
			const { quantity, aggregatedItem } = this.getDataToUpdatePresentationAndDescription();
			const concentration = this.form.medicine.compositions?.map(c => `${this.replaceDecimalPoints(c.concentration) || ''}${c.unitOfMeasurementSymbol || ''}`).join(' + ');
			this.form.product.presentation = `${concentration ? `${concentration} -` : ''} ${quantity || ''} ${aggregatedItem ? `+ ${ aggregatedItem}` : ''}`;
		},
		updateDescription() {
			const { quantity, aggregatedItem, brand } = this.getDataToUpdatePresentationAndDescription();
			const concentration = this.form.product.type === itemType.medicine ? this.form.medicine.compositions?.map(c => `${c.activeIngredient?.nameReduced || c.activeIngredient?.name || ''} ${this.replaceDecimalPoints(c.concentration) || ''}${c.unitOfMeasurementSymbol || ''}`).join(' + ') : '';
			this.form.product.description = `${concentration ? `${concentration} -` : ''} ${quantity || ''} ${aggregatedItem ? `+ ${ aggregatedItem}` : ''} - ${brand}`;
		},
		async getAssociateConditions() {
			const associatedConditionsResponse = await AssociatedConditionPresenter.findAll();
			this.associateConditions = associatedConditionsResponse.items;
		},
		async getProductGroups() {
			const productGroupsReseponse = await ProductGroupPresenter.findAll();
			this.productGroups = productGroupsReseponse.items;
		},
		async getUnitsOfMeasure() {
			const unitsOfMeasureResponse = await UnitOfMeasurePresenter.findAll();
			this.unitsOfMeasure = unitsOfMeasureResponse.items;
		},
		async getManufacturers() {
			this.manufacturers.loading = true;
			const manufacturersResponse = await ManufacturerPresenter.findAll();
			this.manufacturers.items = manufacturersResponse.items;
			this.manufacturers.loading = false;
		},
		async getBrands() {
			this.brands.loading = true;
			const response = await BrandPresenter.findAll();
			this.brands.items = response.items;
			this.brands.loading = false;
		},
		async getDosageForms() {
			const dosageFormsResponse = await DosageFormPresenter.findAll();
			this.dosageForms = dosageFormsResponse.items;
		},
		async getCategories() {
			this.categories.loading = true;
			const response = await ProductCategoryPresenter.findAll();
			this.categories.items = response.items;
			this.categories.loading = false;
		},
		async getUmbrellaItems() {
			const umbrellaItemsResponse = await UmbrellaItemPresenter.findAll();
			this.umbrellaItems = umbrellaItemsResponse.items;
		},
		async closeManufacturerDialog() {
			await this.getManufacturers();
			this.createManufacturer = false;
		},
		async closeBrandDialog() {
			await this.getBrands();
			this.createBrand = false;
		},
		async closeCategoryDialog() {
			await this.getCategories();
			this.createCategory = false;
		},
		async validate() {
			return Boolean(await this.$validator.validateAll());
		}
	}
};
</script>

<style lang="scss" scoped>
</style>

<template>
	<Dialog
		titulo="Cadastrar prescritor"
		@acao="save()"
		btn_acao="Confirmar"
		@close="$emit('close')"
		background="rgba(255, 234, 204, 1)"
		backgroundFooter="rgba(255, 234, 204, 1)">
		<v-container grid-list-md text-xs-center>
			<v-layout row wrap>
				<v-flex xs12>
					<v-text-field
						box
						label="Nome *"
						v-model="form.name"
						data-vv-name="Nome"
						v-validate="{ required: true }"
						:error-messages="errors.collect('Nome')"/>
				</v-flex>

				<v-flex xs12 md4>
					<v-select
						:items="typesOfRegister"
						box
						label="Tipo de registro *"
						v-model="form.typeOfRegister"
						item-value="id"
						item-text="value"
						data-vv-name="Tipo de registro"
						v-validate="{ required: true }"
						:error-messages="errors.collect('Tipo de registro')"></v-select>
				</v-flex>

				<v-flex xs12 md3>
					<v-select
						:items="states"
						box
						label="UF"
						v-model="form.uf"
						item-value="id"
						item-text="value"
						data-vv-name="UF"
						:disabled="stateIsDisabled"
						v-validate="{ required: stateIsRequired }"
						:error-messages="errors.collect('UF')"></v-select>
				</v-flex>

				<v-flex xs12 md5>
					<v-text-field
						box
						label="Número de registro *"
						v-model="form.number"
						data-vv-name="Número de registro"
						v-validate="{ required: true }"
						type="number"
						:error-messages="errors.collect('Número de registro')"/>
				</v-flex>
			</v-layout>
		</v-container>
		<FarmeDivider />
	</Dialog>
</template>

<script>
import { PrescriberPresenter } from '@Presenter/prescriber-presenter';
import FarmeDivider from '@Views/componentes/farme-divider.vue';
import { statesWithSymbol } from '@Consts/estados';
import { typesOfRegister as _typesOfRegister } from '@Consts/prescriber-register-types';

export default {
	name: 'DialogCreatePrescriber',
	$_veeValidate: { validator: 'new' },
	components: {
		FarmeDivider
	},
	data() {
		return {
			form: {
				typeOfRegister: null,
				uf: null,
				number: null,
				name: null
			}
		};
	},
	computed: {
		stateIsRequired() {
			return this.form.typeOfRegister && this.form.typeOfRegister !== _typesOfRegister.RMS;
		},
		stateIsDisabled() {
			return !this.form.typeOfRegister || this.form.typeOfRegister === _typesOfRegister.RMS;
		},
		states() {
			return Object.keys(statesWithSymbol).map(item => ({
				id: item,
				value: statesWithSymbol[item]
			}));
		},
		typesOfRegister() {
			return Object.keys(_typesOfRegister)
				.map(typeOfRegister => ({
					id: typeOfRegister,
					value: typeOfRegister
				}));
		}
	},
	methods: {
		async save() {
			const formIsValidated = await this.$validator.validateAll();

			if (!formIsValidated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Formulário inválido',
					color: 'error'
				});
				return;
			}

			try {
				await PrescriberPresenter.create({
					name: this.form.name,
					uf: this.stateIsRequired ? this.form.uf : null,
					number: this.form.number,
					typeOfRegister: this.form.typeOfRegister
				});

				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Presecritor cadastrado com sucesso',
					color: 'success'
				});
				this.$emit('prescriberCreated');
			} catch (error) {
				const msg = error.message || 'Erro ao criar presecritor';
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: msg,
					color: 'error'
				});
				throw new Error(error);
			} finally {
				this.$emit('close');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>

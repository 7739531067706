<template>
	<v-card style="padding: 32px;">
		<v-card-title>
			<v-layout row wrap align-content-space-between items-center>
				<v-flex style="display: flex; flex-direction: row; align-items: end; justify-content: start;">
					<div class="pr-2">
						<p class="mb-0" style="font-size: smaller;">
							ID Fatura
						</p>
						<h2 style="font-family: Poppins, sans-serif !important;">{{ invoiceToManage.id }}</h2>
					</div>
					<v-btn class="button-action secondary px-2 pt-0" outline @click="copyLink()">
						<span class="px-2 black--text font-semibold">Copiar link</span>
					</v-btn>
					<v-btn class="button-action secondary" outline @click="accessLink()">
						<span class="px-2 black--text font-semibold">Acessar link</span>
					</v-btn>

					<ManageInvoice :item="invoiceToManage" @load-invoice="$emit('load-invoice')">
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" @click.stop style="height: 30px" class="botao-acao" flat>
								<span class="px-2">Outras ações</span><v-icon>fas fa-ellipsis-h</v-icon>
							</v-btn>
						</template>
					</ManageInvoice>
				</v-flex>

				<v-chip
					style="min-width: 100px"
					class="justify-center mx-0"
					:style="statusStyleSelected">
					{{ statusLabelSelected }}
				</v-chip>
			</v-layout>
		</v-card-title>
		<v-divider class="my-2 divider"></v-divider>
		<v-card-text >
			<v-layout wrap>
				<v-flex justify-between>
					<p class="mb-0">
						Tipo
					</p>
					<h4  class="font-semibold">{{ invoiceToManage.reference_type }} {{ getReferenceId }}</h4>
				</v-flex>
				<v-flex d-flex>
					<div>
						<div class="mb-0">Criação</div>
						<span class="font-bold">{{ invoiceToManage.created_at | formatDate('DD/MM/YYYY')  }}</span>

					</div>
					<div>
						<div class="mb-0">Expiração</div>
						<span  class="font-bold">{{ invoiceToManage.expiration_date | formatDate('DD/MM/YYYY') }}</span>
					</div>
					<div>
						<div class="mb-0">Vencimento</div>
						<span  class="font-bold">{{ invoiceToManage.due_date | formatDate('DD/MM/YYYY') }}</span>
					</div>
					<div>
						<div class="mb-0">Valor total</div>
						<span  class="font-bold">{{ invoiceToManage.total_amount | dinheiro }}</span>
					</div>
				</v-flex>
			</v-layout>
		</v-card-text>
	</v-card>

</template>

<script>
import { statusClass, statusLabel } from '@Consts/invoices';
import { copyToClipboard } from '@Util/functions';
import { getUrlJornadaDigital } from '@Config/url-jornada-digital';
import ManageInvoice from '../manage-invoice.vue';

export default {
	name: 'InvoiceResume',
	props: {
		invoice: {
			type: Object,
			required: true
		}
	},
	components: {
		ManageInvoice
	},
	computed: {
		statusLabelSelected() {
			if (!this.invoice.status)
				return '';
			return statusLabel[this.invoice.status]?.toUpperCase();
		},
		statusStyleSelected() {
			if (!this.invoice.status)
				return '';
			return statusClass[this.invoice.status];
		},
		invoiceLink() {
			return `https://${getUrlJornadaDigital()}/faturas/${this.invoice.hash_id}`;
		},
		invoiceToManage() {
			return {
				...this.invoice,
				address: this.invoice.payer.address
			};
		},
		getReferenceId() {
			if (!this.invoice.reference_id)
				return '';
			return `/ ${this.invoice.reference_id}`;
		}
	},
	methods: {
		copyLink() {
			copyToClipboard(this.invoiceLink);
			this.$store.dispatch('SHOW_SNACKBAR', { message: 'Link da fatura copiado!', color: 'success' });
		},
		accessLink() {
			window.open(this.invoiceLink, '_blank');
		}
	}
};
</script>

<style scoped>

.botao-acao {
	color: #4e2f6f !important;
	border: 1px solid #4e2f6f !important;
	border-radius: 16px;

	&:hover {
		color: white !important;
		background-color: #4e2f6f !important;
	}
}

.button-action{
	border-radius: 16px;
	height: 30px
}

.font-semibold {
	font-weight: 600;
}

.font-bold {
	font-weight: bold;
}
</style>

<template>
	<header>
		<div class="items-center space-between">
			<h1 class="text--text mr-3" style="opacity: 0.8; font-size: 30px">
				Acompanhamento <br> de pedidos
			</h1>
			<v-tabs
				color="transparent"
				v-model="filter.view"
				light
				slider-color="primary">
				<v-tab
					v-for="(item, index) in totalResume"
					:key="index"
					ripple>
					{{ item.name }} <v-chip small color="primary" class="ml-3">{{ item.total }}</v-chip>
				</v-tab>
			</v-tabs>
		</div>

		<div class="items-center space-between">
			<v-text-field
				v-model="filter.search"
				label="Busca"
				:placeholder="search_placeholder"
				class="mr-4"
				hide-details
				append-icon="search" />

			<v-menu
				style="height: 100%;"
				:close-on-content-click="false">
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on"
						color="secondary"
						class="px-4 py-4 ma-0 items-center space-between"
						outline
						style="border-radius: 10px;">
						<span>Filtros</span>
						<v-icon small class="ml-5">keyboard_arrow_down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-tile>
						<v-list-tile-title>Filtros</v-list-tile-title>
					</v-list-tile>
					<v-divider v-if="viewTypeIsBox"></v-divider>

					<v-list-tile class="py-3" v-if="viewTypeIsBox">
						<TextDateField v-model="filter.startDate"
							label="Data de início"
							placeholder="dd/mm/yyyy"
							:validate="{ data: true }"
							:flat="true" />
					</v-list-tile>

					<v-divider v-if="viewTypeIsBox"></v-divider>

					<v-list-tile class="py-3" v-if="viewTypeIsBox">
						<TextDateField v-model="filter.pickingDate"
							label="Data de picking"
							placeholder="dd/mm/yyyy"
							full-width
							:validate="{ data: true }"
							:flat="true" />
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-3">
						<v-select
							v-model="filter.status"
							:items="status_items"
							item-text="label"
							item-value="value"
							label="Status" />
					</v-list-tile>

					<v-divider></v-divider>

					<v-list-tile class="py-3">
						<v-autocomplete
							v-model="filter.restHomeId"
							full-width
							background-color="white"
							:loading="restHome.loading"
							:disabled="restHome.loading"
							:items="restHome.items"
							label="Casas de repouso"
							no-data-text="Nenhuma casa de repouso"
							box
							hide-details
							clearable
							item-value="id"
							item-text="nome" />
					</v-list-tile>

					<v-divider v-if="viewTypeIsBox"></v-divider>

					<v-list-tile class="py-2" v-if="viewTypeIsBox">
						<v-combobox
							v-model="filter.ignoreTagsIds"
							background-color="white"
							full-width
							:loading="globalTags.loading"
							:disabled="globalTags.loading"
							:items="globalTags.items"
							label="Não listar tags"
							no-data-text="Nenhuma tag encontrada"
							hide-details
							items-id="id"
							item-text="text"
							:return-object="false"
							clearable
							multiple
							small-chips>
							<template v-slot:selection="data">
								<v-chip
									close
									@input="removeTag(data.item)"
									class="pointer">
									<strong>{{ data.item }}</strong>
								</v-chip>
							</template>
						</v-combobox>
					</v-list-tile>

				</v-list>
			</v-menu>

			<v-btn
				:loading="loading"
				:disabled="selected.length === 0 || loading"
				@click="$emit('retryProcessingIds', selected.map(({id}) => id))"
				class="px-4"
				large
				color="primary"
				style="border-radius: 10px;">
				Efetuar processamento
			</v-btn>
		</div>
	</header>
</template>

<script>
import { getOrderProcessDescription, getRealStatusOrder } from '@Consts/status-order';
import { getStatusBox } from '@Consts/status-box';
import { CasaRepousoPresenter } from '@Presenter/casa-repouso-presenter';
import { TagPresenter } from '@Presenter/tag-presenter';

const VIEWS = {
	0: 'BOXES',
	1: 'ORDERS'
};
export default {
	name: 'ListOrdersHeader',
	props: {
		views: {
			required: false,
			type: Array,
			default: () => ([
				{
					name: 'BOXES',
					value: 0
				},
				{
					name: 'ORDERS',
					value: 0
				}
			])
		},
		totalBoxes: {
			required: false,
			type: Number,
			default: 0
		},
		totalOrders: {
			required: false,
			type: Number,
			default: 0
		},
		selected: {
			required: false,
			type: Array,
			default: () => []
		},
		viewTypeIsBox: {
			required: false,
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			loading: false,
			filter: {
				view: null,
				search: null,
				startDate: null,
				pickingDate: null,
				status: null,
				ignoreTagsIds: []
			},
			restHomeId: null,
			restHome: {
				items: [],
				loading: false
			},
			globalTags: {
				items: [],
				loading: false
			},
			getStatusBox,
			getOrderProcessDescription,
			getRealStatusOrder
		};
	},
	async created() {
		await Promise.all([
			this.getRestHomes(),
			this.getGlobalTags()
		]);
	},
	computed: {
		search_placeholder() {
			if (this.viewTypeIsBox)
				return 'Id ou nome';
			return 'Id da assinatura ou nome';
		},
		totalResume() {
			return [
				{
					name: 'BOXES',
					total: this.totalBoxes
				},
				{
					name: 'PONTUAIS',
					total: this.totalOrders
				}
			];
		},
		status_items() {
			if (this.viewTypeIsBox) {
				return [
					{ label: 'Todos', value: null },
					{ label: 'Picking', value: 'PICKING' },
					{ label: 'Processando picking', value: 'PROCESS_PICKING' }
				];
			}
			return [
				{ label: 'Todos', value: null },
				{ label: 'Faturamento', value: 'BILLING' },
				{ label: 'Picking', value: 'PICKING' }
			];
		}
	},
	methods: {
		async getRestHomes() {
			this.restHome.loading = true;
			try {
				const restHomeResponse = await CasaRepousoPresenter.index();
				this.restHome.items = restHomeResponse;
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{ message: 'Erro ao carregar casas de repouso.', color: 'error' }
				);
			} finally {
				this.restHome.loading = false;
			}
		},
		handleFilter() {
			this.$emit('filter', {
				filter: {
					...this.filter,
					startDate: this.$functions.FormatDateToRequest(this.filter.startDate),
					pickingDate: this.$functions.FormatDateToRequest(this.filter.pickingDate),
					view: VIEWS[this.filter.view],
					ignoreTagsIds: this.globalTags.items.filter(item => this.filter.ignoreTagsIds.includes(item.text)).map(i => i.id)
				}
			});
		},
		async getGlobalTags() {
			this.globalTags.loading = true;
			try {
				const tags = await TagPresenter.index();
				const globalTags = (tags || []).filter(tag => tag.nome.includes('GLOBAL_'));
				this.globalTags.items = globalTags.map(t => ({ id: t.id, text: t.nome }));
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{ message: 'Erro ao carregar tags', color: 'error' }
				);
			} finally {
				this.globalTags.loading = false;
			}
		}
	},
	watch: {
		filter: {
			deep: true,
			handler() {
				this.handleFilter();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
header {
	border-bottom-width: 5px;
	border-bottom-color: #ffd9a0 !important;
	border-bottom-style: solid;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>

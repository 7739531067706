<template>
	<section class="">
		<ListChargesHeader
			:availableViews="views"
			:total="total"
			:totalCharged="totalCharged"
			@filter="headerFilterParams => updateFilters(headerFilterParams)"/>
		<ListChargesTable
			:availableViews="views"
			:currentView="currentView"
			:loading="isLoading"
			:items="items"
			:total="total"
			@filter="tableFilterParams => updateFilters(tableFilterParams)"
			@updateData="() => getCharges()"/>

		<LoaderDialog v-if="isLoading" />
	</section>
</template>

<script>
import { ChargesPresenter } from '@Presenter/charges-presenter';
import { isEqual, debounce } from 'lodash';
import LoaderDialog from '@Componentes/loader-dialog';
import ListChargesHeader from './header';
import ListChargesTable from './table';

const VIEWS = {
	NOT_CHARGED: 'NÃO COBRADOS',
	CHARGED: 'COBRADOS'
};
export default {
	name: 'ListCharges',
	components: {
		ListChargesHeader, ListChargesTable, LoaderDialog
	},
	data() {
		return {
			currentView: VIEWS.NOT_CHARGED,
			views: VIEWS,
			isLoading: false,
			filterParams: {
				pagination: {},
				sorting: {},
				filter: {}
			},
			items: [],
			total: 0,
			totalCharged: 0
		};
	},
	methods: {
		updateFilters(filters) {
			this.currentView = filters.filter?.view ? VIEWS[filters.filter?.view] : VIEWS.NOT_CHARGED;
			this.filterParams = {
				...this.filterParams,
				...filters
			};
		},
		getCharges: debounce(async function () {
			this.isLoading = true;
			try {
				const charges = await ChargesPresenter.list({
					filter: this.filterParams.filter,
					pagination: this.filterParams.pagination,
					sorting: this.filterParams.sorting
				});
				this.items = charges.items;
				this.total = charges.total;
				this.totalCharged = charges.totalCharged;
			} catch (e) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{ message: 'Erro ao carregar dados.', color: 'error' }
				);
			} finally {
				this.isLoading = false;
			}
		}, 500)
	},
	watch: {
		filterParams: {
			deep: true,
			async handler(newVal, oldVal) {
				if (isEqual(newVal, oldVal))
					return;
				await this.getCharges();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>

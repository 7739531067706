<template>
	<div class="px-md-5">
		<HeaderProcesso
			titulo="Cobrança"
			subtitulo="Faça a cobrança dos medicamentos que estão em cada box"
			subtitulo2="Aqui estão listadas as boxes que ainda não foram cobradas."
			busca_label="Busca"
			@busca="val => busca = val"
			:show_casa_filter="true"
			@casas-repouso-updated="onCasaRepousoIds"/>

		<!-- Conteúdo -->
		<DataTableProcessoPagination
			:loading="carregando"
			:search="busca"
			:buttons="buttons"
			:fetch_data="getDataPagination"
			ref="dataTablePagination"
			@selected-updated="arr => selecionados = arr"/>

		<!-- Iugu dialog -->
		<v-dialog
			persistent
			v-model="dialog_iugu"
			scrollable
			transition="fade"
			max-width="920px">
			<transition>
				<DialogIugu
					v-if="dialog_iugu"
					:box_id="item_selecionado.box_id"
					:assinatura_id="item_selecionado.assinatura_id"
					:isFirstBox="item_selecionado.primeira_box"
					:show_modalidade="true"
					@close="onCloseIugu"/>
			</transition>
		</v-dialog>

		<!-- NFS-e dialog -->
		<v-dialog
			persistent
			v-model="dialog_nfs"
			scrollable
			transition="fade"
			max-width="920px">
			<transition>
				<DialogNfs
					v-if="dialog_nfs"
					:box_id="item_selecionado.box_id"
					:assinatura_id="item_selecionado.assinatura_id"
					:close.sync="dialog_nfs"/>
			</transition>
		</v-dialog>

		<!-- Enviar NF dialog -->
		<v-dialog
			persistent
			v-model="dialog_email"
			scrollable
			transition="fade"
			max-width="920px">
			<transition>
				<DialogEnviarNf
					v-if="dialog_email"
					:assinatura_id="item_selecionado.assinatura_id"
					:close.sync="dialog_email"/>
			</transition>
		</v-dialog>

		<!-- Marcar box como paga -->
		<v-dialog
			persistent
			scrollable
			v-model="dialog_confirmar_pagamento"
			transition="fade"
			max-width="500px">
			<transition>
				<DialogConfirmacao
					v-if="dialog_confirmar_pagamento"
					@close="dialog_confirmar_pagamento = false"
					:request="setStatus('PAID')"
					:opcoes="['Confirmo que validei o pagamento realizado pelo cliente da fatura gerada']"/>
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import HeaderProcesso from '@Componentes/header-processo';
import DialogNfs from '@Componentes/dialog-nfs';
import DialogIugu from '@Componentes/dialog-iugu';
import DialogEnviarNf from '@Componentes/dialog-enviar-nf';
import DataTableProcessoPagination from '@Componentes/data-table-processo-pagination';
import { BoxPresenter } from '@Presenter/box-presenter';
import { StatusBoxPresenter } from '@Presenter/status-box-presenter';

export default {
	name: 'Cobranca',
	components: {
		HeaderProcesso,
		DialogNfs,
		DialogIugu,
		DialogEnviarNf,
		DataTableProcessoPagination
	},
	data: () => ({
		carregando: false,
		busca: '',
		dialog_nfs: false,
		dialog_iugu: false,
		dialog_email: false,
		dialog_confirmar_pagamento: false,
		casaRepousoIds: []
	}),
	created() {
		this.buttons = [
			{
				label: 'NFS-e',
				icon: 'fas fa-file-alt',
				onClick: item => this.abrirDialog(item, 'dialog_nfs')
			},
			{
				label: 'Email com NF',
				icon: 'fas fa-envelope',
				onClick: item => this.abrirDialog(item, 'dialog_email')
			},
			{
				label: 'Cobrar',
				icon: 'fas fa-dollar-sign',
				onClick: item => this.abrirDialog(item, 'dialog_iugu')
			},
			{
				label: 'Marcar como pago',
				icon: 'fas fa-check',
				color: 'success',
				condition: item => (item.status_box || []).find(s => s.status === 'CHARGED'),
				onClick: item => this.abrirDialog(item, 'dialog_confirmar_pagamento')
			}
		];
	},
	methods: {
		async getDataFromAPI(pagination, search, casaRepousoIds) {
			this.carregando = true;

			const params = { ...pagination, search, casa_repouso_ids: casaRepousoIds };

			const resp = {};
			await BoxPresenter.fetchCobrancas({ params })
				.then(response => {
					resp.items = response.items;
					resp.total = response.total;
				})
				.finally(() => {
					this.carregando = false;
				});
			return resp;
		},

		async getDataPagination() {
			const pagination = { ...this.$refs.dataTablePagination.pagination };
			pagination.order = pagination.descending ? 'desc' : 'asc';

			const resp = await this.getDataFromAPI(pagination, this.busca, this.casaRepousoIds);
			const items = resp.items;
			const total = resp.total;
			this.$refs.dataTablePagination.updatePage(items, total);
		},

		abrirDialog(item, dialog_name) {
			this.item_selecionado = item;
			this[dialog_name] = true;
		},

		onCloseIugu() {
			this.dialog_iugu = false;
			this.getDataPagination();
		},

		onCasaRepousoIds(casasRepouso) {
			this.casaRepousoIds = casasRepouso;
			this.getDataPagination();
		},

		setStatus(status) {
			return () => new Promise((resolve, reject) => {
				StatusBoxPresenter.store({
					box_id: this.item_selecionado.box_id,
					status
				})
					.then(() => {
						this.afterSave(resolve);
					})
					.catch(() => {
						reject();
					});
			});
		},

		afterSave(resolve) {
			this.getDataPagination(this.$refs.dataTablePagination.pagination);
			this.$store.dispatch('SHOW_SNACKBAR', {
				message: 'Status da box atualizado com sucesso!'
			});
			if (resolve)
				resolve();
		}
	}
};
</script>

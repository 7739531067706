<template>
	<div style="display: flex;">
		<v-tooltip
			v-if="
				visao_colaborador &&
					mpp &&
					mppObservacao
			"
			top>
			<v-icon style="cursor: pointer;" slot="activator" :color="corIcone('warning')">fas fa-bell</v-icon>
			<span>{{ mppObservacao }}</span>
		</v-tooltip>
		<v-tooltip v-if="produtoReferencia" top>
			<v-icon style="cursor: pointer;" slot="activator" :color="corIcone('success')">info</v-icon>
			<span>
				O {{ produto }} é um produto
				{{ descricaoTipoProduto }} ao
				{{ produtoReferencia }}
			</span>
		</v-tooltip>
		<v-tooltip v-if="portaria_344_98" top>
			<v-icon style="cursor: pointer;" slot="activator" :color="corIcone('error')">warning</v-icon>
			<span>Este medicamento necessita da retenção da prescrição
				médica.</span>
		</v-tooltip>
		<v-tooltip top v-if="foraBox">
			<template v-slot:activator="{ on }">
				<img style="cursor: pointer;" src="@Assets/icons/fora_box_dark.svg" alt="ForaBox" v-on="on" width="18"
					class="pl-1">
			</template>
			<span>ForaBox</span>
		</v-tooltip>
		<v-tooltip top v-if="refrigerado">
			<template v-slot:activator="{ on }">
				<img style="cursor: pointer;" src="@Assets/icons/refrigerado_dark.svg" alt="Refrigerado" v-on="on" width="18"
					class="pl-1">
			</template>
			<span>Refrigerado</span>
		</v-tooltip>
		<v-tooltip v-if="pbm" top>
			<v-chip
				class="justify-center"
				:color="corIcone('blue')"
				style="max-width: 20px; max-height: 20px; margin-top: -1.5px; cursor: pointer;"
				slot="activator"
				dark
				small><strong>%</strong></v-chip>
			<span>{{ beneficioPbm || "PBM" }}</span>
		</v-tooltip>
	</div>
</template>

<script>

export default {
	name: 'MedicamentoInfoIcons',
	props: {
		medicamentoPausado: {
			type: Number,
			required: false
		},
		produtoReferencia: {
			type: String,
			required: false
		},
		mppObservacao: {
			type: String,
			required: false
		},
		mpp: {
			type: String,
			required: false
		},
		produto: {
			type: String,
			required: true
		},
		descricaoTipoProduto: {
			type: String,
			required: true
		},
		portaria_344_98: {
			type: String,
			required: false
		},
		foraBox: {
			type: Number,
			required: false
		},
		refrigerado: {
			type: Number,
			required: false
		},
		pbm: {
			type: Number,
			required: false
		},
		beneficioPbm: {
			type: String,
			required: false,
			default: ''
		}
	},
	data: () => ({
		actionsDisabled: false
	}),
	created() {
		this.corIcone();
		this.corTextoMedicamentoPausado();
	},
	computed: {
		visao_colaborador() {
			return this.$store.getters.visao_colaborador;
		},
		eh_clinica() {
			return this.$route.name.includes('Clinica');
		},
		corTituloMedicamento() {
			if (this.medicamentoPausado)
				return 'medicamento-pausado';
			return 'grey--text text--darken-2';
		}
	},
	methods: {
		corIcone(cor) {
			if (this.medicamentoPausado)
				return '#A8A8A8';
			return cor;
		},
		corTextoMedicamentoPausado(cor) {
			if (this.medicamentoPausado)
				return 'grey--text text--lighten-1';
			return cor;
		}
	}
};
</script>

<style scoped lang="scss">
	.medicamento-pausado {
		text-decoration-line: line-through;
		color: #a8a8a8 !important;
	}
</style>

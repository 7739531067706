<template>
	<v-dialog
		persistent
		v-model="opened"
		width="600"
		@keydown.esc="cancel"
		@open="focusFirstField">
		<template v-slot:activator="{ on }">
			<div v-on="on" class="action">
				<button class="action">
					<v-icon color="black" large>play_arrow</v-icon>
				</button>
				Iniciar
			</div>
		</template>

		<section class="farme-dialog">
			<section class="farme-dialog-header">
				Atualizar LogStockInfo - Item {{ currentIndex + 1 }} de {{ logStockInfo.length }}
			</section>

			<section class="farme-dialog-body">
				<p>Clique em 'Próximo' para atualizar o próximo item ou 'Confirmar' para finalizar.</p>

				<!-- Medication Details -->
				<div v-if="currentLogItem" class="medication-info">
					<p><strong>Medicamento:</strong> {{ currentLogItem.medicamento.produto }}</p>
					<p><strong>Apresentação:</strong> {{ currentLogItem.medicamento.apresentacao }}</p>
					<p><strong>Laboratório:</strong> {{ currentLogItem.medicamento.laboratorio }}</p>
					<p><strong>Dosagem:</strong> {{ currentLogItem.medicamento.dosagem }}</p>
					<!-- Display Copiable EANs -->
					<p><strong>EANs:</strong></p>
					<div>
						<v-chip
							v-for="(ean) in currentLogItem.medicamento.eans"
							:key="ean.id"
							class="ma-1"
							outlined
							@click="copyEAN(ean.ean)">
							{{ ean.ean }}
						</v-chip>
					</div>
				</div>

				<!-- LogStockInfo Update Form -->
				<div v-if="currentLogItem" class="log-stock-info">
					<!-- EAN Input Field -->
					<v-text-field
						v-model="eanInput"
						label="EAN"
						outlined
						ref="ean"
						:error-messages="eanError"
						@keyup.enter="handleEANInput"></v-text-field>

					<v-text-field
						v-model="currentLogItem.lote"
						label="Lote"
						outlined
						ref="lote"
						:disabled="!eanValid"
						@keyup.enter="handleLoteInput"></v-text-field>

					<!-- Editable Validade Field -->
					<v-text-field
						v-model="formattedValidade"
						label="Validade (YYYY-MM-DD)"
						outlined
						ref="validade"
						:disabled="!eanValid"
						@keyup.enter="focusNext('etiqueta')"></v-text-field>

					<v-text-field
						v-model="currentLogItem.etiqueta"
						label="Etiqueta"
						outlined
						ref="etiqueta"
						:disabled="!eanValid"
						@keyup.enter="focusNext('valor')"></v-text-field>

					<v-text-field
						v-model="displayValor"
						label="Valor"
						outlined
						type="text"
						ref="valor"
						:disabled="true"
						@keyup.enter="handleEnterOnValue"></v-text-field>
				</div>
			</section>

			<section class="farme-dialog-footer">
				<v-btn color="green darken-1" flat @click="cancel">
					Cancelar
				</v-btn>
				<v-spacer></v-spacer>

				<v-btn color="primary" @click="previousItem" :disabled="currentIndex === 0">
					Anterior
				</v-btn>

				<v-btn color="primary" @click="nextItem" :disabled="currentIndex === logStockInfo.length - 1">
					Próximo
				</v-btn>

				<v-btn color="primary" @click="confirm" :disabled="currentIndex !== logStockInfo.length - 1">
					Confirmar
				</v-btn>
			</section>
		</section>
	</v-dialog>
</template>

<script>
export default {
	props: {
		boxId: {
			type: Number,
			required: true
		},
		logStockInfo: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			opened: false,
			currentIndex: 0,
			displayValor: '', // For displaying the converted value
			eanInput: '', // EAN input field
			eanValid: false, // EAN validation status
			eanError: '' // EAN error message
		};
	},
	computed: {
		currentLogItem() {
			return this.logStockInfo[this.currentIndex];
		},
		formattedValidade: {
			get() {
				return this.currentLogItem?.validade || '';
			},
			set(newVal) {
				if (this.isValidMMYYYY(newVal))
					this.currentLogItem.validade = newVal;
				else
					this.eanError = 'Formato inválido para validade.';
			}
		}
	},
	watch: {
		currentLogItem: {
			handler(newVal) {
				this.displayValor = (newVal?.valor / 100).toFixed(2); // Convert cents to decimal
				this.eanInput = ''; // Reset EAN input
				this.eanValid = false; // Reset EAN validation
				this.eanError = ''; // Clear EAN error
			},
			immediate: true
		},
		displayValor(newVal) {
			if (this.currentLogItem)
				this.currentLogItem.valor = Math.round(parseFloat(newVal) * 100); // Convert back to cents
		}
	},
	methods: {
		focusFirstField() {
			this.$nextTick(() => {
				this.$refs.ean?.focus();
			});
		},
		handleEANInput() {
			// Check if input is a GS1 DataMatrix
			const gs1Pattern = /\(01\)(\d+)\(10\)(\w+)\(17\)(\d{6})/; // GS1 pattern for GTIN, lote, validade
			const match = gs1Pattern.exec(this.eanInput);

			if (match) {
				// GS1 DataMatrix detected, extract fields
				const gtin = match[1];
				const lote = match[2];
				const validade = this.parseGS1Date(match[3]);

				// Validate extracted GTIN
				this.eanInput = gtin; // Update EAN field to just the GTIN
				this.currentLogItem.lote = lote;
				this.currentLogItem.validade = validade;
				this.eanValid = true;
				this.eanError = '';
				this.$refs.valor?.focus(); // Move to the lote field
			} else {
				// Validate as standard EAN
				this.validateStandardEAN();
			}
		},
		validateStandardEAN() {
			const eanList = this.currentLogItem?.medicamento?.eans?.map(e => e.ean) || [];
			if (eanList.includes(this.eanInput)) {
				this.eanValid = true;
				this.eanError = '';
				this.$refs.lote?.focus(); // Move to the lote field
			} else {
				this.eanValid = false;
				this.eanError = 'EAN inválido. Por favor, tente novamente.';
			}
		},
		copyEAN(ean) {
			// Create a temporary textarea to copy the EAN
			const textarea = document.createElement('textarea');
			textarea.value = ean;
			document.body.appendChild(textarea);
			textarea.select();
			document.execCommand('copy');
			document.body.removeChild(textarea);

			// Show a snackbar notification
			this.$store.dispatch('SHOW_SNACKBAR', {
				message: `EAN ${ean} copiado!`,
				color: 'success'
			});
		},
		handleLoteInput() {
			const gs1Pattern = /\(10\)(\w+)\(17\)(\d{6})/; // GS1 pattern for lote and validade
			const match = gs1Pattern.exec(this.currentLogItem.lote);

			if (match) {
				this.currentLogItem.lote = match[1]; // Extract lote
				this.currentLogItem.validade = this.parseGS1Date(match[2]); // Parse and set validade
				this.$refs.validade?.focus(); // Focus validade field
			} else
				this.focusNext('validade'); // If not GS1, focus next field
		},
		parseGS1Date(dateStr) {
			const year = `20${dateStr.substring(0, 2)}`;
			const month = dateStr.substring(2, 4);
			return `${month}/${year}`;
		},
		focusNext(field) {
			this.$refs[field]?.focus();
		},
		handleEnterOnValue() {
			if (this.currentIndex < this.logStockInfo.length - 1)
				this.nextItem();
			else
				this.confirm();
		},
		nextItem() {
			if (this.currentIndex < this.logStockInfo.length - 1) {
				this.currentIndex++;
				this.$nextTick(() => {
					this.$refs.ean?.focus();
				});
			}
		},
		previousItem() {
			if (this.currentIndex > 0) {
				this.currentIndex--;
				this.$nextTick(() => {
					this.$refs.ean?.focus();
				});
			}
		},
		confirm() {
			this.$emit('confirm');
			this.opened = false;
		},
		cancel() {
			this.opened = false;
		},
		isValidMMYYYY(value) {
			// Validate YYYY-MM-DD format
			return /^\d{4}-(0[1-9]|1[0-2])-[0-3]\d$/.test(value);
		}
	}
};
</script>

<style scoped>
/* Styles remain the same */
.action {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 50px;

	button {
		background-color: #FFF;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		box-shadow: 0px 4.62px 4.62px 0px rgba(217, 172, 106, 0.2);

		svg {
			width: 45px;
			height: 45px;
		}
	}

	@media (max-width: 600px) {
		gap: 20px;
		button {
			width: 75px;
			height: 75px;
		}
	}
}

.medication-info {
	margin-bottom: 20px;

	p {
		margin: 5px 0;
	}
}
</style>

<template>
	<div>
		<v-data-table
			v-model="selected"
			ref="DataTable"
			:headers="headers"
			:items="items"
			:loading="loading"
			:no-data-text="loading ? 'Carregando...' : error ? 'Erro ao carregar, tente novamente' : 'Nenhum orçamento'"
			no-results-text="Nenhum resultado encontrado"
			:rows-per-page-items="rows_per_page"
			:pagination.sync="pagination"
			:total-items="totalItems"
			rows-per-page-text="Itens por página">
			<template slot="headers" slot-scope="props">
				<tr>
					<th class="pr-0">
						<v-checkbox :input-value="props.all" :indeterminate="props.indeterminate" primary
							hide-details @click.stop="toggleAll" />
					</th>
					<th v-for="(header, index) in props.headers" :key="header.text" :class="['column', { sortable: header.sortable }, index !== 1 ? 'text-xs-center' : 'text-xs-left', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
						@click="changeSort(header.value)">
						{{ header.text }}
						<v-icon small v-if="header.sortable">arrow_upward</v-icon>
					</th>
				</tr>
			</template>
			<template v-slot:items="props">
				<tr :active="props.selected" @click="abrirDialog(props.item, 'dialog_detalhes')" class="pointer">
					<td class="pr-0">
						<v-checkbox :input-value="props.selected" @click.stop="props.selected = !props.selected" primary hide-details />
					</td>
					<td>{{ props.item.id }}</td>
					<td><strong>{{ props.item.nome }}</strong></td>
					<td>{{ props.item.casa_repouso_nome || "-" }}</td>
					<td class="text-xs-center">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span
									v-bind="attrs"
									v-on="on" >
									{{ props.item.data_validade | formatDate('DD/MM/YYYY') }}
								</span>
							</template>
							<span>Criado em <strong>{{ props.item.created_at | formatDate('DD/MM/YYYY') }}</strong></span>
						</v-tooltip>
					</td>
					<td class="text-xs-center">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<span
									v-bind="attrs"
									v-on="on" >
									{{ props.item.vendedor.permissao === 5 ? 'Externo' : 'Far.me' }}
								</span>
							</template>
							<span>Vendedor: <strong>{{ props.item.vendedor.nome }}</strong></span>
						</v-tooltip>
					</td>
					<td class="text-xs-center"><strong>{{ (mostrar_como_integral ? props.item.valor_final_sem_proporcionalidade: props.item.valor_final ) | dinheiro }}</strong></td>
					<td class="text-xs-center">
						<v-chip small disabled class="mx-0 white--text" :class="$functions.getStatusOrcamento(props.item.status).class">
							{{ $functions.getStatusOrcamento(props.item.status).label.toUpperCase() }}
						</v-chip>
					</td>
					<td>
						<v-menu offset-y>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" @click.stop class="botao-acao" flat >
									<span class="px-2">AÇÕES</span><v-icon>fas fa-ellipsis-h</v-icon>
								</v-btn>
							</template>
							<v-card>
								<v-list>
									<v-list-tile @click.stop="imprimirTermoResponsabilidade(props.item)" :disabled="gerando_termo">
										<v-list-tile-action>
											<v-icon color="green">description</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Imprimir termo de responsabilidade</v-list-tile-title>
									</v-list-tile>
									<v-list-tile @click.stop="crud(props.item)" :disabled="regraDisableEditar(props)">
										<v-list-tile-action>
											<v-icon color="purple" :disabled="regraDisableEditar(props)">edit</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Editar orçamento</v-list-tile-title>
									</v-list-tile>
									<v-list-tile v-if="$store.getters.user.permissao >= $permissoes.RT" @click.stop="novaAssinatura(props.item)" :disabled="carregando_orcamento || props.item.status === 'DONE' || props.item.status === 'CANCELED' || gerando_pdf">
										<v-list-tile-action>
											<v-icon color="primary" :disabled="carregando_orcamento || props.item.status === 'DONE' || props.item.status === 'CANCELED' || gerando_pdf">done_all</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Criar assinatura</v-list-tile-title>
									</v-list-tile>
									<v-list-tile  @click="abrirDialog(props.item, 'dialogUpdateHistory')">
										<v-list-tile-action>
											<v-icon color="black">history</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Histórico de Alterações</v-list-tile-title>
									</v-list-tile>
									<v-divider/>
									<v-list-tile @click="abrirDialog(props.item, 'dialog_renovar')" :disabled="carregando_orcamento || gerando_pdf || !permiteRenovar(props.item)">
										<v-list-tile-action >
											<v-icon color="primary" :disabled="carregando_orcamento || gerando_pdf || !permiteRenovar(props.item)">fas fa-sync</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Renovar</v-list-tile-title>
									</v-list-tile>
									<v-divider/>
									<v-list-tile @click="imprimirOrcamento(props.item)">
										<v-list-tile-action>
											<v-icon color="black">print</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Imprimir orçamento</v-list-tile-title>
									</v-list-tile>
									<v-list-tile @click="abrirDialog(props.item, 'dialog_enviar_email')">
										<v-list-tile-action>
											<v-icon color="black">fas fa-paper-plane</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Enviar email</v-list-tile-title>
									</v-list-tile>
									<v-list-tile @click="copiarURL(props.item)">
										<v-list-tile-action>
											<v-icon color="black">fas fa-share</v-icon>
										</v-list-tile-action>
										<v-list-tile-title>Copiar link</v-list-tile-title>
									</v-list-tile>
									<template v-if="eh_colaborador && em_revisao(props.item.status)">
										<v-divider/>
										<v-list-tile @click.stop="abrirDialog(props.item, 'dialog_revisar_orcamento')">
											<v-list-tile-action>
												<v-icon color="light-green">far fa-check-circle</v-icon>
											</v-list-tile-action>
											<v-list-tile-title>Confirmar revisão</v-list-tile-title>
										</v-list-tile>
									</template>
								</v-list>
							</v-card>
						</v-menu>
					</td>
				</tr>
			</template>
		</v-data-table>
		<v-dialog persistent v-model="dialog_detalhes" scrollable transition="fade" max-width="720px">
			<transition>
				<DialogDetalhes v-if="dialog_detalhes" :orcamento_id="item_selecionado.id" :close.sync="dialog_detalhes" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialogUpdateHistory" scrollable transition="fade" max-width="900px">
			<transition>
				<DialogUpdateHistory
					v-if="dialogUpdateHistory"
					:quotationId="item_selecionado.id"
					:close.sync="dialogUpdateHistory" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialog_orcamento" scrollable transition="fade" max-width="720px">
			<transition>
				<DialogNovoOrcamento v-if="dialog_orcamento" :id="item_selecionado.id"
					@inserir="refresh" @excluir="refresh" @close="dialog_orcamento = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialog_impressao" fullscreen hide-overlay scrollable
			transition="fade">
			<transition>
				<DialogImpressao v-if="dialog_impressao" :orcamento_uuid="item_selecionado.uuid" :close.sync="dialog_impressao" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialog_renovar" scrollable transition="fade" max-width="320px">
			<transition>
				<DialogRenovar v-if="dialog_renovar" :orcamento="item_selecionado"
					@refresh="refresh" @close="dialog_renovar = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="nova_assinatura" fullscreen hide-overlay scrollable>
			<NovaAssinatura v-if="nova_assinatura" type="b2b" :close.sync="nova_assinatura" @assinatura-salva="assinaturaSalva"
				:orcamento="item_selecionado" />
		</v-dialog>

		<v-dialog persistent v-model="dialog_enviar_email" scrollable transition="fade" max-width="400px">
			<transition>
				<Dialog v-if="dialog_enviar_email" titulo="Envio de email" subtitulo="Tem certeza que deseja enviar este email?"
					btn_acao="Sim, enviar" btn_cancelar="Cancelar"
					@acao="enviarEmail('enviarOrcamentoB2C')" @close="dialog_enviar_email = false" />
			</transition>
		</v-dialog>

		<v-dialog persistent v-model="dialog_revisar_orcamento" scrollable transition="fade" max-width="580px">
			<transition>
				<template>
					<Dialog titulo="Revisão de orçamento" btn_acao="Confirmar revisão" btn_cancelar="Cancelar" @acao="revisarOrcamento"
						@close="dialog_revisar_orcamento = false" :loading="confirmando_revisao">
						<v-container fluid grid-list-lg class="px-3 pt-1 pb-0">
							<v-layout row wrap>
								<template>
									<v-flex xs12 md12 class="revisao">
										<p>Revisão de orçamento concluída?</p>
										<p>Após confirmação não será possível desfazer ação! </p>
									</v-flex>
									<v-flex xs12 md12>
										<v-switch v-model="e_vendedor_interno" :input-value="regraSwitchInput(item_selecionado)" label="Direcionar orçamento para vendedor interno?" style="justify-content: center" :disabled="disableSwitchInput"/>
									</v-flex>
								</template>
							</v-layout>
						</v-container>
					</Dialog>
				</template>
			</transition>
		</v-dialog>
	</div>
</template>

<script>
import NovaAssinatura from '@Componentes/assinatura/nova-assinatura';
import DialogNovoOrcamento from '@Componentes/novo-dialog-orcamento';
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';
import { openBuffer } from '@Util/functions';
import moment from 'moment';
import { featureFlags } from '@Consts/feature-flags';
import DialogRenovar from './dialog-renovar';
import DialogDetalhes from './dialog-detalhes';
import DialogImpressao from './dialog-impressao';
import DialogUpdateHistory from './dialog-update-history';
import mixin from './mixin-data-table';

export default {
	name: 'DataTableOrcamento',
	mixins: [mixin],
	components: {
		DialogRenovar,
		DialogDetalhes,
		DialogNovoOrcamento,
		DialogImpressao,
		NovaAssinatura,
		DialogUpdateHistory
	},

	props: {
		rows_per_page: {
			type: Array,
			default: () => [15, 30, 45, 90, { text: 'Todos', value: -1 }]
		},
		totalItems: {
			type: Number,
			required: true
		}
	},

	watch: {
		pagination: {
			async handler(newVal, oldVal) {
				if (!newVal.sortBy) { // skipping 'null state' of sortBy (3rd click in same column)
					oldVal.descending = !oldVal.descending;
					this.pagination = oldVal;
					return;
				}
				this.$emit('pagination-update', this.pagination);
			},
			deep: true
		},
		selected(arr) {
			this.$emit('selected-updated', arr);
		}
	},

	data: () => ({
		headers: [
			{ text: 'ID', value: 'id' },
			{ text: 'Prospect', value: 'nome' },
			{ text: 'ILPI', value: 'casa_repouso' },
			{ text: 'Validade', value: 'data_validade', align: 'center' },
			{ text: 'Vendedor', value: 'vendedor', align: 'center' },
			{
				text: 'Preço',
				value: 'valor_total',
				align: 'center',
				sortable: false
			},
			{ text: 'Status', value: 'status', align: 'center' },
			{ text: '', sortable: false }
		],
		pagination: {
			page: 1,
			rowsPerPage: 15
		},
		selected: [],
		dialog_renovar: false,
		dialog_detalhes: false,
		dialog_orcamento: false,
		dialog_impressao: false,
		dialogUpdateHistory: false,
		dialog_revisar_orcamento: false,
		nova_assinatura: false,
		gerando_pdf: false,
		gerando_termo: false,
		carregando_orcamento: false,
		revisando_orcamento: false,
		confirmando_revisao: false,
		e_vendedor_interno: false,
		disableSwitchInput: false,
		pdf: null
	}),
	methods: {
		toggleAll() {
			this.selected = this.selected.length ? [] : [...this.$refs.DataTable.filteredItems];
		},
		changeSort(column) {
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},
		permiteRenovar(item) {
			const diff = moment(item.data_validade).diff(moment().startOf('day'), 'days', true);
			return item.status === 'EXPIRED' || diff <= 1;
		},
		refresh() {
			this.$emit('refresh');
		},
		crud(item = {}) {
			this.abrirDialog(item, 'dialog_orcamento');
		},
		imprimirOrcamento(orcamento) {
			if (this.$vuetify.breakpoint.mdAndUp) {
				this.item_selecionado = orcamento;
				this.dialog_impressao = true;
			} else {
				this.gerando_pdf = true;
				OrcamentoPresenter.pdf(orcamento.uuid)
					.then(bufferArray => {
						const arr = new Uint8Array(bufferArray);
						const blob = new Blob([arr], { type: 'application/pdf' });
						const reader = new FileReader();
						reader.readAsDataURL(blob);
						reader.onloadend = function () {
							const base64data = reader.result;
							const a = document.createElement('a');
							a.href = base64data;
							a.download = `Orçamento Farme - ${orcamento.nome}.pdf`;
							a.click();
						};
					});
			}
		},
		async imprimirTermoResponsabilidade(orcamento) {
			try {
				this.gerando_termo = true;
				const pdf = await OrcamentoPresenter.relacaoDeMedicamentos(
					orcamento.id,
					orcamento.nome,
					orcamento.cpf
				);
				openBuffer(pdf);
				return pdf;
			} catch (err) {
				throw new Error(err);
			} finally {
				this.gerando_termo = false;
			}
		},
		novaAssinatura(orcamento) {
			this.carregando_orcamento = true;
			OrcamentoPresenter.show(orcamento.id)
				.then(response => {
					this.item_selecionado = response;
					this.nova_assinatura = true;
				})
				.finally(() => {
					this.carregando_orcamento = false;
				});
		},
		assinaturaSalva() {
			this.refresh();
			this.nova_assinatura = false;
		},
		revisarOrcamento() {
			this.confirmando_revisao = true;

			OrcamentoPresenter
				.confirmarRevisao(this.item_selecionado.id, this.e_vendedor_interno)
				.then(() => {
					this.dialog_revisar_orcamento = false;
					this.refresh();
				})
				.finally(() => {
					this.confirmando_revisao = false;
				});
		},
		em_revisao(status) {
			return status === 'UNDER_REVISION';
		},
		regraDisableEditar(props) {
			return this.carregando_orcamento
				|| (this.$store.getters.eh_vendedor && props.item.status === 'APPROVED')
				|| props.item.status === 'DONE'
				|| props.item.status === 'CANCELED'
				|| this.gerando_pdf;
		},
		regraSwitchInput(item) {
			if (this.dialog_revisar_orcamento) {
				if (item.vendedor.permissao === 99) {
					this.e_vendedor_interno = true;
					this.disableSwitchInput = true;
				}
				if (item.vendedor.permissao > 5 && item.vendedor.permissao <= 20) {
					this.e_vendedor_interno = false;
					this.disableSwitchInput = true;
				}
				if (item.vendedor.permissao === 5) {
					this.e_vendedor_interno = false;
					this.disableSwitchInput = false;
				}
			}
		}
	},
	computed: {
		eh_colaborador() {
			return this.$store.getters.eh_colaborador;
		},
		eh_farmeos() {
			return this.$store.getters.eh_farmeos;
		},
		mostrar_como_integral() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.integrality_b2b);
		}
	}
};
</script>

<style lang="scss" scoped>
$icon-size: 16px;

.botao-acao {
	color: #4E2F6F !important;
	border: 1px solid #4E2F6F !important;
  border-radius: 16px;

	&:hover {
		color: white !important;
		background-color: #4E2F6F !important;
	}
}

.preco {
	.space {
		width: $icon-size;
	}

	.v-icon {
		font-size: $icon-size;
	}
}

.revisao {
	font-weight: bold;
	text-align: center;
}
</style>

import $http from '@Config/axios';
import { DefaultPresenter } from './default-presenter';

const prefix = 'envelope';
const entity = 'Envelope';

function sanitize(envelopesData) {
	return {
		...envelopesData,
		envelopes: envelopesData.envelopes && envelopesData.envelopes !== '' ? envelopesData?.envelopes?.split(',') : null
	};
}

export const EnvelopePresenter = {
	...DefaultPresenter({ prefix, entity }),
	/**
	 * Define os envelopes com sobra de estoque do paciente
	 *
	 * @typedef {Object} Params
	 * @property {String} envelopes
	 * @property {Number | null} subscriptionId
	 * @property {Number | null} boxId
	 * @property {Boolean} hasEnvelopes
	 *
	 * @param {Params} params
	 * @returns {Promise<>}
	 */
	async setEnvelopes(params = {}) {
		return $http.post(`${prefix}/`, sanitize(params));
	}
};

<template>
	<section>
		<div class="header">
			<h3>Pendências</h3>

			<v-btn
				color="primary"
				:disabled="!isCreatePendenceAvailable"
				:readonly="!isCreatePendenceAvailable"
				@click="openNewPendenceDialog">
				Nova pendência

				<v-icon small>add</v-icon>
			</v-btn>
		</div>

		<v-container fluid grid-list-md class="px-0">
			<v-layout row wrap>
				<v-flex v-for="(pendence, index) of pendences" :key="index" md3>
					<PendenceCard :position="index+1" :pendence="pendence" @finish="finishPendence"/>
				</v-flex>
			</v-layout>
		</v-container>

		<v-dialog persistent scrollable v-model="openedNewPendenceDialog" transition="fade" max-width="600px">
			<transition>
				<NewPendenceDialog
					v-if="openedNewPendenceDialog"
					:loading="false"
					@close="closeNewPendenceDialog"
					@acao="createPendence"/>
			</transition>
		</v-dialog>
	</section>
</template>

<script>
import NewPendenceDialog from '@Componentes/dialog-pendencias/nova-pendencia';
import PendenceCard from './pendence-card.vue';

export default {
	name: 'PharmaceuticDialogPendenceSection',
	props: {
		pendences: {
			required: false,
			type: Array,
			default: () => ([])
		},
		isCreatePendenceAvailable: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			openedNewPendenceDialog: false
		};
	},
	components: {
		PendenceCard,
		NewPendenceDialog
	},

	methods: {
		openNewPendenceDialog() {
			this.openedNewPendenceDialog = true;
		},
		closeNewPendenceDialog() {
			this.openedNewPendenceDialog = false;
		},
		createPendence(params) {
			this.$emit('createPendence', params);
			this.closeNewPendenceDialog();
		},
		finishPendence(params) {
			this.$emit('finishPendence', params);
		}

	}
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px dashed #3B2C1B80;
	padding-bottom: 10px;

	h3 {
		font-size: 25px;
		font-weight: 400;
		line-height: 30px;
	}
}
</style>

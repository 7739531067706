var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',[_c('v-data-table',{staticClass:"custom-data-table",attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"total-items":_vm.total,"disable-initial-sort":true,"pagination":_vm.pagination,"rows-per-page-text":"Items por página","rows-per-page-items":_vm.itemsPerPageOptions,"no-results-text":"Nenhum resultado encontrado","no-data-text":_vm.loading ? 'Carregando...' : 'Nenhum intem encontrado'},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"column",class:[
							header.sortable ? 'sortable' :  '',
							_vm.pagination.descending ? 'desc' : 'asc',
							header.value === _vm.pagination.sortBy ? 'active' : ''
						],attrs:{"width":header.width},on:{"click":function($event){return _vm.changeSort(header)}}},[(header.sortable )?_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]):_vm._e(),_vm._v(" "+_vm._s(header.text)+" ")],1)}),0)]}},{key:"items",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"items-center justify-center "},[(props.item.deleted)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"red","small":""}},on),[_vm._v("fas fa-exclamation-circle")])]}}],null,true)},[_c('span',[_vm._v(" Assinatura cancelada ")])]):_vm._e(),_c('router-link',{staticClass:"black--text",attrs:{"to":{name: 'Assinatura', params: {id: props.item.subscriptionId}}}},[_vm._v(" "+_vm._s(props.item.subscriptionId)+" ")]),_vm._v(" | "+_vm._s(props.item.box.id)+" "),(props.item.paymentMode)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
return [_c('img',_vm._g({staticClass:"pl-1",staticStyle:{"width":"25px","height":"25px"},attrs:{"src":_vm.iconUrl(props.item),"alt":_vm.iconLabel(props.item)}},on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.iconLabel(props.item))+" ")])]):_vm._e(),(props.item.box.hasPBM)?_c('v-tooltip',{attrs:{"top":""}},[_c('v-chip',{staticClass:"justify-center",staticStyle:{"max-width":"22px","max-height":"22px"},attrs:{"slot":"activator","color":"blue","dark":"","small":""},slot:"activator"},[_c('strong',[_vm._v("%")])]),_c('span',[_vm._v("Box com PBM")])],1):_vm._e(),_c('BoxExpress',{attrs:{"express":props.item.express}}),(!props.item.express && props.item.isFirstBox)?_c('FirstBox'):_vm._e()],1)]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"items-center justify-center "},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(props.item.patient.name))]),(props.item.patient.globalTags && props.item.patient.globalTags.length > 0)?_vm._l((props.item.patient.globalTags),function(globalTag,index){return _c('GlobalTags',{key:index,attrs:{"global_tag":globalTag}})}):_vm._e()],2)]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatDate")(props.item.box.startDate,'DD/MM/YYYY [(]dddd[)]')))]),_c('td',{staticClass:"text-center"},[(props.item.restHome)?[_vm._v(" "+_vm._s(props.item.restHome.name)+" ")]:[_vm._v(" ---- ")]],2),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("statusBox")(props.item.box.status)))]),(_vm.isChargedView)?_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatDate")(props.item.chargedDate,'DD/MM/YYYY')))]):_vm._e(),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"items-center justify-center"},[(!props.item.wasCharged)?_c('div',{staticClass:"pointer",on:{"click":function($event){return _vm.openDialog(props.item, _vm.availableDialogs.IUGU)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fas fa-dollar-sign")])]}}],null,true)},[_c('span',[_vm._v("Cobrar")])])],1):_vm._e(),_c('div',{staticClass:"pl-3 pointer",on:{"click":function($event){return _vm.openDialog(props.item, _vm.availableDialogs.FILES)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fas fa-file")])]}}],null,true)},[_c('span',[_vm._v("Arquivos")])])],1)])])])]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","transition":"fade","max-width":"920px"},model:{value:(_vm.openChargeDialog),callback:function ($$v) {_vm.openChargeDialog=$$v},expression:"openChargeDialog"}},[_c('transition',[(_vm.openChargeDialog)?_c('DialogIugu',{attrs:{"box_id":_vm.selectedItem.box.id,"assinatura_id":_vm.selectedItem.subscriptionId,"isFirstBox":_vm.selectedItem.isFirstBox,"show_modalidade":true},on:{"close":_vm.onCloseIugu}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","transition":"fade","max-width":"920px"},model:{value:(_vm.openFilesDialog),callback:function ($$v) {_vm.openFilesDialog=$$v},expression:"openFilesDialog"}},[_c('transition',[(_vm.openFilesDialog)?_c('Dialog',{attrs:{"titulo":"Arquivos da box"},on:{"close":_vm.onCloseFiles}},[_c('Arquivos',{attrs:{"boxId":_vm.selectedItem.box.id}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
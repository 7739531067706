<template>
	<Dialog
		:titulo="is_new ? 'Criar orçamento' : 'Editar orçamento'"
		:subtitulo="subtitulo"
		btn_acao_extra_2="Voltar"
		@acao_extra_2="stepper--"
		:btn_acao_extra_2_disable="stepper === 1"
		:btn_acao_extra="regraBtnAcaoExtra()"
		@acao_extra="acao((abrir_orcamento = true))"
		:btn_acao="stepper < 3 ? 'Próximo' : 'Salvar'"
		@acao="acao"
		:btn_excluir="!is_new ? 'Cancelar orçamento' : null"
		@excluir="dialog_excluir = true"
		:loading="saving"
		@close="close">
		<div v-if="loading" class="loading">
			<v-progress-circular indeterminate color="primary" />
		</div>

		<v-stepper v-else v-model="stepper">
			<v-stepper-header>
				<v-stepper-step :complete="stepper > 1" step="1">
					Dados Pessoais
				</v-stepper-step>
				<v-divider />
				<v-stepper-step :complete="stepper > 2" step="2">
					Medicamentos
				</v-stepper-step>
				<v-divider />
				<v-stepper-step step="3">
					Dados do orçamento
				</v-stepper-step>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<DadosPessoais ref="personalData" :orcamento="orcamento" @erros="carregarErros" />
				</v-stepper-content>

				<v-stepper-content step="2">
					<Medicamentos :orcamento="orcamento" :fluxo="'entrada'" :assinaturaId="null" :tipoCliente="'b2b'" />
				</v-stepper-content>

				<v-stepper-content step="3">
					<DadosOrcamento :orcamento="orcamento" />
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
		<v-textarea
			v-model="orcamento.observacao"
			box
			hide-details
			label="Observação ao cliente"
			style="padding: 0 24px"/>

		<v-dialog
			persistent
			v-model="dialog_excluir"
			scrollable
			transition="fade"
			max-width="400px">
			<transition>
				<Dialog
					v-if="dialog_excluir"
					titulo="Cancelar orçamento"
					subtitulo="Tem certeza que deseja cancelar o orçamento? Ele não poderá ser recuperado."
					btn_acao="Cancelar orçamento"
					btn_acao_class="error"
					btn_cancelar="Fechar"
					:loading="excluindo"
					@close="dialog_excluir = false"
					@acao="excluir"/>
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';
import moment from 'moment';
import { getCurrentCompanyUnit } from '@Config/unidades';
import { featureFlags } from '@Consts/feature-flags';
import { quotationPaymentModes } from '@Consts/quotation-v2';
import Medicamentos from './medicamentos.vue';
import DadosPessoais from './dados-pessoais.vue';
import DadosOrcamento from './dados-orcamento.vue';

export default {
	name: 'DialogNovoOrcamento',
	components: {
		DadosOrcamento,
		DadosPessoais,
		Medicamentos
	},
	props: {
		id: {
			type: Number,
			default: null
		}
	},
	data: () => ({
		erros: [],
		orcamento: {
			medicamentos: [],
			cobranca_externa: false,
			data_validade: moment()
				.add(7, 'days')
				.format('DD/MM/YYYY')
		},
		dialog_excluir: false,
		excluindo: false,
		saving: false,
		loading: false,
		stepper: 1
	}),
	computed: {
		is_new() {
			return typeof this.id !== 'number';
		},
		subtitulo() {
			if (!this.orcamento.vendedor)
				return this.budgetCreationCity;
			return `Vendedor: ${this.orcamento.vendedor.nome} - ${this.budgetCreationCity}`;
		},
		systemCity() {
			if (this.is_new)
				return getCurrentCompanyUnit().id;

			return this.orcamento.filial_id;
		},
		textCity() {
			switch (this.systemCity) {
				case 1:
					return 'Belo Horizonte';
				case 2:
					return 'São Paulo';
				default:
					return '';
			}
		},
		budgetCreationCity() {
			if (this.is_new)
				return `Orçamento sendo criado em: ${this.textCity}`;
			return `Orçamento da cidade: ${this.textCity}`;
		},
		enableIntegrality() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.integrality_b2c);
		}
	},
	async created() {
		if (!this.is_new) {
			this.loading = true;
			this.orcamento = await OrcamentoPresenter.show(this.id);
			this.orcamento.data_validade = moment(
				this.orcamento.data_validade
			).format('DD/MM/YYYY');
			this.loading = false;
		}
	},
	methods: {
		carregarErros(erros) {
			this.erros = erros.map(erro => erro.msg);
		},
		regraBtnAcaoExtra() {
			if (!this.$store.getters.eh_vendedor || this.stepper < 3)
				return null;

			return 'Salvar e Acessar Orçamento';
		},
		excluir() {
			this.excluindo = true;
			OrcamentoPresenter.destroy(this.orcamento.id)
				.then(() => {
					this.dialog_excluir = false;
					this.$emit('excluir');
					this.close();
				})
				.finally(() => {
					this.excluindo = false;
				});
		},
		createData() {
			const medicamentos = this.orcamento.medicamentos.map(med => ({
				medicamento_id: med.id,
				assinatura_medicamento_id: med.id_assinatura_medicamento || null,
				posologia: med.posologia ? med.posologia : null,
				preco_personalizado: med.preco_personalizado
					? med.preco_personalizado
					: null,
				qtd_caixas: med.qtd_caixas ? Number(med.qtd_caixas) : null,
				desconto: med.desconto,
				aproveitamento_receita: med.aproveitamento_receita
			}));

			const orcamento = {
				...this.orcamento,
				tag_ids: this.orcamento.tags ? this.orcamento.tags.map(t => t.id) : [],
				pct_desconto_primeira_box: Number(
					this.$functions.onlyNumber(this.orcamento.pct_desconto_primeira_box)
				),
				data_validade: moment(
					this.orcamento.data_validade,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				telefone: this.$functions.onlyNumber(this.orcamento.telefone),
				casa_repouso_id: this.orcamento.casa_repouso ? this.orcamento.casa_repouso.id : null,
				valor_servico: Number(
					this.$functions.onlyNumber(this.orcamento.valor_servico)
				),
				filial_id: this.orcamento.filial_id,
				medicamentos,
				modo_pagamento: this.orcamento.modo_pagamento
				|| this.enableIntegrality ? quotationPaymentModes.INTEGRAL : quotationPaymentModes.PROPORTIONAL
			};

			delete orcamento.casa_repouso;

			return orcamento;
		},
		async acao(abrir_orcamento) {
			if (this.stepper < 3) {
				if (this.stepper === 1) {
					const validadePersonalData = await this.$refs.personalData.validate();
					if (!validadePersonalData) {
						this.$store.dispatch('SHOW_SNACKBAR', {
							color: 'error',
							message: 'Dados obrigatórios incompletos'
						});
						return;
					}
				}
				if (this.stepper === 2 && this.orcamento.medicamentos.length === 0) {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'Você deve inserir pelo menos um medicamento'
					});
					return;
				}
				this.stepper++;
			} else {
				if (!moment(this.orcamento.data_validade, 'DD/MM/YYYY').isValid()) {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'Data de validade invalida'
					});
					return;
				}
				if (
					this.$functions.onlyNumber(this.orcamento.pct_desconto_primeira_box)
						< 0
					|| this.$functions.onlyNumber(
						this.orcamento.pct_desconto_primeira_box < 10000
					)
				) {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'Favor digite um desconto valido'
					});
					return;
				}
				const data = this.createData();
				if (!data.vendedor_id) {
					this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'Por favor, insira um vendedor.'
					});
					return;
				}
				const $http = this.is_new
					? OrcamentoPresenter.store
					: OrcamentoPresenter.update;
				this.saving = true;
				$http(this.createData())
					.then(response => {
						this.$emit('inserir', response);

						if (this.$store.getters.eh_vendedor && abrir_orcamento)
							window.open(response.url, '_blank');

						this.close();
					})
					.finally(() => {
						this.saving = false;
					});
			}
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>

<style lang="scss" scoped>
.v-stepper,
.v-stepper__header {
	box-shadow: none;
}
</style>

import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const prefix = '/orders';

const PREFIX_V2 = '/v2';
const PREFIX_ORDER_IN_PROCESS = `${PREFIX_V2}/orders/in-process`;
const PREFIX_RETRY_PROCESS = `${PREFIX_V2}/in-process`;

export const OrderPresenter = {
	get: params => $http.get(buildQueryParams(prefix, params)),
	find: id => $http.get(`${prefix}/${id}`),
	generatePdfOrderPickingList: ({
		orderIds
	}) => $http.get(buildQueryParams(`${prefix}/pickingList`, { orderIds })),
	updateOrderProcess: (id, typeProcess, params) => $http.put(`${prefix}/${id}/processes/${typeProcess}`, params),
	getOrdersByProcess: (process, params) => $http.get(buildQueryParams(`${prefix}/processes/${process}`, params)),
	getProcessByOrderAndProcessType: (orderId, typeProcess) => $http.get(`${prefix}/${orderId}/processes/${typeProcess}`),
	getOrderProcessHistory: orderId => $http.get(`${prefix}/${orderId}/processes`),
	cancelOrder: (orderId, reasonOfCancellation) => $http.delete(`${prefix}/${orderId}`, { data: { reasonOfCancellation } }),
	/**
	 * Lista dos pontuais que estão em processamento
	 *
	 * @typedef {Object} Filter
	 * @property {String} search
	 * @property {String} startDate
	 * @property {Number} restHomeId
	 * @property {String} status
	 *
	 * @typedef {Object} Pagination
	 * @property {Number} page
	 * @property {Number} perPage

	* @typedef {Object} Sorting
	* @property {Number} field
	* @property {(DESC|ASC)} direction
	*
	* @typedef {Object} Params
	* @property {Filter} filter
	* @property {Pagination} pagination
	* @property {Sorting} sorting
	*
	* @typedef {Object} Response
	* @property {Array} items
	* @property {Number} page
	* @property {Number} perPage

	* @param {Params} params
	* @returns {Promise<Response>}
	*/
	async getOrdersInProcess(params = {}) {
		const query = {
			page: params.pagination.page,
			perPage: params.pagination.perPage,
			restHomeId: params.filter.restHomeId,
			startDate: params.filter.startDate,
			productionDate: params.filter.productionDate,
			ignoreTagsIds: params.filter.ignoreTagsIds ? params.filter.ignoreTagsIds.join(',') : '',
			view: params.filter.view,
			sortBy: params.sorting.field,
			sortDir: params.sorting.direction,
			status: params.filter.status,
			q: params.filter.search
		};
		const response = await $http.get(buildQueryParams(PREFIX_ORDER_IN_PROCESS, query));
		return response;
	},

	/**
	 * Tenta reprocessar um pedido (Box ou pontual)
	 *
	 * @typedef {Object} Params
	 * @property {List<Numbers>} ids
	 * @property {String} view
	 *
	 * @param {Params} params
	 * @returns {Promise<Array<Number>}
	 */
	async retryProcessingOrders(params = {}) {
		const response = $http.post(`${PREFIX_RETRY_PROCESS}/retry`, params);
		return response;
	},

	/**
	 * Pega o ID do pedido no ERP
	 *
	 * @param {Number} orderId
	 * @returns {Promise<{code: String}>}
	 */
	async getErpCode(orderId) {
		const response = await $http.get(`${PREFIX_V2}${prefix}/${orderId}/erp-code`);
		return { code: response.code };
	}
};

<template>
	<CobrancaV2 v-if="newVersion" />
	<CobrancaV1 v-else />
</template>

<script>
import { featureFlagsMixin } from '@Mixins/feature-flag';
import { featureFlags } from '@Consts/feature-flags';
import CobrancaV1 from './v1';
import CobrancaV2 from './v2';

export default {
	components: {
		CobrancaV1,
		CobrancaV2
	},
	mixins: [
		featureFlagsMixin
	],
	computed: {
		newVersion() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.enable_farme_new_charging_screen);
		}
	}
};
</script>

<style lang="scss" scoped>
</style>

import { render, staticRenderFns } from "./dialog-create-prescriber.vue?vue&type=template&id=9f0a0270&scoped=true"
import script from "./dialog-create-prescriber.vue?vue&type=script&lang=js"
export * from "./dialog-create-prescriber.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f0a0270",
  null
  
)

export default component.exports
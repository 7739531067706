export const featureFlags = Object.freeze({
	enable_farme_new_production_process: 'enable_farme_new_production_process',
	rastreabilidade_por_foto: 'FARMEBOX_RASTREABILIDADE_POR_FOTO',
	conferencia_digital: 'FARME_BOX_CONFERENCIA_DIGITAL_ENABLED',
	new_draft_dialog: 'FARMEBOX_DRAFT_DIALOG',
	list_orders_in_process: 'FARMEBOX_LIST_ORDERS_IN_PROCESS',
	hide_invoicing_and_picking_process: 'FARMEBOX_HIDE_PICKING_AND_INVOICING',
	picking_with_tag: 'FARMEBOX_PICKING_WITH_TAG',
	picking_with_tag_required: 'FARMEBOX_PICKING_WITH_TAG_REQUIRED',
	enable_envelope_code_on_conference: 'FARMEBOX_ENVELOPE_CODE_ON_CONFERENCE_SCREEN',
	pre_production_process: 'FARMEBOX_PRE_PRODUCTION_PROCESS',
	integrality_b2c: 'FARMEBOX_ENABLE_INTEGRALITY_B2C',
	integrality_b2b: 'FARMEBOX_ENABLE_INTEGRALITY_B2B',
	picking_orders_with_tag: 'FARMEBOX_ORDERS_PICKING_WITH_TAG',
	picking_orders_with_tag_required: 'FARMEBOX_ORDERS_PICKING_WITH_TAG_REQUIRED',
	pharmaceutic_dialog: 'FARMEBOX_PHARMACEUTIC_DIALOG',
	min_date_rule: 'FARMEBOX_MIN_DATE_RULE',
	enable_farme_new_charging_screen: 'FARMEBOX_ENABLE_NEW_CHARGING_SCREEN'
});

<template>
	<section>
		<section class="pa-3 white" style="display: flex;justify-content: space-between;align-items: center;border-radius: 15px">
			<template v-if="subscription.loading">
				<div class="mx-auto text-center">
					Carregando
					<v-progress-linear :indeterminate="true" color="primary" style="width: 200px;" ></v-progress-linear>
				</div>
			</template>
			<template v-else>
				<div class="" style="display: flex;justify-content: space-between;align-items: center;">
					<v-avatar size="58" class="mr-3" color="#FFEACC">
						<img :src="UserIcon" alt="Ícone do paciente" style="width: 40px">
					</v-avatar>

					<div>
						<h2>{{ getPatientName }}</h2>
						<h3>{{ getBoxId }}</h3>
					</div>
				</div>

				<div>{{ getRestHome }}</div>
			</template>
		</section>

		<v-textarea
			v-model="observation"
			label="Observações"
			background-color="white"
			class="mt-4"
			hide-details
			box
			loading="subscription.loading"
			:disabled="subscription.loading">

		</v-textarea>

		<div style="display: flex; justify-content: end; align-items: center;">
			<v-btn
				color="primary"
				:disabled="!isObservationChanged"
				class="mx-0 mt-2"
				@click="updateObservation">Salvar observação</v-btn>
		</div>
	</section>
</template>

<script>
import UserIcon from '@Assets/icons/user-icon.svg';

export default {
	name: 'DraftDialogGeneralData',
	props: {
		subscription: {
			required: true,
			type: Object
		},
		boxId: {
			required: true,
			type: Number
		}
	},
	data() {
		return {
			UserIcon,
			observation: null
		};
	},
	created() {
		this.observation = this.subscription.observacao;
	},
	computed: {
		getPatientName() {
			if (!this.subscription)
				return null;
			return `${this.subscription.paciente.nome} - ${this.subscription.id} - ${this.subscription.modalidade.toUpperCase()}`;
		},
		getRestHome() {
			if (!this.subscription)
				return null;
			const { casa_repouso } = this.subscription.paciente;
			return `${casa_repouso.id} - ${casa_repouso.nome}`;
		},
		isObservationChanged() {
			if (!this.subscription)
				return false;
			return this.observation !== this.subscription.observacao;
		},
		getBoxId() {
			if (!this.boxId)
				return null;
			return `BOX ID: ${this.boxId}`;
		}
	},
	methods: {
		updateObservation() {
			this.$emit('updateObservation', this.observation);
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
